import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import ContentLayout from 'layouts/Content/ContentLayout';
import LicenseDetail from 'pages/License/LicenseDetail';


const mapStateToProps = (state, ownProps) => {

  return {
    isAuthenticated: state.session.isAuthenticated,
    loading: state.license.isLoading,
    activating: state.license.activating,
    licenceData: state.license.licenceData,
    error: state.license.error
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    loadDetails: () => {
      SessionManager.shared().loadLicenceDetails();
    },

    updateLicence: (key) => {
      SessionManager.shared().activateNewLicence(key);
    }

  };
};

class UsersView extends React.Component{

  componentDidMount() {
    this.props.loadDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {

    if(this.props.isAuthenticated === false){
      return <ContentLayout main={<h2></h2>}  /> ;
    }

    let detail = React.cloneElement(<LicenseDetail/>, this.props);;

    return <ContentLayout detail={detail} />;
  }

}



const LicenseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersView);
export default LicenseContainer;


