/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseDetails,
    LicenseDetailsFromJSON,
    LicenseDetailsFromJSONTyped,
    LicenseDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultLicenseDetails
 */
export interface RESTResultLicenseDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultLicenseDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultLicenseDetails
     */
    error?: string;
    /**
     * 
     * @type {LicenseDetails}
     * @memberof RESTResultLicenseDetails
     */
    data?: LicenseDetails;
}

export function RESTResultLicenseDetailsFromJSON(json: any): RESTResultLicenseDetails {
    return RESTResultLicenseDetailsFromJSONTyped(json, false);
}

export function RESTResultLicenseDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultLicenseDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : LicenseDetailsFromJSON(json['data']),
    };
}

export function RESTResultLicenseDetailsToJSON(value?: RESTResultLicenseDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': LicenseDetailsToJSON(value.data),
    };
}


