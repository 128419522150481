import React, { Fragment } from 'react';
import { RingLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

const LoadingFullPage = () => {
  const { t } = useTranslation('common');
  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <RingLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          {t('appname')}
          <span className="font-size-lg d-block text-dark">
              {t('labels.loading')}
            </span>
        </div>
      </div>
    </Fragment>
  );
};

export default LoadingFullPage;
