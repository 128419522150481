import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, List, Typography, useTheme } from '@material-ui/core';
import { useTranslation} from 'react-i18next';
import SecondarySidebarMenuListItem from 'components/SecondarySideBar/SecondarySidebarMenuListItem';

import {ReactComponent as IconGroups} from 'assets/netlinkz/subMenuGroups.svg';
import {ReactComponent as IconDevices} from 'assets/netlinkz/subMenuDevices.svg';
import {ReactComponent as IconTopology} from 'assets/netlinkz/subMenuTopology.svg';
import {ReactComponent as IconPerformance} from 'assets/netlinkz/subMenuPerformance.svg';
import {ReactComponent as IconAudit} from 'assets/netlinkz/subMenuAudit.svg';
import { VINActionTab } from 'helpers/constants';

const getIcon = (type) =>
{
  switch (type)
  {
    case VINActionTab.GROUPS: return <IconGroups className="sidebar-icon"/>
    case VINActionTab.DEVICES: return <IconDevices className="sidebar-icon"/>
    case VINActionTab.TOPOLOGY: return <IconTopology className="sidebar-icon-fill"/>
    case VINActionTab.PERFORMANCE: return <IconPerformance className="sidebar-icon-fill"/>
    case VINActionTab.AUDIT: return <IconAudit className="sidebar-icon-fill"/>
  }
  return <IconGroups/>
}

const SecondarySideBar = props => {

  const { t } = useTranslation('vinsgroups');
  const theme = useTheme();

  if (props.actions == null || props.actions.length === 0){
    return null;
  }

  const menuClick = opt =>{
    //console.log('  action! ',opt);
    // console.log()
    if (opt.action != null){
      opt.action();
    }
  };

  let items = props.actions.map( (opt, i) => {
      return <SecondarySidebarMenuListItem
        icon={getIcon(opt.type)}
        key={i}
        title={opt.title}
        disabled={opt.disabled}
        selected={opt.selected}
        action={menuClick.bind(this,opt)} />
  });

  if (props.active)
  {
    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{marginTop:16, marginBottom: 5, marginLeft:10}}>
          <Typography variant={'caption'}>{t('sidebarTab.title', {name:props.vinName})}</Typography>
        </Box>
        <List style={{marginLeft:10, marginTop:-8}} dense={true}>
          {items}
        </List>
      </Fragment>
    )
  }
  else
  {
    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{marginTop:16}}>
          <Typography style={{width:"100%", textAlign:"center", color:theme.palette.common.disabledText}} variant={'caption'}>{t('sidebarTab.titleDisabled')}</Typography>
        </Box>
      </Fragment>
    )
  }

};

const mapStateToProps = state => ({


});

SecondarySideBar.propTypes = {
  actions: PropTypes.array,
  active: PropTypes.bool,
  vinName: PropTypes.string
};

SecondarySideBar.defaultProps = {
  active: true,
};

export default connect(mapStateToProps)(SecondarySideBar);
