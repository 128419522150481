import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, Button } from '@material-ui/core';

const SecondarySidebarMenuListItem = props =>{

  const onClick = () => {
    if (props.action != null){
      props.action(props);
    }
  };

  return (
    <ListItem
      className={'second-app-sidebar-item'}
      disableGutters
      selected={props.selected}
    >
      <Button
        className={'second-app-sidebar-button'}
        onClick={onClick}
        disabled={props.disabled}
      >
        <span>{props.icon}&nbsp;&nbsp;&nbsp;{props.title}</span>
      </Button>
    </ListItem>
  )

};

SecondarySidebarMenuListItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  action: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SecondarySidebarMenuListItem;
