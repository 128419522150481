/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganisationCount,
    OrganisationCountFromJSON,
    OrganisationCountFromJSONTyped,
    OrganisationCountToJSON,
} from './';

/**
 * 
 * @export
 * @interface Organisation
 */
export interface Organisation {
    /**
     * 
     * @type {number}
     * @memberof Organisation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    description?: string;
    /**
     * 
     * @type {OrganisationCount}
     * @memberof Organisation
     */
    count?: OrganisationCount;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    brokerDomain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Organisation
     */
    disabled?: boolean;
}

export function OrganisationFromJSON(json: any): Organisation {
    return OrganisationFromJSONTyped(json, false);
}

export function OrganisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'count': !exists(json, 'count') ? undefined : OrganisationCountFromJSON(json['count']),
        'brokerDomain': !exists(json, 'broker_domain') ? undefined : json['broker_domain'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function OrganisationToJSON(value?: Organisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'count': OrganisationCountToJSON(value.count),
        'broker_domain': value.brokerDomain,
        'disabled': value.disabled,
    };
}


