/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigDetails,
    ConfigDetailsFromJSON,
    ConfigDetailsFromJSONTyped,
    ConfigDetailsToJSON,
    GroupDetails,
    GroupDetailsFromJSON,
    GroupDetailsFromJSONTyped,
    GroupDetailsToJSON,
    Network,
    NetworkFromJSON,
    NetworkFromJSONTyped,
    NetworkToJSON,
} from './';

/**
 * 
 * @export
 * @interface NetworkDetailsWithDevices
 */
export interface NetworkDetailsWithDevices {
    /**
     * 
     * @type {number}
     * @memberof NetworkDetailsWithDevices
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NetworkDetailsWithDevices
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkDetailsWithDevices
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkDetailsWithDevices
     */
    cidr?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkDetailsWithDevices
     */
    cipher?: NetworkDetailsWithDevicesCipherEnum;
    /**
     * 
     * @type {number}
     * @memberof NetworkDetailsWithDevices
     */
    orgId?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkDetailsWithDevices
     */
    groupCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NetworkDetailsWithDevices
     */
    deviceCount?: number;
    /**
     * 
     * @type {Array<GroupDetails>}
     * @memberof NetworkDetailsWithDevices
     */
    groupList?: Array<GroupDetails>;
    /**
     * 
     * @type {Array<ConfigDetails>}
     * @memberof NetworkDetailsWithDevices
     */
    configList?: Array<ConfigDetails>;
    /**
     * 
     * @type {Network}
     * @memberof NetworkDetailsWithDevices
     */
    up?: Network;
}

export function NetworkDetailsWithDevicesFromJSON(json: any): NetworkDetailsWithDevices {
    return NetworkDetailsWithDevicesFromJSONTyped(json, false);
}

export function NetworkDetailsWithDevicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkDetailsWithDevices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
        'cipher': !exists(json, 'cipher') ? undefined : json['cipher'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'groupCount': !exists(json, 'groupCount') ? undefined : json['groupCount'],
        'deviceCount': !exists(json, 'deviceCount') ? undefined : json['deviceCount'],
        'groupList': !exists(json, 'groupList') ? undefined : ((json['groupList'] as Array<any>).map(GroupDetailsFromJSON)),
        'configList': !exists(json, 'configList') ? undefined : ((json['configList'] as Array<any>).map(ConfigDetailsFromJSON)),
        'up': !exists(json, 'up') ? undefined : NetworkFromJSON(json['up']),
    };
}

export function NetworkDetailsWithDevicesToJSON(value?: NetworkDetailsWithDevices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'cidr': value.cidr,
        'cipher': value.cipher,
        'orgId': value.orgId,
        'groupCount': value.groupCount,
        'deviceCount': value.deviceCount,
        'groupList': value.groupList === undefined ? undefined : ((value.groupList as Array<any>).map(GroupDetailsToJSON)),
        'configList': value.configList === undefined ? undefined : ((value.configList as Array<any>).map(ConfigDetailsToJSON)),
        'up': NetworkToJSON(value.up),
    };
}

/**
* @export
* @enum {string}
*/
export enum NetworkDetailsWithDevicesCipherEnum {
    BlowfishCbc = 'blowfish-cbc',
    Aes128Cbc = 'aes128-cbc'
}


