/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Organisation,
    OrganisationFromJSON,
    OrganisationFromJSONTyped,
    OrganisationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Network
 */
export interface Network {
    /**
     * 
     * @type {number}
     * @memberof Network
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    eref?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    cidr?: string;
    /**
     * 
     * @type {string}
     * @memberof Network
     */
    cipher?: NetworkCipherEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Network
     */
    autoEnable?: boolean;
    /**
     * 
     * @type {Organisation}
     * @memberof Network
     */
    organisation?: Organisation;
}

export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'eref': !exists(json, 'eref') ? undefined : json['eref'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
        'cipher': !exists(json, 'cipher') ? undefined : json['cipher'],
        'autoEnable': !exists(json, 'auto_enable') ? undefined : json['auto_enable'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationFromJSON(json['organisation']),
    };
}

export function NetworkToJSON(value?: Network | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'eref': value.eref,
        'name': value.name,
        'description': value.description,
        'cidr': value.cidr,
        'cipher': value.cipher,
        'auto_enable': value.autoEnable,
        'organisation': OrganisationToJSON(value.organisation),
    };
}

/**
* @export
* @enum {string}
*/
export enum NetworkCipherEnum {
    BlowfishCbc = 'blowfish-cbc',
    Aes128Cbc = 'aes128-cbc'
}


