import React, { Suspense, Fragment, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';

// import PagesLogin from './template/example-pages/PagesLogin';
// import PagesRegister from './template/example-pages/PagesRegister';
// import PagesRecoverPassword from './template/example-pages/PagesRecoverPassword';
// import AuthRoute from 'Wrappers/AuthRoute';
// import CollapsedSidebar from './template/layout-blueprints/CollapsedSidebar';
import PagesError404 from 'pages/PagesError404';
import LoginContainer from 'pages/Login/LoginContainer';
import HeaderOnlyLayout from 'layouts/HeaderOnly/HeaderOnlyLayout';
import TestPage from 'pages/TestPage';
import LeftSidebarLayout from 'layouts/LeftSidebar/LeftSidebarLayout';
import SessionCheck from 'Wrappers/SessionCheck';
import DashboardContainer from 'pages/Dashboard/DashboardContainer';
import OrganisationsContainer from 'pages/Organisations/OrganisationsContainer';
import ManageUsersContainer from 'pages/Users/ManageUsersContainer';
import AppSnackbarAlert from 'components/AppSnackbarAlert';
import VinManageContainer from 'pages/VIN Pages/VinManageContainer';
import CreateVinContainer from 'pages/VIN Pages/CreateVinContainer';
import CreateGroupContainer from 'pages/VIN Pages/CreateGroupContainer';
import CreateUserContainer from 'pages/Users/CreateUserContainer';
import InitialiseContainer from 'pages/Initialise/InitialiseContainer';
import CreateOrganisationContainer from 'pages/Organisations/CreateOrganisationContainer';
import SessionManager from 'session/SessionManager';
import LicenseContainer from 'pages/License/LicenseContainer';

const Routes = (props) => {

  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the live preview examples
            <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span>
          </div>
        </div>
      </Fragment>
    );
  };


  let topLevel = SessionManager.shared().getTopLevelRoutes();

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading/>}>
          <SessionCheck>
            <Switch>
                <Redirect exact from="/" to="/login" />
                <Route exact={true} path={["/login","/"]}>
                  <HeaderOnlyLayout>
                    <LoginContainer/>
                  </HeaderOnlyLayout>
                </Route>
              <Route exact={true} path={["/init"]}>
                <HeaderOnlyLayout>
                  <InitialiseContainer/>
                </HeaderOnlyLayout>
              </Route>
                <Route path={topLevel}>
                  <LeftSidebarLayout>
                    <Switch location={location} key={location.pathname}>
                    <Route path="/dashboard" component={DashboardContainer} />
                    <Route path={["/vins/org/:org_id","/vins/org/:org_id/vin/:vin_id","/vins/org/:org_id/vin/:vin_id/tab/:tab"]}
                           exact={true} component={VinManageContainer} />
                    <Route path={["/vins/new","/vins/:org_id/new"]} exact={true} component={CreateVinContainer} />
                    <Route path="/vins/org/:org_id/vin/:vin_id/newgroup" exact={true} component={CreateGroupContainer} />
                    <Route path={['/orgs']} exact={true} component={OrganisationsContainer} />
                    <Route path='/orgs/new' exact={true} component={CreateOrganisationContainer} />
                    <Route path={['/users']} exact={true} component={ManageUsersContainer} />
                    <Route path='/users/new' exact={true} component={CreateUserContainer} />
                    <Route path='/certificates' component={TestPage} />
                    <Route path='/licences' component={LicenseContainer} />
                    <Route path='/settings' component={TestPage} />
                    </Switch>
                  </LeftSidebarLayout>
                </Route>
              <Route component={PagesError404} />
            </Switch>
          </SessionCheck>
          <AppSnackbarAlert />
        </Suspense>
      </AnimatePresence>
    </MuiThemeProvider>
  );


  // return (
  //   <ThemeProvider theme={MuiTheme}>
  //     <AnimatePresence>
  //       <Suspense fallback={<SuspenseLoading />}>
  //         <Switch>
  //           <Redirect exact from="/" to="/HomePage" />
  //           <Route path={['/HomePage']}>
  //             <PresentationLayout>
  //               <Switch location={location} key={location.pathname}>
  //                 <motion.div
  //                   initial="initial"
  //                   animate="in"
  //                   exit="out"
  //                   variants={pageVariants}
  //                   transition={pageTransition}>
  //                   <Route path="/HomePage" component={HomePage} />
  //                 </motion.div>
  //               </Switch>
  //             </PresentationLayout>
  //           </Route>
  //
  //           <Route
  //             path={[
  //               '/PagesLogin',
  //               '/PagesRegister',
  //               '/PagesRecoverPassword',
  //               '/PagesError404'
  //             ]}>
  //             <MinimalLayout>
  //               <Switch location={location} key={location.pathname}>
  //                 <motion.div
  //                   initial="initial"
  //                   animate="in"
  //                   exit="out"
  //                   variants={pageVariants}
  //                   transition={pageTransition}>
  //                   <Route path="/PagesLogin" component={PagesLogin} />
  //                   <Route path="/PagesRegister" component={PagesRegister} />
  //                   <Route
  //                     path="/PagesRecoverPassword"
  //                     component={PagesRecoverPassword}
  //                   />
  //                   <Route path="/PagesError404" component={PagesError404} />
  //                 </motion.div>
  //               </Switch>
  //             </MinimalLayout>
  //           </Route>
  //
  //           <Route path={['/HomePage']}>
  //             <LeftSidebar>
  //               <Switch location={location} key={location.pathname}>
  //                 <motion.div
  //                   initial="initial"
  //                   animate="in"
  //                   exit="out"
  //                   variants={pageVariants}
  //                   transition={pageTransition}>
  //                   <Route path="/HomePage" component={HomePage} />
  //                 </motion.div>
  //               </Switch>
  //             </LeftSidebar>
  //           </Route>
  //         </Switch>
  //       </Suspense>
  //     </AnimatePresence>
  //   </ThemeProvider>
  // );


};

const mapStateToProps = (state, ownProps) => {

  return {
    sessionData: state.session.sessionData,
    isLoading: state.session.isLoading,
  };

};

export default connect(mapStateToProps)(Routes);
