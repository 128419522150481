import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import { withTranslation} from 'react-i18next';
import DataDetails from 'components/DataDetails';
import { VINActionTab } from 'helpers/constants';
import Alert from '@material-ui/lab/Alert';
import ContentPageHeader from 'components/ContentPageHeader';
import * as linkHelper from 'helpers/links';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SessionManager from 'session/SessionManager';
import VINTopology from 'pages/VIN Pages/VINTopology';

const mainStyle = theme => ({
  main: {
    backgroundColor: theme.palette.common.mainContentBg,
  },
  detail:{
    backgroundColor: theme.palette.common.detailContentBg,
  },
  actions:{
    backgroundColor: theme.palette.common.actionsContentBg,
  }
});

class VinMain extends React.Component{
  constructor(props) {
    super(props);

    this.vinClicked = this.vinClicked.bind(this);
    this.getTabData = this.getTabData.bind(this);

    this.rowClicked = this.rowClicked.bind(this);

    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
    this.crumbClicked = this.crumbClicked.bind(this);

    this.newClicked = this.newClicked.bind(this);
  }

  vinClicked(vin){
    // console.log("VinMan:: vin clicked ", vin);
    this.props.loadVinDetail(vin.id);
  }

  rowClicked(thing){
    // console.log('VinMain:: rowClicked ',thing);
    let actionTab = this.props.actionTab;
    if (actionTab == null){
      //clicked a vin
      this.props.loadVinDetail(thing.id);
    } else {

      if (actionTab === VINActionTab.GROUPS) {
        this.props.loadGroupDetail(thing.id);
      }
      else if (actionTab === VINActionTab.TOPOLOGY) {
        this.props.loadGroupTopologyDetail(thing.id);
      }
      else if (actionTab === VINActionTab.DEVICES) {
        let dev = thing.device;
        this.props.loadDeviceDetail(dev.id);
      }
    }
  }

  newClicked(){
    if(this.props.actionTab == null){
      if (this.props.orgId != null){
        if (this.props.orgId === 'all'){
          SessionManager.shared().goto('/vins/new');
        }else {
          SessionManager.shared().goto('/vins/' + this.props.orgId + '/new');
        }
      }
    }
    if(this.props.actionTab == VINActionTab.GROUPS || this.props.actionTab == VINActionTab.TOPOLOGY){
      // console.log('go to new segment');
      let params = this.props.match.params;
      // console.log(':: params - ',params);
      SessionManager.shared().goto('/vins/org/' + params.org_id + '/vin/' + params.vin_id + '/newgroup');
    }

  }

  getTabData(actionTab, t){
    if(actionTab === VINActionTab.GROUPS){
      return this.props.selectedVinGroups;
    }
    else if(actionTab === VINActionTab.TOPOLOGY){
      return this.props.selectedVinGroups;
    }
    else if(actionTab === VINActionTab.DEVICES){
      let data = null;
      if (this.props.vinDevices != null){
        data = this.props.vinDevices.slice();

        data = data.map(dd => {
          if (dd.enable === false){
            dd.lastSeenInfo = t('vindevice:notApprovedInfo');
          }else{

            if (dd.lastSeen != null) {
              let d = new Date(dd.lastSeen);
              let now = new Date();
              if (now - d < 30000){
                dd.lastSeenInfo = t('common:uppercase',{text: t('vindevice:deviceOnline')});
                dd.online = true;
              }else {
                dd.lastSeenInfo = t('common:lastSeenInfo', { date: d });
                dd.online = false;
              }
            }
          }
          return dd;
        });
      }
      return data;
    }
    return null;
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];
    let actionTab = this.props.actionTab;
    let params = this.props.match.params;
    let org = this.props.orgMap[params.org_id];
    let orgName;
    if (org == null ){
      orgName = t('sidebarnav:allVins');
    }else{
      orgName = org.name + " " + t('vin_plural');
    }

    // first is the Parent ALl VINs or Parent VIN or Dashboard
    let orgCrumb = { name: orgName };
    let vinCrumb = null;
    let tabCrumb = null;
    if (this.props.selectedVin != null)
    {
      orgCrumb.link = linkHelper.linkToOrgVins(params.org_id);
      orgCrumb.action = this.crumbClicked.bind(this, orgCrumb.link);

      vinCrumb = { name: this.props.selectedVin.name };

      if (actionTab != null) {
        let vinLink = linkHelper.linkToSelectedVin(params.org_id, this.props.selectedVin.id);
        vinCrumb.link =  vinLink;
        vinCrumb.action = this.crumbClicked.bind(this, vinCrumb.link);


        if (actionTab === VINActionTab.GROUPS) {
          tabCrumb = { name: t('group_plural')};
        } else if (actionTab === VINActionTab.DEVICES) {
          tabCrumb = { name: t('vindevice:device_plural') };
        } else if (actionTab === VINActionTab.TOPOLOGY) {
          tabCrumb = { name: t('vindevice:topology') };
        }

      }
    }

    crumbs.push(orgCrumb);
    if (vinCrumb != null) crumbs.push(vinCrumb);
    if (tabCrumb != null) crumbs.push(tabCrumb);

    return crumbs;
  }

  crumbClicked(crumb){
    // console.log('crumb click ',crumb);
    SessionManager.shared().goto(crumb);
  }


  render() {

    const { t } = this.props;

    // console.log("VinMain:: ",this.props);

    let nav = this.props.nav;

    let overrideContent = false;
    let content = <Alert severity={'info'}>No Data</Alert>;
    let actionTab = this.props.actionTab;
    let showAdd = false;
    // console.log(' actionTab - ',actionTab);


    if (this.props.isLoading(this.props)){
      content =  <Loading/>;
    }
    else {
      let tableData = [];
      let templateName = "VINTable";
      let obj = null;


      if (actionTab != null) {
        tableData = this.getTabData(actionTab, t);

        if (actionTab === VINActionTab.GROUPS) {
          templateName = "VINGroups";
          obj = Object.assign({}, this.props.selectedGroup);
          showAdd = SessionManager.shared().userAllowedAdd('group');
        } else if (actionTab === VINActionTab.DEVICES) {
          templateName = "VINDevices";
          obj = Object.assign({}, this.props.selectedDevice);
          showAdd = false;
        } else if (actionTab === VINActionTab.TOPOLOGY) {
          showAdd = true;
          templateName = "VINTopology";
          obj = Object.assign({}, this.props.selectedGroup);
          overrideContent = true;
          content = <VINTopology
                       data={{data : tableData}}
                       rowClickHandler={this.rowClicked}
                       selected={obj}
                       sizeWithWindow={200}
          />

        }

      } else {
        showAdd = SessionManager.shared().userAllowedAdd('vin');
        obj = { ...this.props.selectedVin };
        if (this.props.orgMap != null && this.props.orgVins != null) {
          tableData = this.props.orgVins.map(vin => {
            let org = this.props.orgMap[vin.orgId];
            vin.orgName = org.name;
            vin.count = vin.groupCount + '/' + vin.deviceCount;
            if (this.props.ciphers != null){
              let cv = this.props.ciphers[vin.cipher];
              if (cv != null){
                vin.cipherName = cv;
              }else{
                vin.cipherName = vin.cipher
              }
            }
            return vin;
          });
        }

      }

      if (!overrideContent) {
        content = <DataDetails
          sizeWithWindow={200}
          template={templateName}
          object={obj}
          data={{ data: tableData }}
          rowClickHandler={this.rowClicked}/>;
      }
    }
    let crumbs = this.getBreadCrumbs(t);
    // console.log('crumbs ',crumbs);



    const {classes} = this.props;
    // console.log("VINMain Classes : ", classes);
    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} newHandler={this.newClicked} withAdd={showAdd} style={{paddingLeft:16,marginRight:16}}/>
        <Grid className="h-100" spacing={0} container={true}>
          <Grid className={nav == null ? "d-none" : classes.actions} item xs={2} style={{maxWidth:192,minWidth:192}}>
            {nav}
          </Grid>

          <Grid className={content == null ? "d-none" : classes.main} item xs style={{...this.props.mainStyle, marginTop:16}}>
            {content}
          </Grid>
        </Grid>
      </Fragment>
    )


  }
}

VinMain.defaultProps = {
  mainStyle: {paddingLeft:16,paddingRight:16},
};

export default withTranslation(['vinsgroups','vindevice'])(withStyles(mainStyle)(VinMain));
