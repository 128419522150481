
import SessionManager from 'session/SessionManager';

export const AlertType = {ERROR: 'error', WARNING: 'warning', SUCCESS:'success', INFO:'info'};

export const snackAlertError = (stringIdentifier, args) => {
  SessionManager.shared().snackAlertError(stringIdentifier, args);
};
export const snackAlertWarning = (stringIdentifier, args) => {
  SessionManager.shared().snackAlertWarning(stringIdentifier, args);
};
export const snackAlertInfo = (stringIdentifier, args) => {
  SessionManager.shared().snackAlertInfo(stringIdentifier, args);
};
export const snackAlertSuccess = (stringIdentifier, args) => {
  SessionManager.shared().snackAlertSuccess(stringIdentifier, args);
};

export default {AlertType, snackAlertError, snackAlertWarning, snackAlertInfo, snackAlertSuccess};
