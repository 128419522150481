import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import { Box, FormGroup, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AppButton from 'components/AppButton';
import DataDetails from 'components/DataDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import { withTranslation} from 'react-i18next';
import { stringNotNullUndefOrEmpty, isValidOrganisation } from 'helpers/validate';
import AppDialogAlert from 'components/AppDialogAlert';
import NothingSelectedTitle from 'components/NothingSelectedTitle';
import SomethingSelectedTitle from 'components/SomethingSelectedTitle';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class OrganisationDetail extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      deleteOpen:false,
    };
    this.canCancel = this.canCancel.bind(this);
    this.canSave = this.canSave.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);
    this.inputBlur = this.inputBlur.bind(this);
    this.deleteConfirmClick = this.deleteConfirmClick.bind(this);
  }

  canCancel(){
    if(this.props.selected == null){
      return false;
    }

    let limitChange = false;
    if (this.props.selected.deviceLimit == null && this.props.editingOrg.deviceLimit != null){
      if (this.props.editingOrg.deviceLimit.soft == null && this.props.editingOrg.deviceLimit.hard == null){
        limitChange = false;
      }else {
        limitChange = true;
      }
    }

    if (this.props.selected.deviceLimit != null && this.props.editingOrg.deviceLimit != null){
      limitChange = this.props.selected.deviceLimit.soft != this.props.editingOrg.deviceLimit.soft || this.props.selected.deviceLimit.hard != this.props.editingOrg.deviceLimit.hard;
    }

    return (
      this.props.selected.name !== this.props.editingOrg.name ||
      this.props.selected.description !== this.props.editingOrg.description ||
      this.props.selected.disabled !== this.props.editingOrg.disabled || limitChange
    );
  }

  canSave(){
    if(this.props.selected == null){
      return false;
    }
    return this.canCancel() && isValidOrganisation(this.props.editingOrg);
  }

  fieldChanged(field,value, template, event){
    // console.log('fieldChanged '+field + ' "'+value+'"');

    if (field === "enabled"){
      value = event.target.checked;
      if (field === "enabled"){
        field = 'disabled';
        value = !value;
      }
    }

    if (field.startsWith("deviceLimit")){
      let parts = field.split('.');
      field = parts[0];
      let ed = this.props.editingOrg;
      let lim = ed.deviceLimit;
      if (value.length > 0) {
        let num = parseInt(value);
        if (!isNaN(num)) {
          if (lim != null) {
            lim[parts[1]] = num
          } else {
            lim = {};
            lim[parts[1]] = num
          }
          value = lim;
        }
      }else{
        if (lim != null) {
          lim[parts[1]] = null
        } else {
          lim = {};
          lim[parts[1]] = null
        }
        value = lim;
      }
    }

    this.props.detailEdit(field,value);
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    // console.log('this.props.editingOrg; ',this.props.editingOrg);
    if (field.startsWith("deviceLimit")){

      let lim = this.props.editingOrg.deviceLimit;
      this.props.validateDeviceLimit(lim);
    }
  }

  deleteConfirmClick(){
    this.setState({deleteOpen:false});

    if(this.props.selected != null){
      this.props.deleteOrganisation(this.props.selected.id);
    }
  }

  render() {
    // console.log("Organisation Detail:: ",this.props);

    // if (this.props.detailLoading){
    //   return <Loading/>;
    // }

    const { t } = this.props;

    if (this.props.selected == null){
      return (
        <NothingSelectedTitle>{t('noOrgSelectionInfo')}</NothingSelectedTitle>
      );
    }

    if (this.props.detailLoading){
      return null;//<Loading/>;
    }

    let header;
    let canCancel = this.canCancel();
    let canSave = this.canSave();

    let obj = Object.assign({},this.props.editingOrg);// {...this.props.editingOrg};
    obj.deviceLimit = Object.assign({},this.props.editingOrg.deviceLimit);
    obj.enabled = !obj.disabled;

    let orgName = (this.props.editingOrg==null)?null:this.props.editingOrg.name;
    header = (
      <SomethingSelectedTitle>{t('orgDetailTitle', {name:orgName})}</SomethingSelectedTitle>
    );

    let content = (
      <Fragment>
        <div style={{borderBottom: '1px solid grey'}}>
          <Tabs value={0}>
            <Tab label={t('tabOrgDetails')} />
          </Tabs>
        </div>
        <TabPanel index={0} value={0}>
          <FormGroup>
            <DataDetails
              template={"OrgDetail"}
              object={obj}
              inputChangeHandler={this.fieldChanged}
              blurHandler={this.inputBlur}
            />
          </FormGroup>

          <Box className="d-flex justify-content-end" style={{marginTop:15}}>
            <AppButton
              buttonType={'grey'}
              style={{marginRight:10}}
              onClick={this.props.cancelDetailEdit}
              disabled={!canCancel}
            >
              {t('common:labels.cancel').toLocaleUpperCase()}
            </AppButton>
            <AppButton
              buttonType={"primary"}
              disabled={!canSave}
              onClick={() => this.props.updateOrg(this.props.editingOrg)}
            >
              {t('common:labels.save').toLocaleUpperCase()}
            </AppButton>

          </Box>

          <Box className="d-flex justify-content-center" style={{marginTop:60}}>
            <AppButton
              buttonType={"delete"}
              startIcon={<DeleteIcon />}
              disabled={this.props.selected == null}
              onClick={() => this.setState({deleteOpen:true})}
            >
              {t('buttonDeleteOrg').toLocaleUpperCase()}
            </AppButton>
          </Box>

        </TabPanel>
      </Fragment>
    );

    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
          {header}
        </Box>
        <Paper style={{padding:16}}>
          {content}
        </Paper>

        <AppDialogAlert
          open={this.state.deleteOpen}
          title={t("organisation:deleteorg.title")}
          message={t("organisation:deleteorg.message", {name:this.props.editingOrg.name})}
          confirmActionTitle={ t('common:uppercase', {text:t('common:labels.delete')}) }
          confirmHandler={this.deleteConfirmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({deleteOpen:false})}
        />
      </Fragment>
    );

  }


}
export default withTranslation('organisation')(OrganisationDetail);
