import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import ContentLayout from 'layouts/Content/ContentLayout';
import ManageUsersMain from 'pages/Users/ManageUsersMain';
import ManageUsersDetail from 'pages/Users/ManageUsersDetail';
import { validateFieldElement } from 'helpers/validate';
import { snackAlertSuccess} from 'helpers/alerting';


const mapStateToProps = (state, ownProps) => {

  let orgs = [];
  if (state.session.sessionData != null){
    orgs = state.session.sessionData.organisations;
  }

  return {
    userId: state.session.sessionData.id,
    isAuthenticated: state.session.isAuthenticated,
    userList: state.users.list,
    selectedUser: state.users.selected,
    editingUser: state.users.editingUser,
    mainLoading: state.users.isLoading,
    detailLoading: state.users.detailLoading,
    isAdding: state.users.isAdding,
    organisations: orgs,
    orgMap:state.session.orgMap,
    paramsReplacementId: state.users.paramsReplacementId
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    loadAll: () => {
      SessionManager.shared().loadUsers();
    },
    loadUser: (userId) => {

      //will load the user if it's not in the state already.
      SessionManager.shared().loadUser(Number(userId));
    },
    userSelected: userId => {
      SessionManager.shared().gotoUser(userId);
    },
    addClick: () => {
      SessionManager.shared().goto('/users/new');
    },

    deleteUser: (userId, name) => {
      SessionManager.shared().deleteUser(userId).then(result => {

        if (result != null && result.success === true){

          snackAlertSuccess("snack:userdeleted", {name:name});
          SessionManager.shared().loadUsers().then(result => {
            if (result != null && result.success === true){
              SessionManager.shared().loadUser(Number(userId));
            }
          });
        }
      });
    },

    updateUser: info => {
      // console.log("update user ",info);

      if (info.plainPassword == null || info.plainPassword.length === 0){
        delete info["plainPassword"];
      }

      let req = {
        userId: info.id,
        userDetails: info,
      };

      SessionManager.shared().updateUser(req).then(result => {
        if (result != null && result.success === true){
          snackAlertSuccess("snack:changessaved");
          SessionManager.shared().loadUsers().then(result => {
            if (result != null && result.success === true){
              SessionManager.shared().loadUser(Number(info.id));
            }
          });
        }
      });
    },

    detailEdit: (field,value) => {
      dispatch({type:"USER_DETAIL_FIELD_CHANGE",data:{field:field, value: value}});
    },

    cancelDetailEdit: () => {
      dispatch({type:"USER_CANCEL_EDIT"});
      dispatch({type:"VALIDATION_CLEAR_TEMPLATE",data:"UserDetail"});
    },

    validateChangePassword: change => {
      // console.log('validateChangePassword ',change);
      if (change == null || change.length == 0){
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "UserDetail", field:"plainPassword", validation:null}})
      }else{
        let validation = validateFieldElement("password",change);
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "UserDetail", field:"plainPassword", validation:validation}})
      }
    }



  };
};

class UsersView extends React.Component{

  componentDidMount() {

    let userId = this.readParams(this.props);

    if (userId == null) {
      this.props.loadAll();
    }else{
      this.props.loadUser(userId);
    }
  }

  readParams(props)
  {
    let params = props.match.params;
    let userId = params.user_id;
    if (userId == null)
    {
      userId = props.paramsReplacementId;
    }

    return userId;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let userId = this.readParams(this.props);
    let old_userId = this.readParams(prevProps);

    if (old_userId != userId)
    {
      if (userId == null) {
        this.props.loadAll();
      }else{
        this.props.loadUser(userId);
      }
    }

  }
  render() {

    // console.log('UsersView:: ',{...this.props});
    if(this.props.isAuthenticated === false){
      return <ContentLayout main={<h2></h2>}  /> ;
    }

    let main = React.cloneElement(<ManageUsersMain/>, this.props);

    let detail = React.cloneElement(<ManageUsersDetail/>, this.props);;
    // if( this.props.isAdding === false) {
    //   detail = React.cloneElement(<ManageUsersDetail/>, this.props);
    // }

    return <ContentLayout main={main} detail={detail} />;
  }

}



const ManageUsersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersView);
export default ManageUsersContainer;


