export default function vinsgroups(
  state = {
    isLoading: false,
    detailLoading: false,
    isSaving: false,
    paramReplacementType:null,    // instead of using the param in the path as the selection id, we'll store it here
    paramReplacementId:null,      // represents the actual selected id, type will tell you what type of object it is
    isAdding: false,
    allVins: null,
    orgVins: null,
    selected: null,
    editingVin: null,
    vinGroups: null,
    selectedGroup: null,
    editingGroup: null,
    vinDevices:null,
    selectedDevice:null,
    editingDevice: null,
    actionTab: null,
    detailEditing: false,
    groupCheckId: null,
    error:null,
  },
  action) {
  switch (action.type) {

    case "SET_PARAM_REPLACEMENT":
    {
      return {
        ...state,
        paramReplacementId: action.selectId,
        paramReplacementType: action.selectType
      }
    }
    case "CLEAR_PARAM_REPLACEMENT":
    {
      return {
        ...state,
        paramReplacementId: null,
        paramReplacementType: null
      }
    }

    case "LOAD_ALL_VINS_START":
    {
      return {
        ...state,
        error: null,
        orgVins: null,
        selected: null,
        editingVin: null,
        actionTab: null,
        isLoading: true,
      }
    }

    case "LOAD_ALL_VINS_FINISHED":
    {
      return {
        ...state,
        isLoading: false,
        orgVins: action.data,
        allVins: action.data,
      }
    }

    case "LOAD_ORG_VINS_START":
    {
      return {
        ...state,
        error: null,
        isLoading: true,
        selected: null,
        editingVin: null,
        orgVins: null,
        actionTab: null,
      }
    }

    case "LOAD_ORG_VINS_FINISHED":
    {
      return {
        ...state,
        orgVins: action.data,
        isLoading: false,
      }
    }

    case "LOAD_VIN_DETAIL":
    {
      // console.log('LOAD_VIN_DETAIL ',action);
      let vinId = action.data;
      let selected = null;
      let edit = null;
      let org = state.orgVins;
        for (const o in org){
          if (org[o].id === vinId){
            selected = org[o];
            edit = Object.assign({},selected);
            break;
          }
        }
      // console.log('LOAD_VIN_DETAIL selected ',selected);
      return {
        ...state,
        actionTab: null,
        selectedGroup: null,
        detailEditing: false,
        selected: selected,
        editingVin: edit,
      }
    }

    case "UNLOAD_SELECTED_VIN":
    {

      return {
        ...state,
        selected: null,
        editingVin: null,
        paramReplacementType:null,
        paramReplacementId:null,
      }
    }

    case "DETAIL_OBJECT_FIELD_CHANGE":
    {
      // console.log('VIN_FIELD_CHANGE ',action);
      let data = action.data;
      let update;
      let edit;

      switch (data.objType) {

        case "vin":
        {
          edit = Object.assign({},state.editingVin);
          update = "editingVin";
          break;
        }

        case "group":
        {

          edit = Object.assign({},state.editingGroup);

          // special case that actually changes the invite code
          if (data.field == 'enrolment' && edit.invites != null && edit.invites.length > 0)
          {
              edit.invites = [{...edit.invites[0]}];
              if (data.value == 'auto')
              {
                edit.invites[0].autoEnabled = true;
                edit.invites[0].enabled = true;
              }
              else if (data.value == 'manual')
              {
                edit.invites[0].autoEnabled = false;
                edit.invites[0].enabled = true;
              }
              else if (data.value == 'off')
              {
                edit.invites[0].autoEnabled = false;
                edit.invites[0].enabled = false;
              }

          }
          update = "editingGroup";
          break;
        }

        case "device":
        {
          edit = Object.assign({},state.editingDevice);
          update = "editingDevice";
          break;
        }

        default:{
          break;
        }

      }

      if(edit != null) {
        edit[data.field] = data.value;
        let change = {};
        change[update] = edit;
        return{
          ...state,
          ...change
        }
      }else{
        return state;
      }
    }

    case "LOAD_VIN_GROUPS_START":
    {
      return {
        ...state,
        error: null,
        selectedGroup: null,
        editingGroup: null,
        isLoading: true,
      }
    }

    case "LOAD_VIN_GROUPS_FINISHED":
    {
      // console.log("LOAD_VIN_GROUPS_FINISHED - ",action);

      let list = action.data.groupList.map(item => {
        if (item.connectionIds != null){
          item.linkedGroupCount = item.connectionIds.length;
        }else{
          item.linkedGroupCount = 0;
        }
        return item;
      });

      return {
        ...state,
        isLoading: false,
        vinGroups: list,
        vinDevices:null,
        selectedDevice: null
      }
    }

    case "VIN_TAB_SELECTED":
    {
      console.log("VIN_TAB_SELECTED ",action.data);
      return {
        ...state,
        actionTab: action.data,
      }
    }


    case "LOAD_GROUP_DETAIL":
    {
      let groupId = action.data;
      let selected = null;
      let edit;
      let grps = state.vinGroups;
      for (const o in grps){
        if (grps[o].id === groupId){
          selected = grps[o];
          edit = Object.assign({},selected);
          if (selected.invites != null){
            edit.invites = [...selected.invites];
          }else{
            edit.invites = null;
          }
          break;
        }
      }
      // console.log('LOAD_GROUP_DETAIL selected ',selected);
      return {
        ...state,
        selectedGroup: selected,
        editingGroup: edit,
      }
    }

    case "ACTIVATE_DETAIL_EDIT":
    {
      return{
        ...state,
        detailEditing: true,
      }
    }

    case "CANCEL_DETAIL_EDIT":
    {
      let vinEdit = Object.assign({},state.selected);
      let grpEdit = Object.assign({},state.selectedGroup);
      let devEdit = Object.assign({},state.selectedDevice);
      return{
        ...state,
        editingVin: vinEdit,
        editingGroup: grpEdit,
        editingDevice: devEdit
      }
    }

    case "CREATE_VIN_AND_GROUP_START":
    {
      return {
        ...state,
        detailLoading: true,
      }
    }

    case "CREATE_VIN_AND_GROUP_FINISHED":
    {

      let res = {...action.data};

      let vins = state.orgVins.slice();
      let groups = res.groupList;

      delete res['error'];
      delete res['success'];
      delete res['groupList'];
      delete res['up'];
      res.deviceCount = 0;
      res.groupCount = 1;
      vins.push(res);

      return {
        ...state,
        detailLoading: false,
        orgVins: vins,
        vinGroups: groups,
        selected: res,
        selectedGroup: groups[0],
        editingGroup: Object.assign({},groups[0])
      }
    }

    case "CREATE_GROUP_START":
    {
      return {
        ...state,
        detailLoading: true,
      }
    }

    case "CREATE_GROUP_FINISHED":
    {
      // console.log(" CREATE_GROUP_FINISHED: ",action);
      let vinGroups = [];
      if (state.vinGroups != null) {
        vinGroups = state.vinGroups.slice();
      }
      vinGroups.push(action.group);

      // console.log("Adding Group", action.group, vinGroups);
      return {
        ...state,
        detailLoading: false,
        vinGroups: vinGroups
      }
    }

    case "DELETE_VIN_START":
    {
      return {
        ...state,
        isSaving: true,

      }
    }

    case "DELETE_VIN_FINISHED":
    {
      // remove the vin from the vin List
      // update the device in the list
      const deleteVIN = (vinList) => {
        if (vinList == null) return null;

        let vins = [...state.allVins];

        console.log("DELETE_VIN_FINISHED", action.vinId);
        for (let i=0; i<vins.length; ++i)
        {
          if (vins[i].id == action.vinId)
          {
            vins.splice(i, 1);
            break;
          }
        }
        return vins;
      }

      return {
        ...state,
        allVins:deleteVIN(state.allVins),
        orgVins:deleteVIN(state.orgVins),
        selected:null,
        paramReplacementId: null,
        paramReplacementType: null,
        isLoading: false,
        detailLoading: false,
        isSaving: false

      }
    }

    case "DELETE_VIN_GROUP_START":
    {
      return {
        ...state,
        isSaving: true,

      }
    }

    case "DELETE_VIN_GROUP_FINISHED":
    {
      // remove the group from the groups list
      {
        // update the device in the list
        let groups = [...state.vinGroups];
        let groupId = action.groupId;

        console.log("VINMANAGE_DATA_LOADING_FINISHED", action.groupId);
        for (let i=0; i<groups.length; ++i)
        {
          if (groups[i].id == groupId)
          {
            console.log("deleteing", groups[i]);
            groups.splice(i, 1);
            break;
          }
        }

        return {
          ...state,
          vinGroups:groups,
          selectedGroup:null,
          paramReplacementType: null,
          paramReplacementId: null,
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
      }
    }

    case "LOAD_VIN_DEVICES_START":
    {
      return {
        ...state,
        isLoading: true,
        detailLoading: true,
        vinDevices: null,
        selectedDevice: null,
      }
    }

    case "LOAD_VIN_DEVICES_FINISHED":
    {
      // console.log('action ',action);
      let configList = action.data.configList;
      let groupList = action.data.groupList;

      let groupMap = {};

      for (const i in groupList){
        let g = groupList[i];
        groupMap[g.id] = g.name;
      }

      let devices = configList.map( (item,i) => {
        let name = groupMap[item.groupId];
        item.groupName = name;

        return item;
      });

      // sort by enabled then name
      devices = devices.sort((a,b) => {
        if (a.enable === b.enable)
        {
          // sort by name
          return a.device.name.localeCompare(b.device.name);
        }
        else
        {
          return a.enable ? 1 : -1;
        }
      });

      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        vinDevices: devices,
        vinGroups: action.data.groupList,
        selectedGroup: null
      }
    }

    case "LOAD_DEVICE_DETAIL":
    {
      // console.log("LOAD_DEVICE_DETAIL ",action);

      //Technically the vinDevices list is the configs that have device info
      //Selected device will be the config. Poorly named, yes.
      let configs = state.vinDevices;

      // console.log('configs ',configs);

      let selected;

      for (const i in configs){
        let config = configs[i];
        let d = config.device;

        if(d.id === action.data){
          selected = Object.assign({}, config);
          break;
        }
      }

      let edit = {...selected};
      return {
        ...state,
        selectedDevice: selected,
        editingDevice: edit,
      }
    }

    case "DELETE_DEVICE_START":
    {
      return{
        ...state,
        isSaving: true,
      }
    }

    case "DELETE_DEVICE_FINISHED":
    {
        // update the device in the list
        let devices = [...state.vinDevices];
        for (let i=0; i<devices.length; ++i)
        {
          if (devices[i].device.id == action.deviceId)
          {
            devices.splice(i, 1);
            break;
          }
        }
        return {
          ...state,
          vinDevices:devices,
          selectedDevice:null,
          paramReplacementId: null,
          paramReplacementType: null,
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
    }

    case "APPROVE_DEVICE_START":
    {
      return{
        ...state,
        isSaving: true,
      }
    }

    case "VINMANAGE_DATA_SAVING":
    {
      return {
        ...state,
        isSaving: true,
      }
    }

    case "VINMANAGE_DATA_SAVING_FINISHED":
    {
      if (action.dataType == "device" && state.vinDevices != null)
      {
        console.log("VINMANAGE_DATA_LOADING_FINISHED", action.device);
        // update the device in the list
        let devices = [...state.vinDevices];
        for (let i=0; i<devices.length; ++i)
        {
          if (devices[i].id == action.device.id)
          {
            let newDevice = action.device;
            console.log("replacing", devices[i], newDevice);
            devices[i] = newDevice;
            break;
          }
        }
        let selectedDevice = state.selectedDevice;
        console.log("chekcing selected device", selectedDevice, action.device);
        if (selectedDevice != null && selectedDevice.id == action.device.id)
        {
          console.log("replacing selected device", selectedDevice, action.device);
          selectedDevice = action.device;
        }
        return {
          ...state,
          vinDevices:devices,
          selectedDevice:selectedDevice,
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
      }
      if (action.dataType == "approve" && state.vinDevices != null)
      {
        console.log("VINMANAGE_DATA_SAVING_FINISHED", action.vinId, action.deviceId);
        // update the device in the list
        let devices = [...state.vinDevices];
        for (let i=0; i<devices.length; ++i)
        {
          if (devices[i].device.id == action.deviceId)
          {
            let newDevice = {...devices[i], enable:true};
            console.log("replacing", devices[i], newDevice);
            devices[i] = newDevice;
            break;
          }
        }
        let selectedDevice = state.selectedDevice;
        if (selectedDevice != null && selectedDevice.device.id == action.deviceId)
        {
          selectedDevice = {...selectedDevice, enable:true};

        }
        return {
          ...state,
          vinDevices:devices,
          selectedDevice:selectedDevice,
          editingDevice: {...selectedDevice},
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
      }
      else if (action.dataType == "invite" || action.dataType == "group")
      {
        // update the device in the list
        let groups = [...state.vinGroups];
        let newGroup = action.group;

        console.log("VINMANAGE_DATA_LOADING_FINISHED", action.group, groups);
        if (newGroup.connectionIds != null){
          newGroup.linkedGroupCount = newGroup.connectionIds.length;
        }else{
          newGroup.linkedGroupCount = 0;
        }

        for (let i=0; i<groups.length; ++i)
        {
          if (groups[i].id == newGroup.id)
          {
            console.log("replacing", groups[i], newGroup);
            groups[i] = newGroup;
            break;
          }
        }
        let selectedGroup = state.selectedGroup;
        if (selectedGroup != null && selectedGroup.id == newGroup.id)
        {
          selectedGroup = newGroup;
        }
        return {
          ...state,
          vinGroups:groups,
          selectedGroup:selectedGroup,
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
      }

      else if (action.dataType == "vin")
      {

        let newVIN = action.vin;
        let updateVIN = (vinList) =>
        {
          if (vinList == null) return null;
          // update the device in the list
          let vins = [...vinList];

          for (let i=0; i<vins.length; ++i)
          {
            if (vins[i].id == newVIN.id)
            {
              console.log("replacing", vins[i], newVIN);
              vins[i] = {...vins[i], name:newVIN.name, cidr:newVIN.cidr, cipher:newVIN.cipher};
              break;
            }
          }
          return vins;
        }

        let selectedVIN = state.selected;
        if (selectedVIN != null && selectedVIN.id == newVIN.id)
        {
          selectedVIN = newVIN;
        }
        return {
          ...state,
          allVins:updateVIN(state.allVins),
          orgVins:updateVIN(state.orgVins),
          selected:selectedVIN,
          isLoading: false,
          detailLoading: false,
          isSaving: false
        }
      }


      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        isSaving: false
      }
    }

    case "VINS_SAVING_ERROR":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        isSaving: false,
        error: action.data,
      }
    }

    case "VINS_LOAD_ERROR":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        error: action.data,
      }
    }

    case "VINMANAGE_DATA_LOADING":
    {
      return {
        ...state,
        isLoading: true,
        detailLoading: true,
      }
    }

    case "VINMANAGE_DATA_LOADING_FINISHED":
    {

      return {
        ...state,
        isLoading: false,
        detailLoading: false,
      }
    }

    case "LOAD_CHECKLINK_START":
    {
      return {
        ...state,
        detailLoading: true,
        groupCheckId: action.data
      }
    }

    case "LOAD_CHECKLINK_FINISHED":
    {
      return {
        ...state,
        detailLoading: false,
        groupCheckId: null
      }
    }

    default:
      return state;
  }

}
