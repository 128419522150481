export const linkToOrgVins = (orgId) =>{
  if(orgId == null || orgId === 'all'){
    return '/vins/org/all';
  }else{
    return '/vins/org/'+orgId;
  }
};

export const linkToSelectedVin = (orgId, vinId) => {
  let base = linkToOrgVins(orgId);
  if(vinId == null){
    return base;
  }
  return base + '/vin/'+vinId;
};


export const linkToSelectedGroup = (orgId, vinId, groupId) => {
  let base = linkToSelectedVin(orgId,vinId);

  if (groupId == null){
    return base + '/group/all';
  }
  return base + '/group/'+groupId;
};

export const linkToSelectedDevice = (orgId, vinId, deviceId) => {
  let base = linkToSelectedVin(orgId,vinId);
  if(deviceId == null){
    return base + '/device/all';
  }
  return base + '/device/' + deviceId;
};

export const linkToSelectedUser = (userId) => {
  return '/users/user/'+userId;
};

export const linkToSelectedOrg = orgId => {
  return '/orgs/org/'+orgId;
};

export const labelKeyForSideBar = (link, isAdmin, access) => {
  switch (link) {
    case '/dashboard':
    {
      return 'sidebarnav:dashboard'
    }
    case '/vins/org/all':
    {
      if (isAdmin === true) {
        return 'sidebarnav:allVins';
      }
      return "VINs";
    }
    case '/orgs':
    {
      return 'sidebarnav:orgs';
    }
    case '/users':
    {
      return 'sidebarnav:users';
    }
    case  '/certificates':
    {
      return 'sidebarnav:certificates';
    }
    case  '/licences':
    {
      return 'sidebarnav:licences';
    }
    case  '/settings':
    {
      return 'sidebarnav:settings';
    }

    default:{
      return link;
    }
  }
};

export default {linkToOrgVins, linkToSelectedVin, linkToSelectedGroup, linkToSelectedDevice, linkToSelectedUser, linkToSelectedOrg, labelKeyForSideBar};
