/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NewLicenseDetails,
    NewLicenseDetailsFromJSON,
    NewLicenseDetailsToJSON,
    RESTResultLicenseDetails,
    RESTResultLicenseDetailsFromJSON,
    RESTResultLicenseDetailsToJSON,
} from '../models';

export interface PutLicenseRequest {
    xForwardedHost?: string;
    newLicenseDetails?: NewLicenseDetails;
}

/**
 * 
 */
export class LicenseApi extends runtime.BaseAPI {

    /**
     * Get the license details.
     */
    async getLicenseRaw(): Promise<runtime.ApiResponse<RESTResultLicenseDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultLicenseDetailsFromJSON(jsonValue));
    }

    /**
     * Get the license details.
     */
    async getLicense(): Promise<RESTResultLicenseDetails> {
        const response = await this.getLicenseRaw();
        return await response.value();
    }

    /**
     * Put a new license
     */
    async putLicenseRaw(requestParameters: PutLicenseRequest): Promise<runtime.ApiResponse<RESTResultLicenseDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xForwardedHost !== undefined && requestParameters.xForwardedHost !== null) {
            headerParameters['X-Forwarded-Host'] = String(requestParameters.xForwardedHost);
        }

        const response = await this.request({
            path: `/settings/license`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewLicenseDetailsToJSON(requestParameters.newLicenseDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultLicenseDetailsFromJSON(jsonValue));
    }

    /**
     * Put a new license
     */
    async putLicense(requestParameters: PutLicenseRequest): Promise<RESTResultLicenseDetails> {
        const response = await this.putLicenseRaw(requestParameters);
        return await response.value();
    }

}
