import { createMuiTheme } from '@material-ui/core';
import typography from './typography';

/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
//import vars from '!!sass-vars-to-js-loader!./_variables-mui.scss';
import vars from '!!sass-vars-to-js-loader!./_variables-mui.scss';

/*
//just couldn't get this to work, so i just included the fonts from the netlinkz site. We'll have to change that at some point
// they are in the base.css somewhere

import HovesMedium1 from 'fonts/webFonts/TTHoves-Medium/font.woff2';
import HovesMedium2 from 'fonts/webFonts/TTHoves-Medium/font.woff';

const hovesMedium = {
  fontFamily: 'tt-hoves-medium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
  local('tt-hoves-medium'),
  url(${HovesMedium1}) format('woff2'), url(${HovesMedium2}) format('woff')
  `
};
*/

const NetLinkzGlobalTheme = createMuiTheme({
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1510,
      xl: 1920,
    }
  },
  palette: {
    primary: {
      main: vars.primary,
      light: vars.primaryLight,
      dark: vars.primaryDark,

    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2
    },
    secondary: {
      main: vars.secondary,

    },
    error: {
      main: vars.red
    },
    success: {
      main: vars.green
    },
    warning: {
      main: vars.orange
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)'
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
    common:{
      black: vars.secondaryBlack,
      navy: vars.secondaryNavy,
      grey: vars.secondaryGrey,
      warmLightGrey: vars.secondaryWarmLightGrey,
      paleBlue: vars.secondaryPaleBlue,
      blue: vars.primaryBlue,
      green: vars.primaryGreen,
      mainContentBg: vars.contentMainBackground,
      detailContentBg: vars.contentDetailBackground,
      actionsContentBg: vars.contentActionsBackground,
      regularText: vars.textPrimary,
      disabledText: vars.textDisabledGrey,
      cardHeaderBg: vars.cardHeaderBackground,
      cardFormBg: vars.cardFormBackground,
      popoverBg: vars.popoverLightYellowBackgournd,
      warningBackground:vars.warningBackground,
      warningText:vars.warningText,
      superUser:vars.superUser,
    },
    buttons:{
      primary: vars.primaryBtnBackground,
      primaryHover: vars.primaryBtnHover,
      secondary: vars.secondaryBtnBackground,
      secondaryHover: vars.secondaryBtnHover,
      grey: vars.greyBtnBackground,
      greyHover: vars.greyBtnHover,
      disabled: vars.btnDisabled,
      delete: vars.deleteBtnBackground,
      deleteHover: vars.deleteBtnHover,
      deleteDisabled: vars.deleteBtnDisabled,
      deleteDisabledText: vars.deleteBtnDisabledText,
      FABDisabled: vars.FABDisabled,
      FABDisabledText: vars.FABDisabledText
    },
    table: {
      headerBg : vars.tableHeaderBackground,
      rowBg: vars.tableRowBackgroundDefault,
      rowSelectedBg: vars.tableRowSelected,
      rowOdd: vars.tableRowBackgroundOdd,
      rowOver: vars.tableRowMouseOver,
      rowDeviceNotApproved: vars.tableRowDeviceNotApprovedBackground,
      text: vars.tableDefaultText,
      tableTopHeader: vars.tableTopHeader
    },
    secondarySideBar: {
      text: vars.navbarText,
      textSelected: vars.primary,
      itemBg: vars.secondaryBarItemBackground,

    }
  },
  shape: {
    borderRadius: '0.5rem',
    textFieldSize: 'small',
    buttonBorderRadius: '0.25rem',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@body':{
          backgroundColor:'blue !important',
          minWidth:1500,
          overflow:'scroll'
        }
      }
    },
    CssBaseline: {
      '@global': {
        body:{
          backgroundColor:'blue',
          minWidth:1500,
          overflow:'scroll'
        }
      }
    },
    MuiGrid: {
      container:
        {
          backgroundColor:'white'
        }
    },
    MuiSecondaryMenuItem: {
        /* new stuff */
        backgroundColor:'white',
        fontSize:14,
        height:26,
        marginBottom:8
    },
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px'
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px'
      },
      arrow: {
        color: vars.second
      }
    },
    MuiTableRow:{
      root:{
        '&$selected, &$selected:hover, &$selected:nth-of-type(odd), &$selected:nth-of-type(odd):hover':{
          backgroundColor: vars.tableRowSelected,
        },
        '&:nth-of-type(odd)': {
          backgroundColor: vars.tableRowBackgroundOdd,
        },
      },
      hover:{
        '&$selected, &$selected:hover, &$selected:nth-of-type(odd), &$selected:nth-of-type(odd):hover':{
          backgroundColor: vars.tableRowSelected,
        },
        '&:hover':{
          backgroundColor: vars.tableRowMouseOver,
          cursor: 'pointer',
        }
      }
    },
    MuiTableCell: {
      head:{
        backgroundColor: vars.tableHeaderBackground,
        fontWeight:'bold',
        cursor: 'default',
      },
      stickyHeader:{
        backgroundColor: vars.tableHeaderBackground,
        fontWeight:'bold',
        cursor: 'default',
      },
      root: {
        fontSize:12,
        paddingLeft:16,
        paddingRight:16,
        paddingTop:7,
        paddingBottom:7,
        color: vars.tableDefaultText,
      },
      body: {
        color: vars.tableDefaultText,
      }
    },
    MuiFab: {
      root: {
        width:40,
        height:40
      }
    },
    MuiOutlinedInput:{
      root:{
        backgroundColor:'white',

        "&:hover input[readonly]+fieldset": {
          borderColor: 'rgba(0, 0, 0, 0.26)',
        },
        "&:hover input[readonly]+svg+fieldset": {
          borderColor: 'rgba(0, 0, 0, 0.26)',
        },
        "&:hover input[readonly]": {
          cursor:'default'
        },
      },
      notchedOutline:{
        borderRadius:"4px"
      }
    },
    MuiInputBase:{
      input:{
        "&.Mui-disabled":{
          color:vars.textPrimary
        }
      }
    },
    MuiTabs:{
      root:{
        color: vars.primaryBlue,

      },
      indicator:{
        backgroundColor: vars.primaryBlue,
      }
    }
  }
});

//MuiInputBase-input MuiOutlinedInput-input
// put different themes in here
const MuiTheme = NetLinkzGlobalTheme;
//const MuiTheme = AnotherTheme;

export default MuiTheme;
