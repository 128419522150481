import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';

import ContentLayout from 'layouts/Content/ContentLayout';
import CreateVin from 'pages/VIN Pages/CreateVin';

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps ', ownProps);
  let params = ownProps.match.params;
  let orgs = [];
  if (state.session.sessionData != null){
    orgs = state.session.sessionData.organisations;
  }

  return {
    orgId: params.org_id,
    detailLoading: state.vinsgroups.detailLoading,
    mainLoading: state.vinsgroups.isLoading,
    detailEditing: state.vinsgroups.detailEditing,
    allVins: state.vinsgroups.allVins,
    orgMap: state.session.orgMap,
    ciphers: state.session.ciphers,
    organisations: orgs,
    error: state.vinsgroups.error,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    saveNewVinAndGroup: (info) => {

      console.log('save new vin ',info);

      let vin = info.vin;
      let group = info.group;

      //group.enrolment will be 'auto', 'manual' or 'off' .
      let enabled = false;
      let autoEnable = false;
      if (group.enrolment === 'auto'){
        enabled = true;
        autoEnable = true;
      }
      if (group.enrolment === 'manual'){
        enabled = true;
        autoEnable = false;
      }

      let req = {
        orgId: vin.orgId,
        newNetworkDetails: {
          name: vin.name,
          description: vin.description,
          cidr: vin.cidr,
          cipher: vin.cipher,
          group: {
            name: group.name,
            description: group.description,
            meshType: group.meshType,
            enabled: enabled,
            autoEnable: autoEnable
          }
        }
      };

      SessionManager.shared().createNewVinAndGroup(req).then(result => {
        if (result != null && result.success === true) {
          let created = result.data;
          let grp = created.groupList[0];

          SessionManager.shared().gotoGroup(vin.orgId, created.id, grp.id);
        }
      });
    },

    loadVins: () => {
      SessionManager.shared().loadVins();
    },

    cidrInUse: (template) => {
      dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: template, field:'cidr',validation:'validation:fieldCidrDuplicate'}});
    }
  };
};

class CreateVinView extends React.Component{

  render() {
    // console.log("CreateVinView:: ",this.props);
    let detail = React.cloneElement(<CreateVin />, this.props);

    return <ContentLayout detail={detail} /> ;
  }

  componentDidMount() {
    this.props.loadVins();
  }
}

const CreateVinContainer = connect (mapStateToProps,mapDispatchToProps)(CreateVinView);
export default CreateVinContainer;
