/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RESTResultBoolean
 */
export interface RESTResultBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultBoolean
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultBoolean
     */
    error?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultBoolean
     */
    data?: boolean;
}

export function RESTResultBooleanFromJSON(json: any): RESTResultBoolean {
    return RESTResultBooleanFromJSONTyped(json, false);
}

export function RESTResultBooleanFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultBoolean {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function RESTResultBooleanToJSON(value?: RESTResultBoolean | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': value.data,
    };
}


