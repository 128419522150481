import React from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import VinMain from 'pages/VIN Pages/VinMain';
import ContentLayout from 'layouts/Content/ContentLayout';
import VinDetail from 'pages/VIN Pages/VinDetail';
import SecondarySideBar from 'components/SecondarySideBar/SecondarySideBar';
import { VINActionTab } from 'helpers/constants';
import { withTranslation } from 'react-i18next';
import GroupDetail from 'pages/VIN Pages/GroupDetail';
import DeviceDetail from 'pages/VIN Pages/DeviceDetail';
import { snackAlertSuccess} from 'helpers/alerting';

const readParams = (props) =>
{
  let params = props.match.params;
  // console.log("PARAMS = ", params);

  let orgId = params.org_id;
  let vinId = params.vin_id;
  if (vinId == null && props.paramReplacementType==='vin')
  {
    vinId = props.paramReplacementId;
  }
  let groupId = params.group_id;
  if (groupId == null && props.paramReplacementType==='group')
  {
    groupId = props.paramReplacementId;
  }
  let deviceId = params.device_id;
  if (deviceId == null && props.paramReplacementType==='device')
  {
    deviceId = props.paramReplacementId;
  }

  let tab = params.tab;
  // console.log("read params", orgId, vinId, groupId, deviceId, tab);
  return {orgId, vinId, groupId, deviceId, tab};
}

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps ', ownProps);
  let params = ownProps.match.params;

  return {
    isAuthenticated: state.session.isAuthenticated,
    orgId: params.org_id,
    mainLoading: state.vinsgroups.isLoading,
    detailLoading: state.vinsgroups.detailLoading,
    isSaving: state.vinsgroups.isSaving,
    orgVins: state.vinsgroups.orgVins,
    orgMap: state.session.orgMap,
    selectedVin: state.vinsgroups.selected,
    editingVin: state.vinsgroups.editingVin,
    selectedVinGroups: state.vinsgroups.vinGroups,
    selectedGroup: state.vinsgroups.selectedGroup,
    editingGroup: state.vinsgroups.editingGroup,
    actionTab: state.vinsgroups.actionTab,
    ciphers: state.session.ciphers,
    detailEditing: state.vinsgroups.detailEditing,
    vinDevices: state.vinsgroups.vinDevices,
    selectedDevice: state.vinsgroups.selectedDevice,
    editingDevice: state.vinsgroups.editingDevice,
    error: state.vinsgroups.error,
    paramReplacementType: state.vinsgroups.paramReplacementType,
    paramReplacementId: state.vinsgroups.paramReplacementId,
    groupCheckId: state.vinsgroups.groupCheckId
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    loadVinsForOg: (orgId) => {
      if(orgId === 'all'){
        SessionManager.shared().loadVins();
      }else {
        SessionManager.shared().loadVinsForOrganisation(orgId);
      }
    },

    loadVinDetail: (vinId) => {
      let params = ownProps.match.params;
      SessionManager.shared().gotoVIN(params.org_id, vinId);

    },

    loadVinGroups: (vinId, type) => {
      let params = ownProps.match.params;
      SessionManager.shared().gotoVinTab(params.org_id, vinId, type, null);

    },

    loadGroupDetail: (groupId) => {
      let params = ownProps.match.params;
      SessionManager.shared().gotoGroup(params.org_id, params.vin_id, groupId);
    },

    loadGroupTopologyDetail: (groupId) => {
      let params = ownProps.match.params;
      SessionManager.shared().gotoVinTab(params.org_id, params.vin_id, VINActionTab.TOPOLOGY, groupId);
    },

    detailEdit: (type, field, value) => {
      //dispatch({ type:"ACTIVATE_DETAIL_EDIT"});
      dispatch({ type:"DETAIL_OBJECT_FIELD_CHANGE", data:{objType:type, field:field, value: value}});
    },

    cancelDetailEdit: (template) => {
      dispatch({ type:"CANCEL_DETAIL_EDIT"});
      dispatch({type: "VALIDATION_CLEAR_ALL"});
    },

    deleteVin: (vinId) => {
      SessionManager.shared().deleteVin(vinId).then( result => {
        if (result != null && result.success === true)
        {
          snackAlertSuccess("snack:deletedvin");
        }
      });
    },

    deleteGroup: (vinId, groupId) => {

      SessionManager.shared().deleteGroupFromVin(vinId,groupId).then( result => {

        if (result != null && result.success === true)
        {
          snackAlertSuccess("snack:deletedgroup");
        }

      });
    },

    updateVin: (info) => {

      // console.log('update vin: ',info);
      // console.log('ownprops ',ownProps);

      let vinId = info.id;
      let update = {
        name:info.name,
        description: info.description,
      };

      SessionManager.shared().updateVin(vinId,update).then( result => {

        if (result != null && result.success === true){
          snackAlertSuccess("snack:changessaved");
        }
      });
    },

    regenerateInvite: (vinId, groupId) => {

      SessionManager.shared().regenerateInvite(vinId,groupId).then( result => {
        if (result != null && result.success === true){
          snackAlertSuccess("snack:createdinvite");
          // SessionManager.shared().loadVinGroups(vinId).then( result => {
          //   if (result != null && result.success === true){
          //     SessionManager.shared().loadGroupDetail(vinId, groupId);
          //     snackAlertSuccess("snack:createdinvite");
          //     dispatch({ type:"VINMANAGE_DATA_LOADING_FINISHED"});
          //   }
          // });
        }
      });
    },


    updateGroup: (info) => {

      // console.log('updateGruop ',info);

      //if the invite enrolment has been changed
      //there will be an enrolment field.
      //If not have to get teh auto/enable info from the first Invite.

      let enable = false;
      let auto = false;
      if (info.enrolment != null) {
        if (info.enrolment === 'auto') {
          enable = true;
          auto = true;
        }
        if (info.enrolment === 'manual') {
          enable = true;
          auto = false;
        }
      }else{
        if (info.invites != null && info.invites.length > 0) {
          let inv = info.invites[0];
          enable = inv.enabled;
          auto = inv.autoEnabled;
        }
      }

      let details = {
        name: info.name,
        description: info.description,
        networkId: info.networkId,
        meshType: info.meshType,
        enabled: enable,
        autoEnable: auto
      };


      let req = {
        vinId: info.networkId,
        groupId: info.id,
        newGroupDetails: details
      };

      // console.log('req ',req);

      SessionManager.shared().updateGroup(req).then( result => {
        if (result != null && result.success === true)
        {
          snackAlertSuccess("snack:changessaved");
        }
      });
    },

    updateGroupLinks: (info) => {
      // console.log('updateGroup Links ',info);

      let req = {
        vinId: info.networkId,
        groupId: info.id,
        requestBody: info.connectionIds
      };

      // console.log('req ',req);

      SessionManager.shared().updateGroupLinks(req).then( result => {
        if (result != null && result.success === true){
          SessionManager.shared().loadVinGroups(info.networkId).then( result => {
            if (result != null && result.success === true) {
              SessionManager.shared().loadGroupDetail(info.networkId, info.id);
              dispatch({ type:"VINMANAGE_DATA_LOADING_FINISHED"});
              snackAlertSuccess("snack:changessaved");
            }
          });
        }
      });
    },

    loadDevices: (vinId) => {

      dispatch({ type:"VIN_TAB_SELECTED", data: VINActionTab.DEVICES});
      SessionManager.shared().loadVinDevices(vinId);

      let params = ownProps.match.params;
      SessionManager.shared().gotoDevice(params.org_id, vinId, null);
    },

    loadDeviceDetail: (deviceId) => {
      let params = ownProps.match.params;
      SessionManager.shared().gotoDevice(params.org_id, params.vin_id,deviceId);
    },

    approveDevice: (vinId, deviceId) => {
      SessionManager.shared().approveDevice(vinId,deviceId).then( result => {
              snackAlertSuccess("snack:deviceapproved");
        });
    },

    deleteDevice: (vinId, deviceId) => {
      SessionManager.shared().deleteDevice(vinId,deviceId).then(result => {
        if (result != null && result.success === true){
              snackAlertSuccess("snack:devicedeleted");
        }
      });
    },

    // this will decide if the thing is still loading, based on the
    // parameters etc
    isLoading: (props) =>
    {
      let {vinId, groupId, deviceId} = readParams(props);

      // console.log("Checking is Loading vinId, groupId deviceId", vinId, groupId, deviceId, props);

      if (props.mainLoading)
      {
        console.log("  Main Loading");
        return true;
      }

      // if we have a group_id, then we have to wait for the groups to load
      if (groupId != null && (props.selectedVinGroups == null && props.actionTab==null))
      {
        console.log("  Groups Loading");
        return true;
      }

      if (deviceId != null && (props.vinDevices == null || props.actionTab == null))
      {
        console.log("  Devices Loading");
        return true;
      }
      return false;
    },


    updateDevice: (vinId, deviceId, groupId) => {
      console.log(" Changing GroupId ", vinId, deviceId, groupId);
      SessionManager.shared().updateDevice(vinId, deviceId, groupId);
    },

    checkLink: (vin, group, links) => {

      SessionManager.shared().checkLinkValid(vin,group,links).then( result => {
        if (result != null && result.success === true){
          dispatch({ type:"DETAIL_OBJECT_FIELD_CHANGE", data:{objType:"group", field:"connectionIds", value: links}});
        }
      });

    }

  };
};

class ManageVinView extends React.Component{

  constructor(props) {
    super(props);

    this.actionHandler = this.actionHandler.bind(this);
    this.load = this.load.bind(this);

  }

  actionHandler(type){
    switch (type) {

      case VINActionTab.GROUPS:
      case VINActionTab.TOPOLOGY:
      {
        // console.log('action handler Groups ',this.props.selectedVin);
        if (this.props.selectedVin != null){
          this.props.loadVinGroups(this.props.selectedVin.id, type);
        }
        break;
      }
      case VINActionTab.DEVICES:
      {
        if (this.props.selectedVin != null){
          this.props.loadDevices(this.props.selectedVin.id);
        }
        break;
      }

      default:
        console.log('action - ',type);
    }
  }

  load(orgId, vinId, groupId, deviceId, tab)
  {
    console.log("ManageVinView:: Loading ", orgId, vinId, groupId, deviceId, tab);

    if (vinId == null)
    {
      this.props.loadVinsForOg(orgId);
    }
    else
    {
      SessionManager.shared().loadToVinGroupDevice(orgId,vinId,groupId,deviceId, tab);
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let old = readParams(prevProps);
    let {orgId, vinId, groupId, deviceId, tab} = readParams(this.props);

    //Note: The component is unmounted and reloaded when loading different org vin links (/vins/org/:org_id)
    //So, checking old.orgId against orgId doesn't always work in that case. When a vin is selected the vins
    //for the new org would not load because it wouldn't see a difference. Clicking org links triggers a redux call
    //to clear the selected vin which will load vins for the org.

    if (old.orgId !== orgId || old.vinId !== vinId || old.groupId !== groupId ||  old.deviceId !== deviceId || tab !== old.tab)
    {
      this.load(orgId, vinId, groupId, deviceId, tab);
    }

  }

  // componentWillUnmount() {
  //   console.log("    XXX >> ManageView will Unmount");
  //   SessionManager.shared().store.dispatch({type: "UNLOAD_VIN_DETAL"});
  // }

  componentDidMount() {

    // console.log('ManageVinView:: did mount ',this.props);

    // /vins/org/:org_id/vin/:vin_id/group/:group_id
    //                              /device/:device_id

    let {orgId, vinId, groupId, deviceId, tab} = readParams(this.props);

    this.load(orgId, vinId, groupId, deviceId, tab);
  }

  render() {
    // console.log("ManageVinView:: ",this.props);

    const { t } = this.props;

    if(this.props.isAuthenticated === false){
      window.location = "/";
    }

    let detail = null;

    if(this.props.actionTab === VINActionTab.GROUPS){
      detail = React.cloneElement(<GroupDetail />, this.props);
    }
    else if (this.props.actionTab === VINActionTab.DEVICES){
      detail = React.cloneElement(<DeviceDetail />, this.props);
    }
    else if (this.props.actionTab === VINActionTab.TOPOLOGY){
      detail = React.cloneElement(<GroupDetail />, this.props);
    }
    else{
      detail = React.cloneElement(<VinDetail />, this.props);
    }

    let actionDisabled = this.props.selectedVin == null;
    let options = [
    {type: VINActionTab.GROUPS, title: t('sidebarTab.vinGroups'), action: () =>{this.actionHandler(VINActionTab.GROUPS)}, disabled: actionDisabled, selected: this.props.actionTab === VINActionTab.GROUPS},
    {type: VINActionTab.DEVICES, title: t('sidebarTab.devices'), action: () => {this.actionHandler(VINActionTab.DEVICES)}, disabled: actionDisabled, selected: this.props.actionTab === VINActionTab.DEVICES},
    {type: VINActionTab.TOPOLOGY, title: t('sidebarTab.topology'), action: () => {this.actionHandler(VINActionTab.TOPOLOGY)}, disabled: actionDisabled, selected: this.props.actionTab === VINActionTab.TOPOLOGY},
    {type: VINActionTab.PERFORMANCE, title: t('sidebarTab.perf'), disabled: actionDisabled, selected: this.props.actionTab === VINActionTab.PERFORMANCE},
    {type: VINActionTab.AUDIT, title: t('sidebarTab.audit'), disabled: actionDisabled, selected: this.props.actionTab === VINActionTab.AUDIT},
  ];

    let vinName = (this.props.editingVin==null)?null:this.props.editingVin.name;
    let side = <SecondarySideBar actions={options} active={!actionDisabled} vinName={vinName} />;
    let main = React.cloneElement(<VinMain nav={side}/>, this.props);

    return <ContentLayout main={main} detail={detail} mainStyle={{paddingLeft:0,paddingRight:0}} /> ;
  }
}

const VinManageContainer = connect (mapStateToProps,mapDispatchToProps)(withTranslation('vinsgroups')(ManageVinView));
export default VinManageContainer;
