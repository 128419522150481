import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper, IconButton } from '@material-ui/core';

import { connect } from 'react-redux';

import SidebarHeader from 'layouts/LeftSidebar/SidebarHeader';
// import SidebarUserbox from 'layouts/LeftSidebar/SidebarUserbox';
import SidebarMenu from 'layouts/LeftSidebar/SidebarMenu';
// import SidebarFooter from 'layouts/LeftSidebar/SidebarFooter';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { labelKeyForSideBar } from 'helpers/links';

import {ReactComponent as MenuIconVIN} from 'assets/netlinkz/menuVin.svg'
import {ReactComponent as MenuIconDash} from 'assets/netlinkz/menuDashboard.svg'
import {ReactComponent as MenuIconCerts} from 'assets/netlinkz/menuCerts.svg'
import {ReactComponent as MenuIconLecenses} from 'assets/netlinkz/menuLicenses.svg'
import {ReactComponent as MenuIconOrgs} from 'assets/netlinkz/menuOrgs.svg'
import {ReactComponent as MenuIconSettings} from 'assets/netlinkz/menuSettings.svg'
import {ReactComponent as MenuIconUsers} from 'assets/netlinkz/menuUsers.svg'

import { useTranslation } from 'react-i18next';

import {
  setSidebarToggleMobile,
  setSidebarHover,
  setSidebarToggle,
  setSidebarFooter,
  setSidebarUserbox
} from 'reducers/ThemeOptions';
import SessionManager from 'session/SessionManager';


const DashboardBadge = props =>
{
  let appendingBadge = null;
  let alertBadge = null;
  let badgeStyle = {padding:0, marginLeft:5, fontSize:12, textAlign:"center", width:20, height:20}
  if (props.alertCount > 0)
  {
    alertBadge = (
      <div className="badge badge-pill badge-danger" style={badgeStyle}>
        {props.alertCount}
      </div>);
  }

  if (props.approvalCount > 0)
  {
    appendingBadge = (
      <div className="badge badge-pill badge-warning" style={badgeStyle}>
        {props.approvalCount}
      </div>);
  }

  return (
    <div>
      {appendingBadge}
      {alertBadge}
    </div>
  )
}

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarHover,
    setSidebarHover,
    sidebarToggle,
    sidebarUserbox,
    sidebarShadow,
    sidebarFooter,
    session
  } = props;

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  let navItems = [];
  let content = [];

  let orgItems = [];

  const { t } = useTranslation('sidebarnav');

  let approvalCount = 0;
  let alertCount = 0;
  let licenseCount = 0;

  if(props.session != null) {

    approvalCount = props.session.pendingApprovals==null ? 0 : props.session.pendingApprovals.length;
    alertCount =  props.alerts == null ? 0 : props.alerts.length;
    licenseCount = props.session.licensed === false ? 1 : 0;

    for (let i = 0; i < props.session.organisations.length; i++) {
      let org = props.session.organisations[i];
      orgItems.push({
        label: org.name,
        icon: null,
        to: '/vins/org/'+org.id
      });
    }
  }
  const icons = {
    '/dashboard':MenuIconDash,
    '/vins/org/all':MenuIconVIN,
    '/orgs':MenuIconOrgs,
    '/users':MenuIconUsers,
    '/certificates':MenuIconCerts,
    '/licences':MenuIconLecenses,
    '/settings':MenuIconSettings
    };

  let sideBarLinks = SessionManager.shared().getSideBarLinks();

  for (const i in sideBarLinks){
    let link = sideBarLinks[i];

    if (link === '/dashboard'){
      content.push({
        label: t(labelKeyForSideBar(link, props.session.admin, props.access)),
        icon: icons[link],
        to: '/dashboard',
        badge: (props) => DashboardBadge(
          {...props,
            approvalCount:approvalCount,
            alertCount: alertCount
          })

      });
    }else if (link === '/licences'){
      content.push({
        label: t(labelKeyForSideBar(link, props.session.admin, props.access)),
        icon: icons[link],
        to: link,
        badge: (props) => DashboardBadge(
          {...props,
            approvalCount: 0,
            alertCount: licenseCount
          })
      });
    } else{
      content.push({
        label: t(labelKeyForSideBar(link, props.session.admin, props.access)),
        icon: icons[link],
        to: link
      });
    }
  }

  if (session != null && session.admin === true){
    content.splice(2,0,{
      label: t('sidebarnav:vingroups'),
      icon: MenuIconVIN,
      content: orgItems,
    })
  }

  navItems.push({
    key: "MainNav",
    content: content
  });


  const sidebarMenuContent = (
    <div style={{marginTop:24}}
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}>
      {navItems.map(list => (
        <SidebarMenu
          component="div"
          key={list.key}
          pages={list.content}
          title={list.label}
        />
      ))}
    </div>
  );

   // console.log('SideBar:: ',props);

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
            {/*{sidebarUserbox && <SidebarUserbox />}*/}
            {sidebarMenuContent}
            {/*{sidebarFooter && <SidebarFooter />}*/}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {/*{sidebarUserbox && <SidebarUserbox />}*/}
              {sidebarMenuContent}
              {/*{sidebarFooter && <SidebarFooter />}*/}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,

  session : state.session.sessionData,
  access: state.session.access,
  alerts : state.session.alerts

});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarHover: enable => dispatch(setSidebarHover(enable)),
  setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
  setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
