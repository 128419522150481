/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrgDetails,
    OrgDetailsFromJSON,
    OrgDetailsToJSON,
    RESTResultBoolean,
    RESTResultBooleanFromJSON,
    RESTResultBooleanToJSON,
    RESTResultListOrgDetails,
    RESTResultListOrgDetailsFromJSON,
    RESTResultListOrgDetailsToJSON,
    RESTResultOrgDetails,
    RESTResultOrgDetailsFromJSON,
    RESTResultOrgDetailsToJSON,
    RESTResultOrganisation,
    RESTResultOrganisationFromJSON,
    RESTResultOrganisationToJSON,
} from '../models';

export interface DeleteOrganisationRequest {
    orgId: number;
}

export interface GetOrganisationRequest {
    orgId: number;
}

export interface PostOrganisationRequest {
    orgDetails?: OrgDetails;
}

export interface PutOrganisationRequest {
    orgId: number;
    orgDetails?: OrgDetails;
}

/**
 * 
 */
export class OrganisationApi extends runtime.BaseAPI {

    /**
     * Deletes an organisations.
     */
    async deleteOrganisationRaw(requestParameters: DeleteOrganisationRequest): Promise<runtime.ApiResponse<RESTResultBoolean>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling deleteOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultBooleanFromJSON(jsonValue));
    }

    /**
     * Deletes an organisations.
     */
    async deleteOrganisation(requestParameters: DeleteOrganisationRequest): Promise<RESTResultBoolean> {
        const response = await this.deleteOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all organisations.
     */
    async getAllRaw(): Promise<runtime.ApiResponse<RESTResultListOrgDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/org`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListOrgDetailsFromJSON(jsonValue));
    }

    /**
     * Retreives all organisations.
     */
    async getAll(): Promise<RESTResultListOrgDetails> {
        const response = await this.getAllRaw();
        return await response.value();
    }

    /**
     * Retreives a single organisations.
     */
    async getOrganisationRaw(requestParameters: GetOrganisationRequest): Promise<runtime.ApiResponse<RESTResultOrganisation>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultOrganisationFromJSON(jsonValue));
    }

    /**
     * Retreives a single organisations.
     */
    async getOrganisation(requestParameters: GetOrganisationRequest): Promise<RESTResultOrganisation> {
        const response = await this.getOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new organisations.
     */
    async postOrganisationRaw(requestParameters: PostOrganisationRequest): Promise<runtime.ApiResponse<RESTResultOrgDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/org`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgDetailsToJSON(requestParameters.orgDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultOrgDetailsFromJSON(jsonValue));
    }

    /**
     * Creates a new organisations.
     */
    async postOrganisation(requestParameters: PostOrganisationRequest): Promise<RESTResultOrgDetails> {
        const response = await this.postOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an organisations.
     */
    async putOrganisationRaw(requestParameters: PutOrganisationRequest): Promise<runtime.ApiResponse<RESTResultOrgDetails>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling putOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrgDetailsToJSON(requestParameters.orgDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultOrgDetailsFromJSON(jsonValue));
    }

    /**
     * Updates an organisations.
     */
    async putOrganisation(requestParameters: PutOrganisationRequest): Promise<RESTResultOrgDetails> {
        const response = await this.putOrganisationRaw(requestParameters);
        return await response.value();
    }

}
