import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box,Typography } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from 'assets/images/react.svg';
import useTheme from '@material-ui/core/styles/useTheme';
import { appImages } from 'helpers/images';
import { useTranslation } from 'react-i18next';

const PageHeaderLogo = props => {
  const { sidebarToggle, sidebarHover, session } = props;
  const theme = useTheme();
  const { t } = useTranslation('common');
  // console.log("page Header Logo;: theme ",theme);

  let version;
  let full_version = null;
  if (session != null && session.version != null) {
    version = <Typography style={{fontSize: '12px'}}>{t('labels.version')} {session.version}</Typography>

    if (session.build != null)
    {
      full_version = "\t" + session.build.version + " (" + session.build.number + ") " + session.build.branch + "\n\t" + session.build.time;
    }
  }

  return (
    <Fragment>
      <div
        style={{backgroundColor:theme.palette.common.black}}
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title={t('company') + " " + t('appname') + "\n" + full_version}>
          <Link to="/dashboard" className="header-logo-wrapper-link">
            {appImages.companyLogo}
          </Link>
          <Box className="header-logo-text">
            {t('appname')}
            {version}
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  session: state.session.sessionData,
});

export default connect(mapStateToProps)(PageHeaderLogo);
