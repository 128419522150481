export var fontFamilySansSerif = "tt-hoves-regular"
export var fontFamilyMonospace = "Times New Roman"
export var fontFamilyBase = "tt-hoves-regular"
export var primary = "#505FF6"
export var primaryLight = "#495368"
export var primaryDark = "#1B2FF4"
export var secondary = "#3d4977"
export var inheritDefault1 = "#fefefe"
export var inheritDefault2 = "#f8f9ff"
export var second = "#070919"
export var indigo = "#7420ff"
export var purple = "#793de6"
export var pink = "#fc26a4"
export var red = "#f83245"
export var orange = "#f4772e"
export var yellow = "#ffc926"
export var green = "#1bc943"
export var teal = "#18e1a5"
export var cyan = "#27dcf3"
export var primaryBlue = "#505FF6"
export var primaryGreen = "#5ACFC3"
export var secondaryBlack = "#1B222A"
export var secondaryNavy = "#32148A"
export var secondaryGrey = "#494E62"
export var secondaryWarmLightGrey = "#D3D2D3"
export var secondaryPaleBlue = "#CDEBFA"
export var contentMainBackground = "#FFFFFF"
export var contentDetailBackground = "#D2D3DB"
export var contentActionsBackground = "#F4F4F4"
export var cardHeaderBackground = "#E1E1E1"
export var cardFormBackground = "#F7F7F7"
export var popoverLightYellowBackgournd = "#FFFCEC"
export var superUser = "#9B00D1"
export var primaryBtnBackground = "#505FF6"
export var primaryBtnHover = "#1B2FF4"
export var secondaryBtnBackground = "#5ACFC3"
export var secondaryBtnHover = "#1CB7A7"
export var greyBtnBackground = "#E0E0E0"
export var greyBtnHover = "#BDBDBD"
export var btnDisabled = "rgba(0, 0, 0, 0.12)"
export var deleteBtnBackground = "#F44336"
export var deleteBtnHover = "#DC2A1D"
export var deleteBtnDisabled = "#FFC9C5 !important"
export var deleteBtnDisabledText = "#FAFAFA !important"
export var fabDisabled = "#B7B7B7"
export var fabDisabledText = "#E0E0E0 !important"
export var textPrimary = "rgba(0, 0, 0, 0.87)"
export var textDisabledGrey = "rgba(0, 0, 0, 0.26)"
export var tableHeaderBackground = "#EAEBF2"
export var tableRowBackgroundDefault = "#FFFFFF"
export var tableRowBackgroundOdd = "#F9F9FF"
export var tableDefaultText = "#494E62"
export var tableRowSelected = "#DFF4FF !important"
export var tableRowMouseOver = "#FBF4E6 !important"
export var tableRowDeviceNotApprovedBackground = "#FFECDA"
export var tableTopHeader = "#DFE0EC"
export var navbarText = "#737373"
export var sidebarTextSelectedAndHover = "#0893E1"
export var sidebarItemBackground = "transparent"
export var sidebarItemSelectedBackground = "rgba(0,0,0,0.08)"
export var sidebarItemHover = "rgba(0,0,0,0.04)"
export var secondaryBarItemBackground = "#ffffff"
export var warningBackground = "#FFF5EC"
export var warningText = "#FC8517"
export default {"fontFamilySansSerif":"tt-hoves-regular","fontFamilyMonospace":"Times New Roman","fontFamilyBase":"tt-hoves-regular","primary":"#505FF6","primaryLight":"#495368","primaryDark":"#1B2FF4","secondary":"#3d4977","inheritDefault1":"#fefefe","inheritDefault2":"#f8f9ff","second":"#070919","indigo":"#7420ff","purple":"#793de6","pink":"#fc26a4","red":"#f83245","orange":"#f4772e","yellow":"#ffc926","green":"#1bc943","teal":"#18e1a5","cyan":"#27dcf3","primaryBlue":"#505FF6","primaryGreen":"#5ACFC3","secondaryBlack":"#1B222A","secondaryNavy":"#32148A","secondaryGrey":"#494E62","secondaryWarmLightGrey":"#D3D2D3","secondaryPaleBlue":"#CDEBFA","contentMainBackground":"#FFFFFF","contentDetailBackground":"#D2D3DB","contentActionsBackground":"#F4F4F4","cardHeaderBackground":"#E1E1E1","cardFormBackground":"#F7F7F7","popoverLightYellowBackgournd":"#FFFCEC","superUser":"#9B00D1","primaryBtnBackground":"#505FF6","primaryBtnHover":"#1B2FF4","secondaryBtnBackground":"#5ACFC3","secondaryBtnHover":"#1CB7A7","greyBtnBackground":"#E0E0E0","greyBtnHover":"#BDBDBD","btnDisabled":"rgba(0, 0, 0, 0.12)","deleteBtnBackground":"#F44336","deleteBtnHover":"#DC2A1D","deleteBtnDisabled":"#FFC9C5 !important","deleteBtnDisabledText":"#FAFAFA !important","fabDisabled":"#B7B7B7","fabDisabledText":"#E0E0E0 !important","textPrimary":"rgba(0, 0, 0, 0.87)","textDisabledGrey":"rgba(0, 0, 0, 0.26)","tableHeaderBackground":"#EAEBF2","tableRowBackgroundDefault":"#FFFFFF","tableRowBackgroundOdd":"#F9F9FF","tableDefaultText":"#494E62","tableRowSelected":"#DFF4FF !important","tableRowMouseOver":"#FBF4E6 !important","tableRowDeviceNotApprovedBackground":"#FFECDA","tableTopHeader":"#DFE0EC","navbarText":"#737373","sidebarTextSelectedAndHover":"#0893E1","sidebarItemBackground":"transparent","sidebarItemSelectedBackground":"rgba(0,0,0,0.08)","sidebarItemHover":"rgba(0,0,0,0.04)","secondaryBarItemBackground":"#ffffff","warningBackground":"#FFF5EC","warningText":"#FC8517"}
