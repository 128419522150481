/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewGroupDetails
 */
export interface NewGroupDetails {
    /**
     * 
     * @type {string}
     * @memberof NewGroupDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewGroupDetails
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof NewGroupDetails
     */
    networkId?: number;
    /**
     * 
     * @type {string}
     * @memberof NewGroupDetails
     */
    meshType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewGroupDetails
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewGroupDetails
     */
    autoEnable?: boolean;
}

export function NewGroupDetailsFromJSON(json: any): NewGroupDetails {
    return NewGroupDetailsFromJSONTyped(json, false);
}

export function NewGroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewGroupDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'networkId': !exists(json, 'networkId') ? undefined : json['networkId'],
        'meshType': !exists(json, 'mesh_type') ? undefined : json['mesh_type'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'autoEnable': !exists(json, 'auto_enable') ? undefined : json['auto_enable'],
    };
}

export function NewGroupDetailsToJSON(value?: NewGroupDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'networkId': value.networkId,
        'mesh_type': value.meshType,
        'enabled': value.enabled,
        'auto_enable': value.autoEnable,
    };
}


