/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigDetails,
    ConfigDetailsFromJSON,
    ConfigDetailsFromJSONTyped,
    ConfigDetailsToJSON,
    GroupDetails,
    GroupDetailsFromJSON,
    GroupDetailsFromJSONTyped,
    GroupDetailsToJSON,
    NetworkDetails,
    NetworkDetailsFromJSON,
    NetworkDetailsFromJSONTyped,
    NetworkDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    type?: SearchResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    query?: string;
    /**
     * 
     * @type {Array<NetworkDetails>}
     * @memberof SearchResponse
     */
    networks?: Array<NetworkDetails>;
    /**
     * 
     * @type {Array<GroupDetails>}
     * @memberof SearchResponse
     */
    groups?: Array<GroupDetails>;
    /**
     * 
     * @type {Array<ConfigDetails>}
     * @memberof SearchResponse
     */
    configs?: Array<ConfigDetails>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SearchResponse
     */
    networkNames?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SearchResponse
     */
    groupNames?: { [key: string]: string; };
}

export function SearchResponseFromJSON(json: any): SearchResponse {
    return SearchResponseFromJSONTyped(json, false);
}

export function SearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'networks': !exists(json, 'networks') ? undefined : ((json['networks'] as Array<any>).map(NetworkDetailsFromJSON)),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupDetailsFromJSON)),
        'configs': !exists(json, 'configs') ? undefined : ((json['configs'] as Array<any>).map(ConfigDetailsFromJSON)),
        'networkNames': !exists(json, 'networkNames') ? undefined : json['networkNames'],
        'groupNames': !exists(json, 'groupNames') ? undefined : json['groupNames'],
    };
}

export function SearchResponseToJSON(value?: SearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'query': value.query,
        'networks': value.networks === undefined ? undefined : ((value.networks as Array<any>).map(NetworkDetailsToJSON)),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupDetailsToJSON)),
        'configs': value.configs === undefined ? undefined : ((value.configs as Array<any>).map(ConfigDetailsToJSON)),
        'networkNames': value.networkNames,
        'groupNames': value.groupNames,
    };
}

/**
* @export
* @enum {string}
*/
export enum SearchResponseTypeEnum {
    ALL = 'ALL',
    VIN = 'VIN',
    GROUP = 'GROUP',
    DEVICE = 'DEVICE'
}


