/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteDetails
 */
export interface InviteDetails {
    /**
     * 
     * @type {number}
     * @memberof InviteDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InviteDetails
     */
    invitation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDetails
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InviteDetails
     */
    autoEnabled?: boolean;
}

export function InviteDetailsFromJSON(json: any): InviteDetails {
    return InviteDetailsFromJSONTyped(json, false);
}

export function InviteDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'invitation': !exists(json, 'invitation') ? undefined : json['invitation'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'autoEnabled': !exists(json, 'auto_enabled') ? undefined : json['auto_enabled'],
    };
}

export function InviteDetailsToJSON(value?: InviteDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'invitation': value.invitation,
        'enabled': value.enabled,
        'auto_enabled': value.autoEnabled,
    };
}


