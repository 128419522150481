/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceLimit,
    DeviceLimitFromJSON,
    DeviceLimitFromJSONTyped,
    DeviceLimitToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrgDetails
 */
export interface OrgDetails {
    /**
     * 
     * @type {number}
     * @memberof OrgDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OrgDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDetails
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof OrgDetails
     */
    vinCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetails
     */
    groupCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrgDetails
     */
    deviceCount?: number;
    /**
     * 
     * @type {DeviceLimit}
     * @memberof OrgDetails
     */
    deviceLimit?: DeviceLimit;
    /**
     * 
     * @type {boolean}
     * @memberof OrgDetails
     */
    disabled?: boolean;
}

export function OrgDetailsFromJSON(json: any): OrgDetails {
    return OrgDetailsFromJSONTyped(json, false);
}

export function OrgDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'vinCount': !exists(json, 'vinCount') ? undefined : json['vinCount'],
        'groupCount': !exists(json, 'groupCount') ? undefined : json['groupCount'],
        'deviceCount': !exists(json, 'deviceCount') ? undefined : json['deviceCount'],
        'deviceLimit': !exists(json, 'deviceLimit') ? undefined : DeviceLimitFromJSON(json['deviceLimit']),
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function OrgDetailsToJSON(value?: OrgDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'vinCount': value.vinCount,
        'groupCount': value.groupCount,
        'deviceCount': value.deviceCount,
        'deviceLimit': DeviceLimitToJSON(value.deviceLimit),
        'disabled': value.disabled,
    };
}


