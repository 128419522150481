import React, { Fragment } from 'react';

import AppButton from 'components/AppButton';
import {
  Box,
  Tab,
  Tabs,
  Paper,
  FormGroup,
  withTheme
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import AppDialogAlert from 'components/AppDialogAlert';
import { isValidVin } from 'helpers/validate';
import NothingSelectedTitle from 'components/NothingSelectedTitle';
import SomethingSelectedTitle from 'components/SomethingSelectedTitle';
import SessionManager from 'session/SessionManager';

//
// const DeleteButton = withStyles((theme) =>({
//   containedPrimary:{
//     backgroundColor: theme.palette.common.deleteBtn,
//     '&:hover':{
//       backgroundColor: theme.palette.common.deleteHover
//     },
//   },
//   disabled:{
//     backgroundColor: theme.palette.common.deleteDisabled,
//     color:theme.palette.common.deleteDisabledText,
//   }
// }))(Button);


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

class VinDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {deleteOpen: false};

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.deleteVinConfrmClick = this.deleteVinConfrmClick.bind(this);
    this.saveConfirmClick = this.saveConfirmClick.bind(this);

    this.fieldChanged = this.fieldChanged.bind(this);
    this.canCancel = this.canCancel.bind(this);
    this.canSave = this.canSave.bind(this);

    this.allowedEdit = SessionManager.shared().userAllowedEdit('vin');
    this.allowedDelete = SessionManager.shared().userAllowedDelete('vin');
  }


  fieldPropsHandler(field, template){
    if(template === 'VINDetails') {
      if (this.allowedEdit) {
        if (field === 'orgName' || field === 'cidr' || field === "cipher") {
          return { InputProps: { disabled:true } };
        }
      }else{
        return { InputProps: { disabled:true } };
      }
    }
  }

  deleteVinConfrmClick(){
    this.setState({deleteOpen:false});

    if(this.props.selectedVin != null){
      this.props.deleteVin(this.props.selectedVin.id);
    }
  }

  saveConfirmClick(){
    this.setState({saveOpen:false});

    if(this.props.editingVin != null){
      this.props.updateVin(this.props.editingVin);
    }
  }

  fieldChanged(field,value){
    // console.log('fieldChanged '+field + ' '+value);
    this.props.detailEdit("vin",field,value);
  }

  canCancel(){
    if(this.props.selectedVin == null || this.props.isSaving){
      return false;
    }
    return (
      this.props.selectedVin.name !== this.props.editingVin.name ||
      this.props.selectedVin.description !== this.props.editingVin.description
    );
  }

  canSave(){
    if(this.props.selectedVin == null || this.props.isSaving){
      return false;
    }
    return (
      (this.props.selectedVin.name !== this.props.editingVin.name ||
      this.props.selectedVin.description !== this.props.editingVin.description) &&
      isValidVin(this.props.editingVin));
  }

  render() {

     // console.log("VinDetail:: ",this.props);

    const { t } = this.props;

    if (this.props.selectedVin == null){
      return (
          <NothingSelectedTitle>{t('noVinSelectedInfo')}</NothingSelectedTitle>
      );
    }

    if (this.props.isLoading(this.props)){
      return <Fragment/>
      //return <Loading/>
    }

    let content = [];
    let header = null;

    let canSave = this.canSave();
    let canCancel = this.canCancel();

    let cks = Object.keys(this.props.ciphers);
    let data = cks.map(key => {
      return { id: key, name: this.props.ciphers[key] }
    });

    let vinName = (this.props.editingVin==null)?null:this.props.editingVin.name;
    header = (
      <SomethingSelectedTitle>{t('vinDetailTitle', {name:vinName})}</SomethingSelectedTitle>
    );

    content = (
      <Fragment>
      <div style={{borderBottom: '1px solid grey'}}>
        <Tabs value={0}>
          <Tab label={t('tabVinDetails')} />
        </Tabs>
      </div>
      <TabPanel index={0} value={0}>

        <FormGroup>
        <DataDetails
          template={"VINDetails"}
          data={{data:data}}
          object={this.props.editingVin}
          extraPropsHandler={this.fieldPropsHandler}
          inputChangeHandler={this.allowedEdit? this.fieldChanged : null}
        />
        </FormGroup>

        {this.allowedEdit ?
          <Box className="d-flex justify-content-end" style={{marginTop:15}}>
            <AppButton
              buttonType={'grey'}
              style={{marginRight:10}}
              onClick={this.props.cancelDetailEdit}
              disabled={!canCancel}
            >
              {t('common:labels.cancel').toLocaleUpperCase()}
            </AppButton>

            <AppButton
              buttonType={"primary"}
              disabled={!canSave}
              onClick={this.saveConfirmClick}
            >
              {t('common:labels.save').toLocaleUpperCase()}
            </AppButton>
          </Box>
          : null
        }

        {this.allowedDelete ?
          <Box className="d-flex justify-content-center" style={{ marginTop: 60 }}>
            <AppButton
              buttonType={"delete"}
              startIcon={<DeleteIcon/>}
              disabled={this.props.selectedVin == null}
              onClick={() => {
                this.setState({ deleteOpen: true })
              }}
            >
              {t('common:uppercase', { text: t('buttonDeleteVin') })}
            </AppButton>
          </Box>
          : null
        }

      </TabPanel>
      </Fragment>
    );




    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
          {header}
        </Box>
        <Paper style={{padding:16}}>
          {content}
        </Paper>

        <AppDialogAlert
          open={this.state.deleteOpen}
          title={t("vinsgroups:deletevin.title")}
          message={t("vinsgroups:deletevin.message", {name:vinName})}
          confirmActionTitle={ t('common:uppercase', {text:t('common:labels.delete')}) }
          confirmHandler={this.deleteVinConfrmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({deleteOpen:false})}
          />


        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
      </Fragment>
    );

  }
}

export default withTranslation('vinsgroups')(withTheme(VinDetail));
