import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import DashboardSearch from 'pages/Dashboard/DashboardSearch';
import DashboardDetail from 'pages/Dashboard/DashboardDetail';
import ContentLayout from 'layouts/Content/ContentLayout';
import { snackAlertSuccess } from 'helpers/alerting';


const mapStateToProps = (state, ownProps) => {

  return {
    isLoading: state.session.isLoading,
    isSearchLoading: state.search.isLoading,
    isAuthenticated: state.session.isAuthenticated,
    isLicensed: state.session.isLicensed,
    searchResults: state.search.results,
    orgMap:state.session.orgMap,
    pendingApprovals: state.session.pendingApprovals,
    alerts : state.session.alerts,
    ciphers: state.session.ciphers,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    search: (type, keywords) => {
      console.log("Searching");
      SessionManager.shared().performSearch(type, keywords);
    },
    approveDevice: (device, t) => {
      console.log("Approving Device");
      //return SessionManager.shared().approveDevice(device.networkId, device.device.id);
      let vinId = device.networkId;
      let deviceId = device.device.id;

      SessionManager.shared().approveDevice(vinId,deviceId).then( result => {

        if (result != null && result.success === true){
          SessionManager.shared().loadVinDevices(vinId).then( result => {
            if (result != null && result.success === true){
              SessionManager.shared().loadDeviceDetail(deviceId);
              snackAlertSuccess("snack:deviceApproved", {name:device.device.name, vinName:device.networkName});

            }
          });
        }
      });

    }
  };
};

class DashboardView extends React.Component{

  constructor(props) {
    super(props);
  }

  render() {

    let main = React.cloneElement(<DashboardSearch/>, this.props);
    let detail = React.cloneElement(<DashboardDetail/>, this.props);

    let detailsSize = 4;
    if (this.props.pendingApprovals != null && this.props.pendingApprovals.length > 0)
    {
      detailsSize = 5;
    }
    if (this.props.alerts != null && this.props.alerts.length > 0)
    {
      detailsSize = 5;
    }

    return <ContentLayout main={main} detail={detail} detailsSize={detailsSize}/>;


  }

}



const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardView);
export default DashboardContainer;


