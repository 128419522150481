import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import ContentPageHeader from 'components/ContentPageHeader';
import { Link, Route } from 'react-router-dom';
import PageHeader from 'layouts/HeaderOnly/PageHeader';


export default function HomePage() {

  let crumbs = [""];
  return (

    <div>
    <PageHeader />
      <Grid container={true} direction="column" className='h-100' style={{paddingLeft:16, marginRight:16}}>
        <ContentPageHeader breadCrumbs={crumbs} newHandler={() => {}} withAdd={false}/>
        <Grid style={{width:'100%', textAlign:"center", fontSize:30, paddingTop:40}}>
          <p>Sorry, this page does not exist.</p>
          <p><Link to={'/dashboard'}>Click here to continue</Link></p>
        </Grid>
      </Grid>
    </div>



  )
}
