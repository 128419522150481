/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDetails,
    UserDetailsFromJSON,
    UserDetailsFromJSONTyped,
    UserDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultListUserDetails
 */
export interface RESTResultListUserDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultListUserDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultListUserDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<UserDetails>}
     * @memberof RESTResultListUserDetails
     */
    data?: Array<UserDetails>;
}

export function RESTResultListUserDetailsFromJSON(json: any): RESTResultListUserDetails {
    return RESTResultListUserDetailsFromJSONTyped(json, false);
}

export function RESTResultListUserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListUserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(UserDetailsFromJSON)),
    };
}

export function RESTResultListUserDetailsToJSON(value?: RESTResultListUserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(UserDetailsToJSON)),
    };
}


