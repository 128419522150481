import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { Graph } from "react-d3-graph";
import { GroupMeshType } from 'helpers/constants';
import ConnectedSVG from 'assets/netlinkz/node-connected.svg'
import Typography from '@material-ui/core/Typography';
import useWindowSize from 'utils/windowSize';


// graph payload (with minimalist structure)
const LocalButton = (props) => {

  let style = {fontSize:14, padding:3, cursor:'pointer', marginLeft:10, marginRight:10};
  if (props.selected)
  {
    style = {...style, border:"2pt solid blue", color:"blue"};
  }
  else
  {
    style = {...style, border:"1pt solid gray", color:"gray"};
  }
  return <span style={style} onClick={props.onClick}>{props.children}</span>
}

const myConfig = {
  border: "1pt solid gray",
  "automaticRearrangeAfterDropNode": false,
  "collapsible": false,
  "directed": false,
  "focusAnimationDuration": 0.75,
  "focusZoom": 1,
  "highlightDegree": 0,
  "highlightOpacity": 0.2,
  "linkHighlightBehavior": false,
  "maxZoom": 2,
  "minZoom": 0.5,
  "nodeHighlightBehavior": false,
  "panAndZoom": false,
  staticGraph: false,
  staticGraphWithDragAndDrop: true,
  d3: {
  //   alphaTarget: 0.05,
     gravity: -1000,
     linkLength: 300,
     linkStrength: 1,
  //   disableLinkForce: false
  },
  node: {
    size: 2000,
    highlightStrokeColor: "blue",
    strokeColor:"gray",
    color:"#FFF",
    fontSize:15,
    labelProperty:"name",
    strokeWidth:3
  },
  link: {
    highlightColor: "gray",
    type:"CURVE_SMOOTH",
    //type:"CURVE_FULL",
    strokeWidth:3

  },
};

const getGroupCount = (props) =>
{
  if (props.data == null || props.data.data == null) return 0;
  return props.data.data.length;
}

const getGroupByIndex = (props, index) =>
{
  let groups = props.data.data;

  let g = groups[index];

  return g;

}

const getGroupByName = (props, name) =>
{
  for (let i=0; i < getGroupCount(props); ++i)
  {
    let g = getGroupByIndex(props, i);
    if (g.name == name || g.id == name) return g;
  }

  return null;

}

const getNodesFormatOne = (props, size) =>
{
  let data = {nodes:[], links:[]};
  console.log("VINTopology", props);

  let hgap = 150;
  let vgap = 150;
  let base = 50;

  if (getGroupCount(props) > 5) {
    hgap = 100;
  }

  if (getGroupCount(props) > 8) {
    hgap = 75;
  }

  let row = -1;
  for (let i=0; i < getGroupCount(props); ++i)
  {
    let g = getGroupByIndex(props, i);

    if (i%3 == 2) ++row;
    if (i%3 == 0) ++row;
    let col = (i%3==0) ? 0 : ((i%3==1)) ? 2 : 1;

    console.log("Segment i = row, col", i, row, col);
    console.log("Segment i = row, col", i, row, col);
    console.log("Group = ", g);

    let node = {name:g.name + " (" + g.deviceCount +")", id:g.id, x:base + col*vgap, y:base + (row*hgap)};
    if (props.selected != null && props.selected.id == g.id)
    {
      node.strokeColor = "#505FF6";
      node.fontColor = "#505FF6";
      node.strokeWidth = 5;
      node.fontWeight = 900;
    }
    // let nodeIcon = "<svg><circle x={0} y={0} width={100} height={100}/></svg>"
    // node.svg = ConnectedSVG;
    // node.size = 500;
    data.nodes.push(node);
    //height = node.y+100;

    for (let j=0; j<g.connectionIds.length; ++j)
    {
      if (g.id < g.connectionIds[j])
      {
        data.links.push({source:g.id, target:g.connectionIds[j]});
      }
    }
  }

  return data;
}

const getNodesFormatTwo = (props, size) =>
{
  let data = {nodes:[], links:[]};

  let centerX = (size.width - 900)/2;
  let centerY = (size.height - 200)/2;
  let radius = Math.min(centerX, centerY) - 100;
  let offsetX = centerX;
  let offsetY = centerY;

  console.log("Sizing", size, radius, offsetX, offsetY);
  let groupCount = getGroupCount(props);

  for (let i=0; i < groupCount; ++i)
  {
    let g = getGroupByIndex(props, i);

    // work out the angle to show this guy at
    let ThreeSixty = 2 * Math.PI;
    let Ninety = ThreeSixty/4;
    let division = (ThreeSixty / groupCount);
    let angle = (i * division - Ninety) + (Ninety/6);
    // work out a position for this node
    let x = offsetX + Math.cos(angle) * radius;
    let y = offsetY + Math.sin(angle) * radius;

    let symbol = g.meshType === GroupMeshType.nonMeshed ? 'square' : 'circle';

    let node = {name:g.name + " [" + g.deviceCount +"]", id:g.id, x:x, y:y, symbolType: symbol};
    if (props.selected != null && props.selected.id == g.id)
    {
      node.strokeColor = "#505FF6";
      node.fontColor = "#505FF6";
      node.strokeWidth = 5;
      node.fontWeight = 900;
    }

    data.nodes.push(node);

    for (let j=0; j<g.connectionIds.length; ++j)
    {
      if (g.id < g.connectionIds[j])
      {
        data.links.push({source:g.id, target:g.connectionIds[j]});
      }
    }
  }

  return data;
}

const VINTopology = props => {

  const size = useWindowSize();
  const [isStatic, setIsStatic] = useState(true);
  const [formatIndex, setFormatIndex] = useState(2);
  const [smoothLines, setSmoothLines] = useState(false);

  let data = null;
  let height = 0;

  if (props.demo === true)
  {
    data = {
      nodes: [{ id: "Harry" }, { id: "Sally" }, { id: "Alice" }],
      links: [{ source: "Harry", target: "Sally" }, { source: "Harry", target: "Alice" }],
    };
    height = 600;
  }
  else if (formatIndex == 1)
  {
      data = getNodesFormatOne(props, size);
  }
  else if (formatIndex == 2)
  {
    data = getNodesFormatTwo(props, size);
  }


  console.log("Data", data);

  if (data.nodes.length == 0)
  {
    return <div>No Nodes</div>
  }


  myConfig.height = size.height - 200;//height;
  myConfig.width = size.width - 900;
  //myConfig.staticGraphWithDragAndDrop = isStatic;
  myConfig.staticGraph = isStatic;
  myConfig.link.type = (smoothLines)?"CURVE_SMOOTH" : null;

  return (
    <Box style={{padding:0, width:"100%", border:"0pt solid black"}} key={"format" + formatIndex + "-" + isStatic + "-" + smoothLines}>
      {/*<Typography variant={"h4"}>Topology Beta Test*/}
      {/*  <LocalButton selected={isStatic} onClick={() => setIsStatic(!isStatic)}>Static</LocalButton>*/}
      {/*  &nbsp;|&nbsp;*/}
      {/*  <LocalButton selected={formatIndex==1} onClick={() => setFormatIndex(1)}>Format 1</LocalButton>*/}
      {/*  <LocalButton selected={formatIndex==2} onClick={() => setFormatIndex(2)}>Format 2</LocalButton>*/}
      {/*  &nbsp;|&nbsp;*/}
      {/*  <LocalButton selected={smoothLines} onClick={() => setSmoothLines(!smoothLines)}>Curved Lines</LocalButton>*/}
      {/*</Typography>*/}
      <Graph
        id={"group-graph-id-" + formatIndex} // id is mandatory, if no id is defined rd3g will throw an error
        data={data}
        config={myConfig}
        onClickNode={(id, extra) => {
          console.log("on Click Node ", id, extra);
          props.rowClickHandler(getGroupByName(props, id));
        }}
        // onDoubleClickNode={onDoubleClickNode}
        // onRightClickNode={onRightClickNode}
        // onClickGraph={onClickGraph}
        // onClickLink={onClickLink}
        // onRightClickLink={onRightClickLink}
        // onMouseOverNode={onMouseOverNode}
        // onMouseOutNode={onMouseOutNode}
        // onMouseOverLink={onMouseOverLink}
        // onMouseOutLink={onMouseOutLink}
        // onNodePositionChange={onNodePositionChange}
      />
    </Box>
  );
}


VINTopology.propTypes = {
  data: PropTypes.object,
  sizeWithWindow: PropTypes.number,
  rowClickHandler: PropTypes.func,
  selected: PropTypes.object
};

export default VINTopology;
