/*

Fields:
field: the key for the value in the Object this template is displaying
label: the display name for the field data
type:
      - text is a text field
      - select is a drop down. Need to also include the dataMap. This has a 'key' to get the data to populate
        the dropdown out of the props data object. The itemKeys is a list of keys that relate to the item.
        The first key is used for the key and value prop of the menu item. The rest are for the content.
      - multiline-<NUMBER> is a multiline text filed. Optional NUMBER will go into rows prop of Texfield component.
        If you omit NUMBER then fields will grow as number of lines grows. Including NUMBER rows fixes number of rows displayed.
        Will scroll over that number.

 */




const templates = {
  "User": [
    {
      fields: [
        {field: "firstname", label: "labels.firstName", type: "text"},
        {field: "lastname", label: "labels.lastName", type: "text"},
        {field: "username", label: "labels.username", type: "text"},
        {field: "plainPassword", label: "labels.password", type: "password"},
        {field: "orgId", label: "labels.org", type: "select", dataMap:{key:'orgs',itemKeys:['id','name']}},
      ]
    }
  ],

  "VINTableSearch": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'data',
              cellStyleOverride: (user,field) => {
                if (field === 'cidr'){
                  return "mono"
                }else {
                  return null;
                }
              },
              columns:[
                {field:'vinIcon',label:'', type:'icon'},
                {field:'name',label:'vinsgroups:table.colVinName'},
                {field:'orgName',label:'vinsgroups:table.colOrg'},
                {field:'cidr',label:'vinsgroups:table.colCidr'},
                {field:'cipher',label:'vinsgroups:table.colCipher'},
                {field:'count',label:'vinsgroups:table.colCount'}
              ]
            }},
      ]
    }
  ],

  "VINTable": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'data',
              cellStyleOverride: (user,field) => {
                if (field === 'cidr'){
                  return "mono"
                }else {
                  return null;
                }
              },
              columns:[
                {field:'vinIcon',label:'', type:'icon'},
                {field:'name',label:'vinsgroups:table.colVinName'},
                {field:'orgName',label:'vinsgroups:table.colOrg'},
                {field:'cidr',label:'vinsgroups:table.colCidr'},
                {field:'cipherName',label:'vinsgroups:table.colCipher'},
                {field:'count',label:'vinsgroups:table.colCount'}
              ]
            }},
      ]
    }
  ],

  "OrgTable": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'orgs',
              columns:[
                {field:'orgIcon',label:'', type:'icon'},
                {field:'name',label:'table.columnOrg'},
                {field:'vinCount',label:'organisation:table.columnVin'},
                {field:'description',label:'organisation:table.columnDesc'},
                {field:'deviceCount',label:'organisation:table.columnDeviceCount'},
                {field: (org) => {
                  if (org.deviceLimit != null) {
                    if (org.deviceLimit.soft != null && org.deviceLimit.hard != null) {
                      return org.deviceLimit.soft + "/" + org.deviceLimit.hard
                    }else {
                      return "";
                    }
                  }else {
                    return "";
                  }
                  },label:'organisation:table.columnLimit'},
                {field: (org) => {
                    if (org.disabled){
                      return "crossIcon";
                    }else{
                      return "tickIcon";
                    }
                  },
                  label:'organisation:table.columnEnabled', type:'icon'}
              ]
            }},
      ]
    }
  ],

  "UserTable": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'users',
              cellStyleOverride: (user,field) => {
                if (user.admin === true){
                  return "superuser"
                }else {
                  return null;
                }
              },
              columns:[
                {field: (user) => {
                    if(user.admin){
                      return 'superUserIcon'
                    }else{
                      return 'userIcon'
                    }
                  }
                  ,label:'', type:'icon'},
                {field:'fullName',label:'users:table.columnName'},
                {field:'orgName',label:'users:table.columnOrg'},
                {field:'username',label:'users:table.columnUsername'},
                {field: (user) => {
                    if (user.disabled){
                      return "crossIcon";
                    }else{
                      return "tickIcon";
                    }
                  },
                  label:'users:table.columnEnabled', type:'icon'}
                ]
            }},
      ]
    }
  ],

  "VINTopology": [
    {
      fields: [
        {type: "Topology", dataMap:
            {
              key:'data',
              columns:[
                {field:'groupIcon',label:'',type:'icon'},
                {field:'name',label:'vinsgroups:table.colName'},
                {field:'meshType',label:'vinsgroups:table.colType', valueAsKey:true, namespace: 'vinsgroups'},
                {field:'deviceCount',label:'vinsgroups:table.colDevices'},
                {field:'linkedGroupCount',label:'vinsgroups:table.colLinkedGroups'}]
            }},
      ]
    }
  ],

  "VINGroups": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'data',
              columns:[
                {field:'groupIcon',label:'',type:'icon'},
                {field:'name',label:'vinsgroups:table.colName'},
                {field:'meshType',label:'vinsgroups:table.colType', valueAsKey:true, namespace: 'vinsgroups'},
                {field:'deviceCount',label:'vinsgroups:table.colDevices'},
                {field:'linkedGroupCount',label:'vinsgroups:table.colLinkedGroups'}]
            }},
      ]
    }
  ],


  "VINGroupsSearch": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'data',
              columns:[
                {field:'groupIcon',label:'',type:'icon'},
                {field:'name',label:'vinsgroups:table.colName'},
                {field:'vinName',label:'vinsgroups:table.colVinName'},
                {field:'meshType',label:'vinsgroups:table.colType', valueAsKey:true, namespace: 'vinsgroups'},
                {field:'deviceCount',label:'vinsgroups:table.colDevices'},
                {field:'linkCount',label:'vinsgroups:table.colLinkedGroups'},
                {field:'invite',label:'vinsgroups:table.colInvitation'}]
            }},
      ]
    }
  ],

  "VINDevices": [
      {
        fields: [
          {type: "DataTable", dataMap:
              {
                defaultSort:"none",
                key:'data',
                rowStyleOverride: (rowItem) => {
                    if (rowItem.enable !== true){
                      return "notapproved"
                    }else {
                      return null;
                    }
                  },
                cellStyleOverride: (user,field) => {
                  if (field === 'ip'){
                    return "mono"
                  }else {
                    return null;
                  }
                },
                columns:[
                  {field: (obj) => {
                    if (obj.online === true){
                      return 'deviceOnlineIcon';
                    }
                    return 'deviceIcon';
                    },label:'',type:'icon'},
                  {field:'device.name',label:'vindevice:table.colDeviceName'},
                  {field:'groupName',label:'vindevice:table.colDeviceGroup'},
                  {field:'ip',label:'vindevice:table.colDeviceIp'},
                  {field:'device.os', label:'vindevice:table.colDeviceOs'},
                  {field:'lastSeenInfo',label:'vindevice:table.colDeviceLastSeen',sortField:'lastSeen'}]
              }},
        ]
      }
    ],

  "VINDevicesSearch": [
    {
      fields: [
        {type: "DataTable", dataMap:
            {
              key:'data',
              rowStyleOverride: (rowItem) => {
                if (rowItem.enable !== true){
                  return "notapproved"
                }else {
                  return null;
                }
              },
              cellStyleOverride: (user,field) => {
                if (field === 'ip'){
                  return "mono"
                }else {
                  return null;
                }
              },
              columns:[
                {field:'deviceIcon',label:'',type:'icon'},
                {field:'device.name',label:'vindevice:table.colDeviceName'},
                {field:'vinName',label:'vindevice:table.colVinName'},
                {field:'groupName',label:'vindevice:table.colDeviceGroup'},
                {field:'ip',label:'vindevice:table.colDeviceIp'},
                {field:'device.os', label:'vindevice:table.colDeviceOs'},
                {field:'lastSeenInfo',label:'vindevice:table.colDeviceLastSeen'}]
            }},
      ]
    }
  ],

  "VINDetails": [
    {
      fields: [
        {field: "orgName", label: "vinsgroups:fieldOrg", placeholder: "", type: "text"},
        {field: "name", label: "vinsgroups:fieldVinName", placeholder: "", helper:"common:helper.max32Character", type: "text", validate:'textMax-32'},
        {field: "description", label: "common:labels.description", placeholder: "", type: "multiline-2"},
        {field: "cidr", label: "vinsgroups:fieldCidr", placeholder: "", type: "text"},
        {field: "cipher", label: "vinsgroups:fieldCipher", placeholder: "", type: "select", dataMap:{key:'data',itemKeys:['id','name']}},
      ]
    }
  ],

  "GroupDetails": [
    {
      fields: [
        {field: "vin", label: "vinsgroups:vin", placeholder: "", type: "text"},
        {field: "name", label: "common:labels.name", placeholder: "", helper:"common:helper.max32Character", type: "text", validate:'textMax-32'},
        {field: "meshType", label: "vinsgroups:fieldType", placeholder: "", type: "select", dataMap:{key:'data',itemKeys:['id','name']}},
        {field: "description", label: "common:labels.description", placeholder: "", type: "multiline-2"},
        {field: "invite", label: "vinsgroups:fieldInvite", placeholder: "", type: "invite"},
        {field: "enrolment", label: "vinsgroups:groupEnrolmentTitle", type: "radio", dataMap:{key:'options',itemKeys:['id','name']}},
      ]
    }
  ],

  "CreateVin": [
    {
      fields: [
        {field: "orgId", label: "vinsgroups:fieldOrg", placeholder: "", type: "select", dataMap:{key:'orgs',itemKeys:['id','name']}, required:true, validate:"text"},
        {field: "name", label: "vinsgroups:fieldVinName", placeholder: "vinsgroups:placeholder.fieldVinName", helper:"common:helper.max32Character", type: "text", required:true, validate:'textMax-32'},
        {field: "description", label: "common:labels.description", placeholder: "vinsgroups:placeholder.fieldVinDescription", type: "multiline-3"},
        {field: "cidr", label: "vinsgroups:fieldCidr", placeholder: "vinsgroups:placeholder.fieldCidr", type: "text", required:true, validate:'cidr'},
        {field: "cipher", label: "vinsgroups:fieldCipher", placeholder: "vinsgroups:placeholder.fieldCipher", type: "select", dataMap:{key:'ciphers',itemKeys:['id','name']}, required:true, validate:"text"},
      ]
    }
  ],

  "CreateVinWithOrg": [
    {
      fields: [
        {field: "orgName", label: "vinsgroups:fieldOrg", placeholder: "", type: "text"},
        {field: "name", label: "vinsgroups:fieldVinName", placeholder: "vinsgroups:placeholder.fieldVinName", helper:"common:helper.max32Character", type: "text", required:true, validate:'textMax-32'},
        {field: "description", label: "common:labels.description", placeholder: "vinsgroups:placeholder.fieldVinDescription", type: "multiline-3"},
        {field: "cidr", label: "vinsgroups:fieldCidr", placeholder: "vinsgroups:placeholder.fieldCidr", type: "text", required:true, validate:'cidr'},
        {field: "cipher", label: "vinsgroups:fieldCipher", placeholder: "vinsgroups:placeholder.fieldCipher", type: "select", dataMap:{key:'ciphers',itemKeys:['id','name']}, required:true, validate:"text"},
      ]
    }
  ],

  "CreateGroup": [
    {
      fields: [
        {field: "name", label: "vinsgroups:fieldVinGroupName", placeholder: "vinsgroups:placeholder.fieldVinGroupName", helper:"common:helper.max32Character", type: "text",required:true, validate:"textMax-32"},
        {field: "meshType", label: "vinsgroups:fieldType", placeholder: "", type: "select", dataMap:{key:'data',itemKeys:['id','name']}, required:true, validate:"text"},
        {field: "description", label: "common:labels.description", placeholder: "vinsgroups:placeholder.fieldVinGroupDescription", type: "multiline-3"},
        {field: "enrolment", label: "vinsgroups:groupEnrolmentTitle", type: "radio", dataMap:{key:'options',itemKeys:['id','name']}},
      ]
    }
  ],


  "OrgDetail": [
    {
      fields: [
        {field: "name", label: "common:labels.name", placeholder: "", type: "text", validate:"text"},
        {field: "description", label: "common:labels.description", placeholder: "", type: "multiline-3"},
        {field: "deviceLimit", label: "organisation:deviceLimit", placeholder: "", type: "limit"},
        {field:"enabled",label:"organisation:orgEnabled", type:"switch"},
      ]
    }
  ],

  "DeviceDetail" : [
    {
      fields:[
        {field:"device.name",label:"vindevice:fieldDeviceName", type:"text"},
        {field:"ip",label:"vindevice:fieldDeviceIp", type:"text"},
        {field:"device.os",label:"vindevice:fieldDeviceOs", type:"text"},
        // {field:"groupName",label:"vindevice:fieldDeviceGroup", type:"text"},
        {field: "groupId", label: "vindevice:fieldDeviceGroup", placeholder: "vindevice:fieldDeviceGroup", type: "select", dataMap:{key:'data',itemKeys:['id','name']}},
      ]
    }
  ],

  "CreateUser" : [
    {
      fields:[
        {field:"firstname",label:"users:fullname", type:"text", placeholder:"users:placeholder.fullname", required:true, validate:"text"},
        {field:"username",label:"users:labels.email", type:"text", placeholder:"users:placeholder.email", required:true, validate:"email"},
        {field:"plainPassword",label:"common:labels.password", type:"password", placeholder:"users:placeholder.password", helper:"users:helper.fieldPassword", required:true, validate:"password"},
        {field:"confirmPassword",label:"users:labels.confirmPassword", type:"password", placeholder:"users:placeholder.passwordConfirm", required:true, validate:"password"},
        {field:"enabled",label:"users:labels.accountEnabled", type:"switch"},
        {field:"admin",label:"users:labels.superUser", type:"switch"},
        {field: "orgId", label: "users:org", placeholder: "users:placeholder.org", type: "select", dataMap:{key:'orgs',itemKeys:['id','name']}, validate:"text"},
      ]
    }
  ],

  "Initialise" : [
    {
      fields:[
        {field:"username",label:"initialise:labels.username", type:"text", placeholder:"initialise:placeholder.username", required:true, validate:"email"},
        {field:"plainPassword",label:"initialise:labels.password", type:"password", placeholder:"initialise:placeholder.password", helper:"users:helper.fieldPassword", required:true, validate:"password"},
        {field:"confirmPassword",label:"initialise:labels.confirmPassword", type:"password", placeholder:"initialise:placeholder.passwordConfirm", required:true, validate:"password"},
        {field:"organisation",label:"initialise:labels.organisation", type:"text", placeholder:"initialise:placeholder.organisation", required:true, validate:"textMax-386"},
        {field:"licenseKey",label:"initialise:labels.licenseKey", type:"text", placeholder:"initialise:placeholder.licenseKey", required:true, validate:"text"},
      ]
    }
  ],

  "UserDetail" : [
    {
      fields:[
        {field:"username",label:"common:labels.username", type:"text", placeholder:"users:placeholder.username", validate:"textMax-386"},
        {field:"firstname",label:"users:fullname", type:"text", placeholder:"users:placeholder.fullname", validate:"text"},
        {field:"plainPassword",label:"users:labels.changePassword", type:"password", placeholder:"users:placeholder.passwordChange", validate:"password"},
        {field:"enabled",label:"users:labels.accountEnabled", type:"switch"},
        {field:"admin",label:"users:labels.superUser", type:"switch"},
        //{field: "orgId", label: "users:org", placeholder: "users:placeholder.org", type: "select", dataMap:{key:'orgs',itemKeys:['id','name']}},
        {field:"orgName",label:"users:org", type:"text"},
      ]
    }
  ],

  "CreateOrganisation" : [
    {
      fields: [
        {field: "name", label: "common:labels.name", placeholder: "",required: true, type: "text", validate:"text"},
        {field: "description", label: "common:labels.description", placeholder: "", type: "multiline-3"},
        {field: "deviceLimit", label: "organisation:deviceLimit", placeholder: "", type: "limit"},
      ]
    }
  ],

  "LicenseDetail" :[
    {
      fields: [
        {field: "licenseKey", label: "license:licenceKey", type:"text"},
        {field: "deviceCount", label: "license:deviceCount", type:"text"},
        {field: "notBeforeInfo", label: "license:notBefore", type:"text"},
        {field: "notAfterInfo", label: "license:notAfter", type:"text"},
      ]
    }
  ],
  "LicenseActivate" :[
    {
      fields: [
        {field: "licenceKey", label: "license:licenceKey",placeholder:"license:placeholderKey", type:"text"},
      ]
    }
  ],

};

module.exports = {

  getTemplate(type){
    return templates[type];
  },

  getValue(object, field){
    // console.log('getValue object ',object);
    // console.log('getValue field ',field);

    // console.log('split ',field.split('.'));

    if(object == null){
      return '';
    }

    let parts = field.split('.');

    if (parts.length === 1){
      return object[field];
    }else if (parts.length === 2){

      let child = object[parts[0]];

      if (child == null){
        return '';
      }

      return child[parts[1]];

    }

    return field;
  }

};
