import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  TableHead
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import useTheme from '@material-ui/core/styles/useTheme';
import { PulseLoader } from 'react-spinners';

const GroupLinkTable = props => {

  // console.log('GroupLinkTable:: ',props);

  let {groups,connections,linkType,linkAction, group} = props;
  const { t } = useTranslation('vinsgroups');
  const theme = useTheme();

  let rows = [];
  let header = "";
  if (connections == null){
    connections = [];
  }

  if (linkType === 'link'){
    //show groups we can link to
    let grpName = t('group');
    if(group.name != null){
      grpName = group.name;
    }
    header = t('addGroupLinkTitle', {name: grpName});

    let unlinked = [];

    unlinked = groups.filter( item => {
      return (!connections.includes(item.id) && item.id !== group.id);
    });

    if (unlinked.length > 0){
      rows = unlinked.map( (item, i) => {
        let b = <AppButton onClick={() => linkAction(item,props.linkType)} disabled={props.loading} buttonType={'primary'} size={"small"}>{t('common:uppercase', {text: t('groupLink')})}</AppButton>;
        if (props.loading && item.id === props.loadingId){
          b = <PulseLoader color={theme.palette.common.blue} size={5} loading={true}/>
        }
        return (
          <TableRow key={i}>
            <TableCell>{item.name}</TableCell>
            <TableCell style={{textAlign:'right'}}>{b}</TableCell>
          </TableRow>
        );
      });
    }else{
      rows.push(
        <TableRow key="00">
          <TableCell>{t('groupNoLinks')}</TableCell>
        </TableRow>
      );
    }

  }else{
    //show groups we're already linked to
    let grpName = t('group');
    if(group.name != null){
      grpName = group.name;
    }
    header = t('currentGroupTitle', {name:grpName});

    let linked = [];

    linked = groups.filter( item => {
      return connections.includes(item.id);
    });

    if (linked.length > 0){
      rows = linked.map( (item,i) => {
        let b = <AppButton onClick={() => linkAction(item,props.linkType)} size={"small"}>{t('common:uppercase', {text:t('groupUnlink')})}</AppButton>;
        return (
          <TableRow key={i}>
            <TableCell>{item.name}</TableCell>
            <TableCell style={{textAlign:'right'}}>{b}</TableCell>
          </TableRow>
        );
      });
    }else{
      rows.push(
        <TableRow key="00">
          <TableCell>{t('groupNoLinks')}</TableCell>
        </TableRow>
      );
    }
  }

  return (
    <Fragment>
      <div style={{marginLeft:-24,marginRight:-24}}>
      <Card elevation={0}>
          <Table size={"small"}>
            <TableHead>
              <TableRow key={"header"}>
                <TableCell colSpan={100} style={{backgroundColor: theme.palette.table.tableTopHeader, color:theme.palette.table.text}}>{header}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
      </Card>
      </div>
    </Fragment>
  )

};

GroupLinkTable.propTypes = {
  groups: PropTypes.array.isRequired,
  connections: PropTypes.arrayOf(PropTypes.number).isRequired,
  linkType: PropTypes.oneOf(["link","unlink"]).isRequired,
  linkAction: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingId: PropTypes.number,
  group: PropTypes.shape({
    id:PropTypes.number.isRequired,
    name:PropTypes.string,
  }).isRequired
};

GroupLinkTable.defaultProps = {
  loading: false
};

export default GroupLinkTable;
