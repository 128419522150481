import React, { Fragment } from 'react';
import { Box, Paper, withTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {ReactComponent as GroupLogo} from 'assets/netlinkz/group.svg';
import {ReactComponent as VinLogo} from 'assets/netlinkz/vin.svg';
import {ReactComponent as DeviceLogo} from 'assets/netlinkz/device.svg';
import {ReactComponent as LandingLogo} from 'assets/netlinkz/LandingLogo.svg';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VINTopology from 'pages/VIN Pages/VINTopology';

const useStyles = makeStyles((theme) => ({

  paper: {backgroundColor:'#EFF6FA', borderRadius:8, padding:16, textAlign:"center", marginTop:20, maxWidth:700, margin:"auto"},
  title: {color:"#505FF6", fontSize:28, fontWeight:300},
  subtitle: {fontWeight:300},
  helpCard: {margin:"auto", height:220, width:190, textAlign:"center", boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2)"},
  cardTitle: {
    fontSize:14,
    fontWeight:600,
    paddingTop:6,
    "& path, rect":
    {
      stroke:"#FFF",
      strokeOpacity:1.0
    }

  },
  cardText: {
    fontSize:12,
    fontWeight:300,
    lineHeight:'16px',
  },
  helpText: {
    fontSize:13,
    color:"#737373",
    '& a' : {
      color: '#5383ff'
    }
  }
}));

function HelpPanel(props)
{
  const { t } = useTranslation('dashboard');
  const classes = useStyles();

  let icon = null;
  if (props.name == 'vins') icon = <VinLogo className='dashboard-icon'/>;
  else if (props.name == 'segments') icon = <GroupLogo className='dashboard-icon' style={{width:20, height:20}}/>;
  else icon = <DeviceLogo className='dashboard-icon' style={{width:20, height:20}}/>;

  let stringPrefix = "dashboard:landing." + props.name ;
  return (
      <Card variant={"outlined"} className={classes.helpCard}>
            <Box style={{backgroundColor:"#505FF6", height:34, color:"#FFF", whiteSpace:"nowrap"}}>
                <Typography className={classes.cardTitle}>
                  {icon} {t(stringPrefix+".title")}
                </Typography>
            </Box>
            <Box style={{padding:13}}>
              <div className={classes.cardText}>
                <div dangerouslySetInnerHTML={{__html: t(stringPrefix+".text", {interpolation: {escapeValue: false}})}}/></div>
              </Box>
         </Card>
  );
}



export default function LandingPage(props)
{
  const { t } = useTranslation('dashboard');
  const classes = useStyles();

  return (
    <Box style={{width:'100%', textAlign:"center"}}>
    <Paper elevation={0} className={classes.paper}>
      <Grid container direction="column" spacing={2} style={{backgroundColor:'#EFF6FA'}}>
        <Grid item >
          <Typography variant={"h2"} className={classes.title}>{t('dashboard:landing.title')}</Typography>
          <Typography variant={"h5"} className={classes.subtitle}>{t('dashboard:landing.subtitle')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} direction="row"  justify="center" style={{backgroundColor:'#EFF6FA'}}>
            <Grid item xs={4}><HelpPanel name={"vins"}/></Grid>
            <Grid item xs={4}><HelpPanel name={"segments"}/></Grid>
            <Grid item xs={4}><HelpPanel name={"devices"}/></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{marginTop:24}}>
          <LandingLogo/>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.helpText}>
            <div dangerouslySetInnerHTML={{__html: t('dashboard:landing.help', {interpolation: {escapeValue: false}})}}/>
          </div>
        </Grid>

      </Grid>
    </Paper>
    </Box>
  )
}
