import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import ContentLayout from 'layouts/Content/ContentLayout';
import CreateUser from 'pages/Users/CreateUser';
import { snackAlertSuccess} from 'helpers/alerting';
import { stringNotNullUndefOrEmpty, validateFieldElement } from 'helpers/validate';


const mapStateToProps = (state, ownProps) => {

  let orgs = [];
  if (state.session.sessionData != null){
    orgs = state.session.sessionData.organisations;
  }

  return {

    mainLoading: state.users.isLoading,
    detailLoading: state.users.detailLoading,
    organisations: orgs,
    orgMap:state.session.orgMap,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    saveNewClick: (info) => {


      info.disabled = !info.enabled;
      info.email = info.username;
      delete info["enabled"];
      delete info["confirmPassword"];
      if (info.admin === true){
        delete info["orgId"];
      }

      // console.log("SaveNewUser click - ",info);

      let req = {userDetails: info};

      SessionManager.shared().createUser(req).then(result => {
        if (result != null && result.success === true){
          snackAlertSuccess("snack:usercreated");
          SessionManager.shared().loadUsers().then(result => {
            if (result != null && result.success === true){
              SessionManager.shared().goto('/users');
              dispatch({type:"CREATE_USER_FINISHED"});
            }
          });
        }
      });
    },

    validatePasswordMatch: (password, confirm) => {
      let passwordOK = !stringNotNullUndefOrEmpty(validateFieldElement("password",password));
      if (passwordOK){
        let validation;
        if (password !== confirm){
          validation = "validation:fieldPasswordDontMatch";
        }
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "CreateUser", field:"confirmPassword", validation:validation}})
      }
    },

  };
};

class CreateUserView extends React.Component{

  componentDidMount() {
    //this.props.loadAll();
  }

  render() {

    // console.log('CreateUserView:: ',{...this.props});

    let detail = React.cloneElement(<CreateUser/>, this.props);

    return <ContentLayout detail={detail} />;
  }

}



const CreateUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateUserView);
export default CreateUserContainer;


