/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildDetails,
    BuildDetailsFromJSON,
    BuildDetailsFromJSONTyped,
    BuildDetailsToJSON,
    ConfigDetails,
    ConfigDetailsFromJSON,
    ConfigDetailsFromJSONTyped,
    ConfigDetailsToJSON,
    SessionOrg,
    SessionOrgFromJSON,
    SessionOrgFromJSONTyped,
    SessionOrgToJSON,
} from './';

/**
 * 
 * @export
 * @interface SessionDetails
 */
export interface SessionDetails {
    /**
     * 
     * @type {number}
     * @memberof SessionDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDetails
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionDetails
     */
    lastname?: string;
    /**
     * 
     * @type {Array<SessionOrg>}
     * @memberof SessionDetails
     */
    organisations?: Array<SessionOrg>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof SessionDetails
     */
    ciphers?: { [key: string]: string; };
    /**
     * 
     * @type {Array<ConfigDetails>}
     * @memberof SessionDetails
     */
    pendingApprovals?: Array<ConfigDetails>;
    /**
     * 
     * @type {BuildDetails}
     * @memberof SessionDetails
     */
    build?: BuildDetails;
    /**
     * 
     * @type {string}
     * @memberof SessionDetails
     */
    version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDetails
     */
    licensed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDetails
     */
    admin?: boolean;
}

export function SessionDetailsFromJSON(json: any): SessionDetails {
    return SessionDetailsFromJSONTyped(json, false);
}

export function SessionDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'organisations': !exists(json, 'organisations') ? undefined : ((json['organisations'] as Array<any>).map(SessionOrgFromJSON)),
        'ciphers': !exists(json, 'ciphers') ? undefined : json['ciphers'],
        'pendingApprovals': !exists(json, 'pendingApprovals') ? undefined : ((json['pendingApprovals'] as Array<any>).map(ConfigDetailsFromJSON)),
        'build': !exists(json, 'build') ? undefined : BuildDetailsFromJSON(json['build']),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'licensed': !exists(json, 'licensed') ? undefined : json['licensed'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
    };
}

export function SessionDetailsToJSON(value?: SessionDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'email': value.email,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'organisations': value.organisations === undefined ? undefined : ((value.organisations as Array<any>).map(SessionOrgToJSON)),
        'ciphers': value.ciphers,
        'pendingApprovals': value.pendingApprovals === undefined ? undefined : ((value.pendingApprovals as Array<any>).map(ConfigDetailsToJSON)),
        'build': BuildDetailsToJSON(value.build),
        'version': value.version,
        'licensed': value.licensed,
        'admin': value.admin,
    };
}


