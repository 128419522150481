const makeOrganisationMap = (sessionData) => {
  if(sessionData == null){
    return null;
  }
  if (sessionData.organisations != null){
    let orgList = sessionData.organisations;
    let map = {};
    for (let i in orgList){
      let org = orgList[i];
      map[org.id] = org;
    }
    return map;
  }
  return null;
};

const collateAlerts = (sessionData) => {

  if (sessionData == null){
    return null;
  }

  let alerts = [];
  if (sessionData.licensed === false){
    //"licenseExpireAlertTitle": "Your VIN Admin License has expired.",
    //   "licenseExpireAlertInfo": "Enter a new Licence Key",
    alerts.push({
      type:"system",
      title:"dashboard:licenseExpireAlertTitle",
      info:"dashboard:licenseExpireAlertInfo",
      link:'/licences'
    })
  }
  return alerts;
};


export default function session(
  state = {
  isLoading: false,
  isAuthenticated: false,
  sessionData: null,
  isLicensed:null,
  ciphers:null,
  orgMap:null,
  error:null,
  pendingApprovals:null,
  alerts:null,
  access: {
      delete: [],
      add:[],
      edit:[]
    },
  },
  action) {
    switch (action.type) {


      case 'SET_INITIALISE_STATUS':
        console.log("=========== Setting Status : ", action);
        return {...state, isInitialised:(action.value=="complete"), initialiseError:action.error, initialiseStatus:action.value, isLoading: false}

      case 'SET_INITIALISE_ON':
        return {...state, isInitialised:true, isLoading: false}

      case 'SET_INITIALISE_OFF':
        return {...state, isInitialised:false, isLoading: false}

      case 'AUTH_START':
        return {
          isAuthenticated: false,
          isLoading: true,
          sessionData: null,
          error: null,
        };

      case 'AUTH_SUCCESS': {

        let session = action.data;
        let access = {
          delete: [],
          add:[],
          edit:[]
        };

        if (session != null) {
          if (session.admin === true) {
            access = {
              delete: ["vin", "user", "org", "group","device"],
              add: ["vin", "user", "org", "group","device"],
              edit: ["vin", "user", "org", "group","device"]
            };
          } else {
            access = {
              delete: ["group","device"],
              add: ["group"],
              edit: ["group"]
            };
          }
        }

        return {
          ...state,
          isLoading: false,
          isAuthenticated: true,
          sessionData: action.data,
          ciphers: action.data.ciphers,
          isLicensed: action.data.licensed,
          orgMap: makeOrganisationMap(action.data),
          error: null,
          pendingApprovals: action.data.pendingApprovals == null ? [] : action.data.pendingApprovals,
          alerts: collateAlerts(action.data),
          access: access,
        };
      }
      case 'AUTH_NO_SUCCESS':
        return {
          ...state,
          isLoading: false,
          isAuthenticated: false,
          sessionData:  null,
          error: action.data,
        };

      case 'AUTH_ERROR':
        return {
          isLoading: false,
          isAuthenticated: false,
          sessionData:  null,
        };


      case 'AUTH_LOGOUT':
        return {
          isAuthenticated: false,
          isLoading: false,
          sessionData: null,
          error: null,
        };

      case 'CHECK_SESSION_START':
        return {...state, isLoading: true, error: null};

      case 'CHECK_SESSION_SUCCESS': {

        let session = action.data;
        let access = {
          delete: [],
          add:[],
          edit:[]
        };

        if (session != null) {
          if (session.admin === true) {
            access = {
              delete: ["vin", "user", "org", "group","device"],
              add: ["vin", "user", "org", "group","device"],
              edit: ["vin", "user", "org", "group","device"]
            };
          } else {
            access = {
              delete: ["group","device"],
              add: ["group"],
              edit: ["group"]
            };
          }
        }

        return {
          ...state,
          isLoading: false,
          isAuthenticated: true,
          isLicensed: action.data.licensed,
          sessionData: action.data,
          ciphers: action.data.ciphers,
          orgMap: makeOrganisationMap(action.data),
          pendingApprovals: action.data.pendingApprovals == null ? [] : action.data.pendingApprovals,
          alerts: collateAlerts(action.data),
          access: access
        };
      }

      case 'CHECK_SESSION_NO_SUCCESS':
        return {
          isAuthenticated: false,
          isLoading: false,
          sessionData: null,
        };

      // We'll update the approved device list to signal this one is approving
      case 'APPROVE_DEVICE_START':
      {
        if (state.pendingApprovals == null) return state;

        let pendingApprovals = state.pendingApprovals.slice();
        for (let i = 0; i < pendingApprovals.length; ++i) {
          let d = pendingApprovals[i];
          if (d.networkId === action.vinId && d.device.id === action.deviceId) {
            d.isApproving = true;
          }
        }
        return {
          ...state,
          pendingApprovals: pendingApprovals
        };
      }
      default:
        return state;
    }

}
