import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import { Box, FormGroup, Paper, Tab, Tabs, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withTranslation} from 'react-i18next';
import AppButton from 'components/AppButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DataDetails from 'components/DataDetails';
import { stringNotNullUndefOrEmpty, validateFieldElement } from 'helpers/validate';
import AppDialogAlert from 'components/AppDialogAlert';
import NothingSelectedTitle from 'components/NothingSelectedTitle';
import SomethingSelectedTitle from 'components/SomethingSelectedTitle';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class ManageUsersDetail extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      deleteOpen:false,
    };

    this.canCancel = this.canCancel.bind(this);
    this.canSave = this.canSave.bind(this);

    this.deleteConfirmClick = this.deleteConfirmClick.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.inputBlur = this.inputBlur.bind(this);
  }

  canCancel(){
    if(this.props.selectedUser == null){
      return false;
    }
    return (
      this.props.selectedUser.username !== this.props.editingUser.username ||
      this.props.selectedUser.firstname !== this.props.editingUser.firstname ||
      this.props.selectedUser.plainPassword !== this.props.editingUser.plainPassword ||
      this.props.selectedUser.disabled !== this.props.editingUser.disabled ||
      this.props.selectedUser.admin !== this.props.editingUser.admin ||
      this.props.selectedUser.orgId !== this.props.editingUser.orgId
    );
  }

  canSave(){
    if(this.props.selectedUser == null){
      return false;
    }

    let changed = this.canCancel();

    if (changed) {
      let usernameOK = stringNotNullUndefOrEmpty(this.props.editingUser.username);
      let nameOK = stringNotNullUndefOrEmpty(this.props.editingUser.firstname);
      let passwordOK;
      if (this.props.editingUser.plainPassword == null || this.props.editingUser.plainPassword.length === 0){
        passwordOK = true;
      }else{
        passwordOK = !stringNotNullUndefOrEmpty(validateFieldElement("password",this.props.editingUser.plainPassword));
      }

      let orgOK;
      if (this.props.editingUser.admin){
        orgOK = true;
      }else{
        orgOK = this.props.editingUser.orgId != null;
      }

      return usernameOK && nameOK && passwordOK && orgOK;
    }

    return false;
  }

  fieldPropsHandler(field, template){
    if (field === 'username' || field === 'orgName'){
      return {InputProps:{ disabled:true}}
    }

    return null;
  }

  deleteConfirmClick(){
    this.setState({deleteOpen:false});

    if(this.props.selectedUser != null){
      this.props.deleteUser(this.props.selectedUser.id, this.props.selectedUser.username);
    }
  }

  saveClick(){
    if(this.props.selectedUser != null) {
      this.props.updateUser(this.props.editingUser);
    }
  }

  fieldChanged(field,value, template, event){
    // console.log('fieldChanged '+field + ' '+value);

    if (field === "enabled" || field === "admin"){
      value = event.target.checked
      if (field === "enabled"){
        field = 'disabled';
        value = !value;
      }
    }

    this.props.detailEdit(field,value);
  }

  selectChange(field, value,template){
    // console.log(template+ ' selectChange '+ field + " | v - "+value );
    this.props.detailEdit(field,value);
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    if (field === 'plainPassword'){
      this.props.validateChangePassword(value);
    }
  }

  render() {
    // console.log("ManageUsers Detail:: ",this.props);

    const { t } = this.props;

    if (this.props.detailLoading){
      return <Loading/>;
    }

    if (this.props.selectedUser == null) {
      return (
        <NothingSelectedTitle>{t('noUserSelectionInfo')}</NothingSelectedTitle>
      );
    }

    let header;
    let obj = {...this.props.editingUser};
    obj.enabled = !obj.disabled;

    if (obj.orgId != null){
      obj.orgName = this.props.orgMap[obj.orgId].name;
    }else{
      obj.orgName = '';
    }

    let userName = (this.props.editingUser==null)?null:this.props.editingUser.firstname;
    header = (
      <SomethingSelectedTitle>{t('userDetailTitle', {name:userName})}</SomethingSelectedTitle>
    );


    let canCancel = this.canCancel();
    let canSave = this.canSave();

    let content = (
      <Fragment>
        <div style={{borderBottom: '1px solid grey'}}>
          <Tabs value={0}>
            <Tab label={t('tabUserDetails')} />
          </Tabs>
        </div>
        <TabPanel index={0} value={0}>

          <FormGroup>
            <DataDetails
              template={"UserDetail"}
              object={obj}
              inputChangeHandler={this.fieldChanged}
              blurHandler={this.inputBlur}
              selectChangeHandler={this.selectChange}
              extraPropsHandler={this.fieldPropsHandler}
              data={{orgs:this.props.organisations}}
              />
          </FormGroup>

          <Box className="d-flex justify-content-end" style={{marginTop:15}}>
            <AppButton
              buttonType={'grey'}
              style={{marginRight:10}}
              onClick={this.props.cancelDetailEdit}
              disabled={!canCancel}
            >
              {t('common:labels.cancel').toLocaleUpperCase()}
            </AppButton>

            <AppButton
              buttonType={"primary"}
              disabled={!canSave}
              onClick={this.saveClick}
            >
              {t('common:labels.save').toLocaleUpperCase()}
            </AppButton>

          </Box>

          <Box className="d-flex justify-content-center" style={{marginTop:60}}>
            <AppButton
              buttonType={"delete"}
              startIcon={<DeleteIcon />}
              disabled={this.props.selectedUser == null || this.props.selectedUser.id == this.props.userId}
              onClick={() => {this.setState({deleteOpen:true})}}
            >
              { t('common:uppercase', {text: t('buttonDeleteUser')})}
            </AppButton>
          </Box>

        </TabPanel>
      </Fragment>
    );

    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
          {header}
        </Box>
        <Paper style={{padding:16}}>
          {content}
        </Paper>

        <AppDialogAlert
          open={this.state.deleteOpen}
          title={t("users:deleteuser.title")}
          message={t("users:deleteuser.message", {name:this.props.selectedUser.username})}
          confirmActionTitle={ t('common:uppercase', {text:t('common:labels.delete')}) }
          confirmHandler={this.deleteConfirmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({deleteOpen:false})}
        />

        {/*<pre>*/}
        {/*  {JSON.stringify(this.props.selectedUser,null,2)}*/}
        {/*</pre>*/}
      </Fragment>
    );
  }


}
export default withTranslation(['users','common'])(withTheme(ManageUsersDetail));
