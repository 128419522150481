import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const mainStyle = theme => ({
  main: {
    backgroundColor: theme.palette.common.mainContentBg,
  },
  detail:{
    backgroundColor: theme.palette.common.detailContentBg,
  },
  actions:{
    backgroundColor: theme.palette.common.actionsContentBg,
  }
});

class ContentLayout extends React.Component{

  constructor(props) {
    super(props);

  }

  render() {
     // console.log("Content Layout:: ",this.props);
    const {classes} = this.props;

    let detailsSize = this.props.detailsSize;
    if (detailsSize == null) detailsSize = 3;

    return (
      <Fragment>
        {/*<Hidden mdDown>*/}
        <Grid className="h-100" spacing={0} container={true}>
          <Grid className={this.props.nav == null ? "d-none" : classes.actions} item xs={2} style={{maxWidth:192,minWidth:192}}>
            {this.props.nav}
          </Grid>

          <Grid className={this.props.main == null ? "d-none" : classes.main} item xs style={this.props.mainStyle}>
            {this.props.main}
          </Grid>

          {this.props.main == null ?
            <Grid className={this.props.detail == null ? "d-none" : classes.detail} item xs style={{paddingLeft:16,paddingRight:16}}>
              {this.props.detail}
            </Grid>
            :
            <Grid className={this.props.detail == null ? "d-none" : classes.detail} item xs={detailsSize}
                  style={{ paddingLeft: 16, paddingRight: 16 }}>
              {this.props.detail}
            </Grid>
          }

        </Grid>
        {/*</Hidden>*/}

        {/*<Hidden lgUp>*/}
        {/*  <Grid className="h-100" spacing={0} container={true} direction="column">*/}
        {/*    <Grid className={this.props.nav == null ? "d-none" : "border-1 bg-asteroid text-white"} item xs={1} >*/}
        {/*      {this.props.nav}*/}
        {/*      --optional--*/}
        {/*    </Grid>*/}

        {/*    <Grid className="border-1 bg-amy-crisp" item xs style={{paddingLeft:25,paddingRight:25}}>*/}
        {/*      {this.props.main}*/}
        {/*    </Grid>*/}

        {/*    <Grid className={this.props.detail == null ? "d-none" : "border-1 bg-arielle-smile"} style={{maxWidth:'100%'}} xs={4} item>*/}
        {/*      {this.props.detail}*/}
        {/*    </Grid>*/}

        {/*  </Grid>*/}
        {/*</Hidden>*/}
      </Fragment>

    )




    // return (
    //   <div className="d-flex flex-row h-100" style={{backgroundColor: 'pink'}}>
    //     <div className={this.props.nav == null ? "d-none" : ""}>
    //       layout sidebar
    //     </div>
    //     <div style={{border: '1px dashed green'}}>
    //       main
    //       {this.props.main}
    //     </div>
    //     <div style={{border: '1px dashed orange'}}>
    //       detail
    //       {this.props.detail}
    //     </div>
    //   </div>
    // )
  }


}

ContentLayout.propTypes = {
  main: PropTypes.node,
  mainStyle: PropTypes.object,
  detail: PropTypes.node,
  nav: PropTypes.node,
  detailsSize: PropTypes.number
};

ContentLayout.defaultProps = {
  mainStyle: {marginLeft:16,paddingRight:16},
};

export default withStyles(mainStyle)(ContentLayout);
