import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import withTheme from '@material-ui/core/styles/withTheme';
import MuiAlert from '@material-ui/lab/Alert';
import { withTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';

class LoginPage extends React.Component{

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.state = {username:"",password:""};

    this.userChanged = this.userChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);

  }

  login(event){
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      this.props.doLogin(this.state.username, this.state.password);
    }
    event.preventDefault();
  }

  userChanged(event) {
    this.setState({username: event.target.value});
  }

  passwordChanged(event) {
    this.setState({password: event.target.value});
  }

  render() {

    // console.log("LoginPage::render ",this.props);
    const { t, theme } = this.props;

    if (this.props.isAuthenticated === true){
      return <Redirect to='/dashboard'/>;
    }

    return (
      <Fragment>
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content py-5" >
            <Container maxWidth="lg" className="d-flex justify-content-center">
              <Card className="m-0 w-50 p-0 border-0">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{height:78, backgroundColor:theme.palette.common.cardHeaderBg}}>
                  <Typography variant="h3" style={{color:theme.palette.common.black}}>{t('auth:loginTitle')}</Typography>
                  <Typography style={{color:theme.palette.common.grey}}>{t('auth:logininfo')}</Typography>

                </div>
                <CardContent className="p-3">

                  {this.props.error != null ?
                    <MuiAlert className="mb-4" severity="error">
                      <div className="d-flex align-items-center align-content-center">
                        <span>
                          <strong className="d-block">{t('common:labels.error')}</strong>
                          {t(this.props.error)}
                        </span>
                      </div>
                    </MuiAlert>
                    : null}

                  <form className="px-5 text-center">
                    <div className="mb-3" style={{marginTop:25}}>
                      <FormControl className="w-50" style={{marginBottom:5}}>
                        <TextField
                          size={theme.shape.textFieldSize}
                          fullWidth={true}
                          variant={"outlined"}
                          label={t('common:labels.email')}
                          value={this.state.username}
                          placeholder={t('auth:placeholderUsername')}
                          InputLabelProps={{ shrink: true }}

                          onChange={this.userChanged}
                        />
                      </FormControl>
                    </div>
                    <div className="mb-3">
                      <FormControl className="w-50">

                        <TextField
                          type="password"
                          size={theme.shape.textFieldSize}
                          fullWidth={true}
                          variant={"outlined"}
                          label={t('common:labels.password')}
                          value={this.state.password}
                          placeholder={t('auth:placeholderPassword')}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.passwordChanged}
                        />
                      </FormControl>
                    </div>
                    <div className="text-center" style={{marginTop:25, marginBottom:25}}>
                      <AppButton
                        type={'submit'}
                        buttonType={"primary"}
                        onSubmit={this.login}
                        onClick={this.login}
                        className="my-2">
                        {t('common:labels.login').toLocaleUpperCase()}
                      </AppButton>
                    </div>
                  </form>
                </CardContent>
              </Card>
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }





}
export default withTheme(withTranslation()(LoginPage));
