/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NewGroupDetails,
    NewGroupDetailsFromJSON,
    NewGroupDetailsToJSON,
    RESTResultGroupDetails,
    RESTResultGroupDetailsFromJSON,
    RESTResultGroupDetailsToJSON,
    RESTResultInteger,
    RESTResultIntegerFromJSON,
    RESTResultIntegerToJSON,
    RESTResultNetworkDetailsWithDevices,
    RESTResultNetworkDetailsWithDevicesFromJSON,
    RESTResultNetworkDetailsWithDevicesToJSON,
} from '../models';

export interface CreateGroupRequest {
    vinId: number;
    newGroupDetails?: NewGroupDetails;
}

export interface DeleteGroupRequest {
    vinId: number;
    groupId: number;
}

export interface GetNetworkGroupDevicesRequest {
    vinId: number;
    groupId: number;
}

export interface RegenerateInviteRequest {
    vinId: number;
    groupId: number;
}

export interface UpdateGroupRequest {
    vinId: number;
    groupId: number;
    newGroupDetails?: NewGroupDetails;
}

/**
 * 
 */
export class NetworkGroupApi extends runtime.BaseAPI {

    /**
     * Creates a new network device group.
     */
    async createGroupRaw(requestParameters: CreateGroupRequest): Promise<runtime.ApiResponse<RESTResultGroupDetails>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling createGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/{vin_id}/group`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewGroupDetailsToJSON(requestParameters.newGroupDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultGroupDetailsFromJSON(jsonValue));
    }

    /**
     * Creates a new network device group.
     */
    async createGroup(requestParameters: CreateGroupRequest): Promise<RESTResultGroupDetails> {
        const response = await this.createGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a network
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest): Promise<runtime.ApiResponse<RESTResultInteger>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling deleteGroup.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling deleteGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/group/{group_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultIntegerFromJSON(jsonValue));
    }

    /**
     * Deletes a network
     */
    async deleteGroup(requestParameters: DeleteGroupRequest): Promise<RESTResultInteger> {
        const response = await this.deleteGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all the config for devices connected to this network group
     */
    async getNetworkGroupDevicesRaw(requestParameters: GetNetworkGroupDevicesRequest): Promise<runtime.ApiResponse<RESTResultNetworkDetailsWithDevices>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling getNetworkGroupDevices.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getNetworkGroupDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/group/{group_id}/device`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNetworkDetailsWithDevicesFromJSON(jsonValue));
    }

    /**
     * Retreives all the config for devices connected to this network group
     */
    async getNetworkGroupDevices(requestParameters: GetNetworkGroupDevicesRequest): Promise<RESTResultNetworkDetailsWithDevices> {
        const response = await this.getNetworkGroupDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a network group.
     */
    async regenerateInviteRaw(requestParameters: RegenerateInviteRequest): Promise<runtime.ApiResponse<RESTResultGroupDetails>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling regenerateInvite.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling regenerateInvite.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/group/{group_id}/regenerate`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultGroupDetailsFromJSON(jsonValue));
    }

    /**
     * Update a network group.
     */
    async regenerateInvite(requestParameters: RegenerateInviteRequest): Promise<RESTResultGroupDetails> {
        const response = await this.regenerateInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a network group.
     */
    async updateGroupRaw(requestParameters: UpdateGroupRequest): Promise<runtime.ApiResponse<RESTResultGroupDetails>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling updateGroup.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling updateGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/{vin_id}/group/{group_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewGroupDetailsToJSON(requestParameters.newGroupDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultGroupDetailsFromJSON(jsonValue));
    }

    /**
     * Update a network group.
     */
    async updateGroup(requestParameters: UpdateGroupRequest): Promise<RESTResultGroupDetails> {
        const response = await this.updateGroupRaw(requestParameters);
        return await response.value();
    }

}
