import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tab,
  Tabs,
  Popover,
  Paper, withTheme, FormGroup, useTheme
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';

import { isValidVin, isValidGroup } from 'helpers/validate';
import { GroupMeshType } from 'helpers/constants';
import SessionManager from 'session/SessionManager';

//
// const DeleteButton = withStyles((theme) =>({
//   containedPrimary:{
//     backgroundColor: theme.palette.common.deleteBtn,
//     '&:hover':{
//       backgroundColor: theme.palette.common.deleteHover
//     },
//   },
//   disabled:{
//     backgroundColor: theme.palette.common.deleteDisabled,
//     color:theme.palette.common.deleteDisabledText,
//   }
// }))(Button);

const CIDRHelper = props => {

  // console.log("CIDRHelper ",props);

  const theme = useTheme();

  const {show, cidrList, anchor} = props;

  let ips = [];
  if (anchor != null && cidrList != null && cidrList.length !== 0){
    // console.log('anchor valu ', anchor.value);
    let val = anchor.value;

    ips = cidrList.map( (item, i) => {

      let start = item.substring(0,val.length);
      let rest = item.substring(val.length, item.length);

      // return <Typography style={{fontFamily:'Lucida Console',fontSize:'16px'}} key={i}>{start}<span style={{color:'red'}}>{rest}</span></Typography>;
      return <Typography style={{fontFamily:'Monospace', fontSize:'16px'}} component={"div"} key={i}>{start}<span style={{color:'red'}}>{rest}</span></Typography>;

    });
  }


  return(

    <Popover
      open={show}
      anchorEl={props.anchor}
      anchorOrigin={{
        vertical:'center',
        horizontal:'right'
      }}
      transformOrigin={{
        vertical:'center',
        horizontal:'left'
      }}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      style={{marginLeft:15}}
    >

      <Card style={{backgroundColor: theme.palette.common.popoverBg}}>
        <CardContent>
          <Typography variant={'h5'} style={{marginBottom:8}}>Unavailable CIDRs/IPs</Typography>
          {ips}
        </CardContent>
      </Card>

    </Popover>

  )

}

CIDRHelper.propTypes = {
  show: PropTypes.bool.isRequired,
  cidrList: PropTypes.arrayOf(PropTypes.string),
  anchor: PropTypes.any,
};

CIDRHelper.defaultProps = {
  show: false,
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CreateVin extends React.Component{
  constructor(props) {
    super(props);

    let orgName = null;
    if(this.props.orgId != null){
      orgName = this.props.orgMap[this.props.orgId].name;
    }

    // console.log('create vin props; ',props);

    this.cidrs = [];

    //Hardcoding the cipher in here until such time
    //the sessionData state has a cipher marked as default.
    this.state = {
      vin:{
        orgId:this.props.orgId,
        orgName: orgName,
        cipher:'AES128_CBC',

      },
      group:{
        enrolment:'auto',
      },
      similar:[],
      showIPHelp:false,
      ipAnchor: null,
    };

    this.saveClick = this.saveClick.bind(this);

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.inputChanged = this.inputChanged.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.inputBlur = this.inputBlur.bind(this);

    this.checkSimilarCIDR = this.checkSimilarCIDR.bind(this);

    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
  }


  saveClick(){
    console.log('save click ',this.state);
    //console.log(JSON.stringify(this.state,null,2));
    let vin = this.state.vin;
    let group = this.state.group;

    if (vin.orgId != null &&
      vin.name != null &&
      vin.cidr != null &&
      vin.cipher != null &&
      group.name != null &&
      group.meshType != null)
    {
      this.props.saveNewVinAndGroup(this.state);
    }

  }


  fieldPropsHandler(field, template){
    if(template === 'CreateVinWithOrg'){
      if (field === 'orgName'){
        return {InputProps:{readOnly:true}};
      }
    }
  }

  inputChanged(field, value,template, event){
    // console.log(template+ ' inputChanged '+ field + " | v - "+value );

    if (template === "CreateVin" || template === "CreateVinWithOrg"){
      let updateVin = this.state.vin;
      if(field === 'cidr'){

        if(value.length > 0) {
          let last = value.substring(value.length - 1);
          let lazyRegex = ["0","1","2","3","4","5","6","7","8","9",".","/"];
          if( lazyRegex.includes(last)){
            updateVin[field] = value;
            this.setState({vin:updateVin});
            this.checkSimilarCIDR(value,event);
          }
        }else{
          updateVin[field] = value;
          this.setState({vin:updateVin});
        }

        // console.log('is '+value+' cidr - '+isCidr(value));

      }else{
        updateVin[field] = value;
        this.setState({vin:updateVin});
      }
    }

    if (template === "CreateGroup"){
      let updateGroup = this.state.group;
      updateGroup[field] = value;
      this.setState({group:updateGroup});
    }
  }

  selectChange(field, value,template){
    // console.log(template+ ' selectChange '+ field + " | v - "+value );
    if (template === "CreateVin" || template === "CreateVinWithOrg"){
      let updateVin = this.state.vin;
      updateVin[field] = value;
      this.setState({vin:updateVin});
    }

    if (template === "CreateGroup"){
      let updateGroup = this.state.group;
      updateGroup[field] = value;
      this.setState({group:updateGroup});
    }
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    if(field === 'cidr'){
      let duped = false;
      for (const i in this.props.allVins){
        let vin = this.props.allVins[i];
        if (vin.cidr === value){
          duped = true;
          break;
        }
      }
      if (duped) {
        let vinTemplate = "CreateVinWithOrg";
        if (this.props.orgId == null){
          vinTemplate = "CreateVin"
        }
        this.props.cidrInUse(vinTemplate);
      }
      this.setState({showIPHelp:false});
    }
  }

  ipSort( ipAddressArray )
  {
    return ipAddressArray.sort( function( a, b )
    {
      a = a.split( '.' );
      b = b.split( '.' );
      for( var i = 0; i < a.length; i++ )
      {
        if( ( a[i] = parseInt( a[i] ) ) < ( b[i] = parseInt( b[i] ) ) )
          return -1;
        else if( a[i] > b[i] )
          return 1;
      }
      return 0;
    } );
  }

  checkSimilarCIDR(input, event){
    // console.log(event);
    if (this.cidrs.length === 0){
      if(this.props.allVins != null) {
        this.cidrs = this.props.allVins.map(vin => {
          return vin.cidr;
        })
      }
    }
    if (input.length > 2) {
      let similar = this.cidrs.filter( ip => ip.startsWith(input));
      similar = this.ipSort(similar);
      this.setState({similar:similar, showIPHelp:similar.length > 0, ipAnchor: event.target});
    }else{
      this.setState({similar:[], showIPHelp:false});
    }
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];
    let orgId = this.props.orgId;
    if(orgId == null){
      orgId = 'all';
    }
    let orgName = t('sidebarnav:allVins');
    if(orgId != 'all') {
      let org = this.props.orgMap[orgId];
      orgName = org.name + " " + t('vin_plural');
    }

    let link = '/vins/org/'+orgId;
    crumbs.push({name: orgName, link:'/vins/org/'+orgId, action: this.crumbClicked.bind(this,link)});
    crumbs.push({name: t('createVinTitle')});


    return crumbs;
  }

  crumbClicked(crumb){
    // console.log('crumb click ',crumb);
    SessionManager.shared().goto(crumb);
  }

  render() {

    // console.log("CreateVin:: ",this.props);
    // console.log("CreateVin:: state ",this.state);

    const { t, theme } = this.props;

    if (this.props.detailLoading || this.props.mainLoading){
      return <Loading/>
    }

    let debug = "";
    let content = [];
    let header = null;

    header = <Typography>Create VIN and Segment</Typography>;

    let cks = Object.keys(this.props.ciphers);
    let cipherData = cks.map(key => {return  {id:key, name:this.props.ciphers[key]}});
    let meshData = [{id:GroupMeshType.meshed,name:t('mesh')}, {id:GroupMeshType.nonMeshed,name:t('nonmesh')}];

    let enrolmentData = [{id:'auto',name:t('groupEnrolmentOptionAuto')},{id:'manual',name:t('groupEnrolmentOptionManual')}, {id:'off',name:t('groupEnrolmentOptionNotAllowed')}]

    let vinTemplate = "CreateVinWithOrg";
    if (this.props.orgId == null){
      vinTemplate = "CreateVin"
    }
    let validVin = isValidVin(this.state.vin) && this.state.similar.length === 0;
    let validGroup = isValidGroup(this.state.group);

    content = (
      <Fragment>
      <div style={{borderBottom: '1px solid grey'}}>
        <Tabs value={0} variant="scrollable">
          <Tab label={t('tabNewVin').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
        </Tabs>
      </div>
      <TabPanel index={0} value={0}>
        <Grid spacing={2} container={true}>
          <Grid item xs>
            <Card elevation={0} style={{backgroundColor:theme.palette.common.cardFormBg, height: '100%'}}>
              <CardContent>
                <Box style={{marginBottom:15}}>
                  <Typography variant="subtitle1" >{t('enterDetailsTitle')}</Typography>
                </Box>
              <FormGroup>
                <DataDetails
                  template={vinTemplate}
                  object={this.state.vin}
                  data={{ciphers:cipherData,orgs:this.props.organisations}}
                  inputChangeHandler={this.inputChanged}
                  selectChangeHandler={this.selectChange}
                  extraPropsHandler={this.fieldPropsHandler}
                  blurHandler={this.inputBlur}
                />
              </FormGroup>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs>
            <Card elevation={0} style={{backgroundColor:theme.palette.common.cardFormBg, height: '100%'}}>
              <CardContent>
                <Box style={{marginBottom:15}}>
                  <Typography variant="subtitle1" >{t('createFirstGroupTitle')}</Typography>
                </Box>
                <FormGroup>
                  <DataDetails
                    template={"CreateGroup"}
                    object={this.state.group}
                    data={{data:meshData,options:enrolmentData}}
                    inputChangeHandler={this.inputChanged}
                    selectChangeHandler={this.selectChange}
                    blurHandler={this.inputBlur}
                  />
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
        <Box className="d-flex justify-content-end">
          <AppButton buttonType="grey" style={{marginRight:8}} onClick={() => this.props.history.goBack()}>
            {t('common:labels.cancel').toLocaleUpperCase()}
          </AppButton>
          <AppButton
            buttonType="primary"
            onClick={this.saveClick}
            disabled={(!validVin || !validGroup)}
          >
            {t('common:labels.save').toLocaleUpperCase()}
          </AppButton>
        </Box>
      </Fragment>
    );

    let crumbs = this.getBreadCrumbs(t);

    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} withAdd={false} />
        <Grid spacing={0} container={true} style={{backgroundColor:this.props.theme.palette.common.detailContentBg}}>
          <Grid item xs={8} >
            <Paper style={{padding:16}}>
              {content}
            </Paper>
          </Grid>
        </Grid>
        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
        <CIDRHelper
          show={this.state.showIPHelp}
          anchor={this.state.ipAnchor}
          cidrList={this.state.similar}
        />
      </Fragment>
    );

  }
}
export default withTranslation(['vinsgroups','sidebarnav','common'])(withTheme(CreateVin));
