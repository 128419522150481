import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup,
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';

import { stringNotNullUndefOrEmpty, isValidOrganisation } from 'helpers/validate';
import SessionManager from 'session/SessionManager';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CreateOrganisation extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      org :{}
    };

    this.saveClick = this.saveClick.bind(this);

    // this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.inputChanged = this.inputChanged.bind(this);
    this.inputBlur = this.inputBlur.bind(this);

    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
  }


  saveClick(){
    // console.log('save click ',this.state);
    this.props.saveNewClick(this.state.org);
  }


  // fieldPropsHandler(field, template){
  //
  // }

  inputChanged(field, value){
    // console.log(template+ ' inputChanged '+ field + " | v - "+value);

    let updateOrg = {...this.state.org};


    if (field.startsWith("deviceLimit")){
      let parts = field.split('.');
      let lim = updateOrg.deviceLimit;
      if (value.length > 0) {
        let num = parseInt(value);
        if (!isNaN(num)) {
          if (lim != null) {
            lim[parts[1]] = num
          } else {
            lim = {};
            lim[parts[1]] = num
          }
          updateOrg.deviceLimit = lim;
        }
      }else{
        if (lim != null) {
          lim[parts[1]] = null
        } else {
          lim = {};
          lim[parts[1]] = null
        }
        updateOrg.deviceLimit = lim;
      }
    }else{
      updateOrg[field] = value;
    }

    this.setState({org:updateOrg});
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    // console.log('this.props.editingOrg; ',this.props.editingOrg);
    if (field.startsWith("deviceLimit")){

      let lim = this.state.org.deviceLimit;
      this.props.validateDeviceLimit(lim);
    }
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];

    let link = '/users';
    crumbs.push({name: t('sidebarnav:orgs'), link:link, action: this.crumbClicked.bind(this,link)});
    crumbs.push({name: t('addNewOrgTitle')});


    return crumbs;
  }

  crumbClicked(crumb){
    // console.log('crumb click ',crumb);
    SessionManager.shared().goto(crumb);
  }

  render() {

    // console.log("CreateVin:: ",this.props);
    // console.log("CreateVin:: state ",this.state);

    const { t, theme } = this.props;

    if (this.props.detailLoading || this.props.mainLoading){
      return <Loading/>
    }

    let debug = "";
    let validOrg = isValidOrganisation(this.state.org);

    let obj = {...this.state.org};
    if (obj.deviceLimit != null){
      obj.deviceLimit = {...this.state.org.deviceLimit};
    }else{
      obj.deviceLimit = null;
    }

    let content = (
      <Fragment>
        <div style={{borderBottom: '1px solid grey'}}>
          <Tabs value={0} variant="scrollable">
            <Tab label={t('tabbNewOrgTitle').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
          </Tabs>
        </div>
        <TabPanel index={0} value={0}>
          <Grid spacing={2} container={true}>
            <Grid item xs>
              <Card elevation={0} style={{height: '100%'}}>
                <CardContent>
                  <Box style={{marginBottom:15}}>

                  </Box>
                  <FormGroup>
                    <DataDetails
                      template={"CreateOrganisation"}
                      object={obj}
                      inputChangeHandler={this.inputChanged}
                      blurHandler={this.inputBlur}
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <Box className="d-flex justify-content-end">
          <AppButton buttonType="grey" style={{marginRight:8}} onClick={() => this.props.history.goBack()}>
            { t('common:uppercase', {text:t('common:labels.cancel')}) }
          </AppButton>
          <AppButton
            buttonType="primary"
            onClick={this.saveClick}
            disabled={!validOrg}
          >
            { t('common:uppercase', {text:t('common:labels.save')}) }
          </AppButton>
        </Box>
      </Fragment>
    );

    let crumbs = this.getBreadCrumbs(t);

    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} withAdd={false} />
        <Grid spacing={0} container={true} style={{backgroundColor:this.props.theme.palette.common.detailContentBg}}>
          <Grid item xs={4} >
            <Paper style={{padding:16}}>
              {content}
            </Paper>
          </Grid>
        </Grid>
        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
      </Fragment>
    );

  }
}
export default withTranslation(['organisation','common'])(withTheme(CreateOrganisation));
