import commonEn from 'strings/en/common';
import authEn from 'strings/en/auth';
import sidebarnavEN from 'strings/en/sidebarnav';
import orgEn from 'strings/en/organisation';
import usersEn from 'strings/en/users';
import vinsgroupsEn from 'strings/en/vinsgroups';
import vinDeviceEn from 'strings/en/vindevice';
import dashboardEn from 'strings/en/dashboard';
import validationEn from 'strings/en/validationMessages';
import initialiseEn from 'strings/en/initialise';
import snackbarEn from 'strings/en/snackbar';
import licenseEn from 'strings/en/license';

import commonZh from 'strings/zh/common';
import authZh from 'strings/zh/auth';
import sidebarnavZh from 'strings/zh/sidebarnav';
import orgZh from 'strings/zh/organisation';
import usersZh from 'strings/zh/users';
import vinsgroupsZh from 'strings/zh/vinsgroups';
import vinDeviceZh from 'strings/zh/vindevice';
import dashboardZh from 'strings/zh/dashboard';
import validationZh from 'strings/zh/validationMessages';
import initialiseZh from 'strings/zh/initialise';
import snackbarZh from 'strings/zh/snackbar';
import licenseZh from 'strings/zh/license';


export default {
  en: {
    common: commonEn,
    auth: authEn,
    sidebarnav: sidebarnavEN,
    organisation: orgEn,
    users: usersEn,
    vinsgroups: vinsgroupsEn,
    vindevice: vinDeviceEn,
    validation: validationEn,
    dashboard: dashboardEn,
    initialise: initialiseEn,
    snack: snackbarEn,
    license: licenseEn

  },
  zh: {
    common: commonZh,
    auth: authZh,
    sidebarnav: sidebarnavZh,
    organisation: orgZh,
    users: usersZh,
    vinsgroups: vinsgroupsZh,
    vindevice: vinDeviceZh,
    validation: validationZh,
    dashboard: dashboardZh,
    initialise: initialiseZh,
    snack: snackbarZh,
    license: licenseZh
  }
};
