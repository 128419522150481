export default function users(
  state = {
    isLoading: false,
    detailLoading: false,
    list: null,
    selected: null,
    editingUser:null,
    error:null,
    paramsReplacementId: null
  },
  action) {
  switch (action.type) {

    case 'AUTH_LOGOUT':
      return {
        isLoading: false,
        detailLoading: false,
        list: null,
        selected: null,
        editingUser:null,
        error:null,
      };

    case "LOAD_USERS_START":
    {
      return {
        ...state,
        isLoading: true,
        list: null,
        selected: null,
        error: null
      };
    }

    case "LOAD_USERS_FINISHED":
    {
      let data = action.data;
      // console.log('LOAD_USERS_FINISHED ',data);
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        list:data
      };
    }

    case "LOAD_SELECTED_USER_START":
    {
      return {
        ...state,
        detailLoading: true,
      };
    }

    case "LOAD_SELECTED_USER_FINISHED":
    {
      let edit = {...action.data};
      return {
        ...state,
        selected: action.data,
        editingUser: edit,
        detailLoading: false
      };
    }

    case "SELECT_USER_ID":
    {
      return {
        ...state,
        paramsReplacementId: action.selectId,
      }
    }

    case "SELECT_USER":
    {
      let edit = {...action.data};
      return {
        ...state,
        selected: action.data,
        editingUser: edit,
        detailLoading: false,
        isLoading: false,
      }
    }

    case "CREATE_USER_START":
    {
      return {
        ...state,
        isLoading: true,
        detailLoading: true,
      }
    }

    case "CREATE_USER_FINISHED":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
      }
    }

    case "USER_DETAIL_FIELD_CHANGE":
    {
      // console.log('USER_DETAIL_FIELD_CHANGE ',action);
      let data = action.data;
      let edit = Object.assign({},state.editingUser);
      edit[data.field] = data.value;

      return {
        ...state,
        editingUser: edit,
      }

    }

    case "USER_CANCEL_EDIT":
    {
      let edit = Object.assign({},state.selected);
      return {
        ...state,
        editingUser: edit,
      }
    }

    case "USER_DATA_LOADING_START":
    {
      return {
        ...state,
        isLoading: true,
        detailLoading: true
      }
    }

    case "USER_DATA_LOADING_FINISHED":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false
      }
    }

    case "USER_LOAD_ERROR":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        error: action.data
      };
    }


    default:
      return state;
  }

}
