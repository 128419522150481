import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import { VINActionTab } from 'helpers/constants';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import AppDialogAlert from 'components/AppDialogAlert';
import NothingSelectedTitle from 'components/NothingSelectedTitle';
import SomethingSelectedTitle from 'components/SomethingSelectedTitle';
import SessionManager from 'session/SessionManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class DeviceDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {tabIndex: 0,deleteOpen: false, saveOpen: false};

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);
    this.deleteConfirmClick = this.deleteConfirmClick.bind(this);
    this.saveConfirmClick = this.saveConfirmClick.bind(this);
    this.approveClick = this.approveClick.bind(this);
    this.canSaveCancel = this.canSaveCancel.bind(this);

    this.canCancel = this.canCancel.bind(this);
    this.canSave = this.canSave.bind(this);

    this.allowedEdit = SessionManager.shared().userAllowedEdit('device');
    this.allowedDelete = !this.props.isSaving && SessionManager.shared().userAllowedDelete('device');
  }

  fieldPropsHandler(field, template){
    if (this.allowedEdit){
      if (field !== 'groupId'){
        return { InputProps: { disabled: true } };
      }
    }else {
      return { InputProps: { disabled: true } };
    }
  }

  tabChange(ev,value){
    this.setState({tabIndex:value});
  }

  fieldChanged(field,value){
    console.log('fieldChanged '+field + ' '+value);
    this.props.detailEdit("device",field,value);
  }

  deleteConfirmClick(){
    this.setState({deleteOpen:false});

    if(this.props.selectedVin != null && this.props.selectedDevice != null){
      let config = this.props.selectedDevice;
      let deviceID = config.device.id;
      this.props.deleteDevice(this.props.selectedVin.id, deviceID);
    }
  }
  saveConfirmClick(){
    console.log('save device');

    if(this.props.selectedVin != null && this.props.selectedDevice != null){
      let config = this.props.selectedDevice;
      let deviceID = config.device.id;
      this.props.updateDevice(this.props.selectedVin.id, deviceID, this.props.editingDevice.groupId);
    }

  }

  approveClick(){
    if(this.props.selectedVin != null && this.props.selectedDevice != null){
      let config = this.props.selectedDevice;
      let deviceID = config.device.id;
      this.props.approveDevice(this.props.selectedVin.id, deviceID);
    }
  }

  canSaveCancel(){
    if(this.props.selectedDevice == null){
      return false;
    }
  }

  canCancel(){
    if(this.props.selectedDevice == null || this.props.isSaving){
      return false;
    }
    return (
      this.props.selectedDevice.groupId !== this.props.editingDevice.groupId
    );
  }

  canSave(){
    return this.canCancel();
  }

  render() {

    console.log("DeviceDetail:: ",this.props.isSaving);
    console.log("DeviceDetail:: selectedDevice",this.props.selectedDevice);
    console.log("DeviceDetail:: editingDevice",this.props.editingDevice);

    const { t, theme } = this.props;

    if (this.props.selectedDevice == null){
      return (
        <NothingSelectedTitle>{t('noDeviceSelectedInfo')}</NothingSelectedTitle>
      );
    }

    if (this.props.detailLoading){
      return <Loading/>
    }

    let content = [];
    let header = null;

    if (this.props.actionTab === VINActionTab.DEVICES){

      header = (
        <SomethingSelectedTitle>{t('deviceDetailTitle', {name:this.props.selectedDevice.device.name})}</SomethingSelectedTitle>
      );
      //debug = JSON.stringify(this.props.selectedGroup,null,2);

      let groupData = [];

      if(this.props.selectedVinGroups != null){
        groupData = this.props.selectedVinGroups.map( item => {
          return {id:item.id, name: item.name};
        });
      }

      console.log("=============== Editing Device", this.props.editingDevice);
      let obj = {...this.props.editingDevice};


      let needsApproval = false;
      if(obj.enable != null){
        needsApproval = !obj.enable;
      }

      let canSave = this.canSave();
      let canCancel = this.canCancel();



      content = (
        <Fragment>
          <div style={{borderBottom: '1px solid grey'}}>
            <Tabs value={this.state.tabIndex} onChange={this.tabChange}>
              <Tab label={t('tabDeviceDetails')} />
            </Tabs>
          </div>
          <TabPanel index={0} value={this.state.tabIndex} >
            <FormGroup>
              {needsApproval ?
                <Box className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
                  <AppButton
                    disabled={this.props.selectedDevice == null}
                    buttonType={"primary"}
                    onClick={this.approveClick}
                  >
                    {t('buttonApprove').toLocaleUpperCase()}
                  </AppButton>
                </Box>
                :
                null
              }

              <DataDetails template={"DeviceDetail"}
                           object={obj}
                           data={{data:groupData}}
                           extraPropsHandler={this.fieldPropsHandler}
                           selectChangeHandler={this.fieldChanged}
              />
            </FormGroup>

            {this.allowedEdit ?
              <Box className="d-flex justify-content-end" style={{marginTop:15}}>
                <AppButton
                  buttonType={'grey'}
                  style={{marginRight:10}}
                  onClick={this.props.cancelDetailEdit}
                  disabled={!canCancel}
                >
                  {t('common:labels.cancel').toLocaleUpperCase()}
                </AppButton>

                <AppButton
                  buttonType={"primary"}
                  disabled={!canSave}
                  onClick={this.saveConfirmClick}
                >
                  {t('common:labels.save').toLocaleUpperCase()}
                </AppButton>
              </Box>
              : null
            }

            {this.allowedDelete ?
              <Box className="d-flex justify-content-center" style={{ marginTop: 60 }}>
                <AppButton
                  buttonType={"delete"}
                  startIcon={<DeleteIcon/>}
                  disabled={this.props.selectedDevice == null || this.props.isSaving}
                  onClick={() => this.setState({ deleteOpen: true })}
                >
                  {t('buttonDeleteDevice').toLocaleUpperCase()}
                </AppButton>
              </Box>
              : null
            }

          </TabPanel>

        </Fragment>
      )
    }



    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
          {header}
        </Box>
        <Paper style={{padding:16}}>
          {content}
        </Paper>

        <AppDialogAlert
          open={this.state.deleteOpen}
          title={t("vindevice:deletedevice.title")}
          message={t("vindevice:deletedevice.message", {name:this.props.selectedDevice.device.name})}
          confirmActionTitle={ t('common:uppercase', {text:t('common:labels.delete')}) }
          confirmHandler={this.deleteConfirmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({deleteOpen:false})}
        />

        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
      </Fragment>
    );

  }
}
export default withTranslation(['vindevice','common'])(withTheme(DeviceDetail));
