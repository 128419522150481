/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigDetails,
    ConfigDetailsFromJSON,
    ConfigDetailsFromJSONTyped,
    ConfigDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultConfigDetails
 */
export interface RESTResultConfigDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultConfigDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultConfigDetails
     */
    error?: string;
    /**
     * 
     * @type {ConfigDetails}
     * @memberof RESTResultConfigDetails
     */
    data?: ConfigDetails;
}

export function RESTResultConfigDetailsFromJSON(json: any): RESTResultConfigDetails {
    return RESTResultConfigDetailsFromJSONTyped(json, false);
}

export function RESTResultConfigDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultConfigDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ConfigDetailsFromJSON(json['data']),
    };
}

export function RESTResultConfigDetailsToJSON(value?: RESTResultConfigDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': ConfigDetailsToJSON(value.data),
    };
}


