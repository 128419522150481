import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup, Grid
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';
import SessionManager from 'session/SessionManager';
import { Alert, AlertTitle } from '@material-ui/lab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class LicenseDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      tab:0,
      licenceKey:"",
    };

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.activateClick = this.activateClick.bind(this);
  }

  fieldPropsHandler(field, template){
    return { disabled: true }
  }

  tabChange(ev,value){
    this.setState({tab:value});
  }

  inputChanged(field, value){
    this.setState({licenceKey:value});
  }

  activateClick(){
    let key = this.state.licenceKey.trim();
    if(key != null && key.length > 0){
      this.props.updateLicence(key);
    }
    this.setState({licenceKey:""});
  }

  render() {

    // console.log("LicenceDetails:: ",this.props);

    const { t } = this.props;

    if (this.props.loading){
      return <Loading/>;
    }

    let content = [];
    let details = {...this.props.licenceData};
    //dd.lastSeenInfo = t('common:lastSeenInfo', { date: d });
    if (details.notAfter != null){
      let d = new Date(details.notAfter);
      details.notAfterInfo = t('common:fullDateTime', { date: d });
    }else{
      details.notAfterInfo = ""
    }
    if (details.notBefore != null){
      let d = new Date(details.notBefore);
      details.notBeforeInfo = t('common:fullDateTime', { date: d });
    }else{
      details.notBeforeInfo = ""
    }

    let expired;
    if (details.licensed === false){
      expired = (
      <Alert severity={'error'} style={{marginBottom:20}}>
        {t('dashboard:licenseExpireAlertTitle')}
      </Alert>
      );
    }

    let licenceOk = this.state.licenceKey.trim().length > 0;
    let buttonTitle = this.props.activating === true ? t('common:uppercase', {text: t('common:labels.loading')}) : t('common:uppercase', {text: t('buttonActivate')});

      content = (
        <Fragment>
          <div style={{borderBottom: '1px solid grey'}}>
            <Tabs value={this.state.tab} variant="scrollable" onChange={this.tabChange}>
              <Tab label={t('tabDetails').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
              <Tab label={t('tabActivate').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
            </Tabs>
          </div>
          <TabPanel index={0} value={this.state.tab}>
            {expired}
            <FormGroup>
              <DataDetails
                template={"LicenseDetail"}
                object={details}
                extraPropsHandler={this.fieldPropsHandler}
              />
            </FormGroup>
          </TabPanel>
          <TabPanel index={1} value={this.state.tab}>
            <FormGroup>
              <DataDetails
                template={"LicenseActivate"}
                object={{...this.state}}
                inputChangeHandler={this.inputChanged}
              />
            </FormGroup>
            <Box className="d-flex justify-content-end">
              <AppButton buttonType={"primary"} onClick={this.activateClick} disabled={this.props.activating || !licenceOk}>{buttonTitle}</AppButton>
            </Box>
          </TabPanel>
        </Fragment>
      );

      let crumbs = [{name:t('pageTitle')}];


    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} withAdd={false} />
        <Grid spacing={0} container={true} style={{backgroundColor:this.props.theme.palette.common.detailContentBg}}>
          <Grid item xs={4} >
            <Paper style={{padding:16}}>
              {content}
            </Paper>
          </Grid>
        </Grid>

      </Fragment>
    );

  }
}
export default withTranslation('license')(withTheme(LicenseDetail));
