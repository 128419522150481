/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewLicenseDetails
 */
export interface NewLicenseDetails {
    /**
     * 
     * @type {string}
     * @memberof NewLicenseDetails
     */
    licenseKey?: string;
}

export function NewLicenseDetailsFromJSON(json: any): NewLicenseDetails {
    return NewLicenseDetailsFromJSONTyped(json, false);
}

export function NewLicenseDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewLicenseDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenseKey': !exists(json, 'licenseKey') ? undefined : json['licenseKey'],
    };
}

export function NewLicenseDetailsToJSON(value?: NewLicenseDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenseKey': value.licenseKey,
    };
}


