/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganisationCount
 */
export interface OrganisationCount {
    /**
     * 
     * @type {number}
     * @memberof OrganisationCount
     */
    sitegroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCount
     */
    networkCount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCount
     */
    sitegrouopId?: number;
}

export function OrganisationCountFromJSON(json: any): OrganisationCount {
    return OrganisationCountFromJSONTyped(json, false);
}

export function OrganisationCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sitegroupId': !exists(json, 'sitegroup_id') ? undefined : json['sitegroup_id'],
        'networkCount': !exists(json, 'network_count') ? undefined : json['network_count'],
        'sitegrouopId': !exists(json, 'sitegrouop_id') ? undefined : json['sitegrouop_id'],
    };
}

export function OrganisationCountToJSON(value?: OrganisationCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sitegroup_id': value.sitegroupId,
        'network_count': value.networkCount,
        'sitegrouop_id': value.sitegrouopId,
    };
}


