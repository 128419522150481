import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import { GroupMeshType, VINActionTab } from 'helpers/constants';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import { appImages } from 'helpers/images';
import AppDialogAlert from 'components/AppDialogAlert';
import GroupLinkTable from 'components/Tables/GroupLinkTable';
import { isValidGroup } from 'helpers/validate';
import NothingSelectedTitle from 'components/NothingSelectedTitle';
import SomethingSelectedTitle from 'components/SomethingSelectedTitle';
import SessionManager from 'session/SessionManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class GroupDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      groupTab: 0,
      deleteOpen: false,
      regenOpen: false
    };

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.fieldChanged = this.fieldChanged.bind(this);
    this.deleteVinConfirmClick = this.deleteVinConfirmClick.bind(this);
    this.saveConfirmClick = this.saveConfirmClick.bind(this);
    this.saveLinkConfirmClick = this.saveLinkConfirmClick.bind(this);
    this.canSaveCancel = this.canSaveCancel.bind(this);
    this.canSaveCancelLink = this.canSaveCancelLink.bind(this);
    this.linkChanged = this.linkChanged.bind(this);
    this.regenerateInvite = this.regenerateInvite.bind(this);
    this.regenerateInviteConfirmClick = this.regenerateInviteConfirmClick.bind(this);

    this.allowedEdit = SessionManager.shared().userAllowedEdit('group');
    this.allowedDelete = SessionManager.shared().userAllowedDelete('group');
  }

  fieldPropsHandler(field, template){

    if (this.allowedEdit) {

      if (this.props.selectedGroup == null) {
        return { disabled: true }
      } else {
        if (field === 'vin' || field === 'invite') {
          return { InputProps: { disabled:true } };
        }
      }
    }else {
      return { InputProps: { disabled:true } };
    }
  }

  tabChange(ev,value){
    this.setState({groupTab:value});
  }

  regenerateInvite()
  {
    // first ask if the want to
    this.setState({regenOpen:true});
  }

  regenerateInviteConfirmClick()
  {
    this.setState({regenOpen:false});

    if (this.props.selectedVin != null && this.props.selectedGroup != null){
      this.props.regenerateInvite(this.props.selectedVin.id,this.props.selectedGroup.id);
    }

  }

  fieldChanged(field,value){

    this.props.detailEdit("group",field,value);
  }

  deleteVinConfirmClick(){
    this.setState({deleteOpen:false});
    if (this.props.selectedVin != null && this.props.selectedGroup != null){
      this.props.deleteGroup(this.props.selectedVin.id,this.props.selectedGroup.id);
    }
  }
  saveConfirmClick(){
    this.setState({saveOpen:false});

    if(this.props.editingGroup != null){
      this.props.updateGroup(this.props.editingGroup);
    }
  }

  saveLinkConfirmClick(){
    this.setState({saveLinkOpen:false});

    if(this.props.editingGroup != null){
      this.props.updateGroupLinks(this.props.editingGroup);
    }
  }

  canSaveCancel(){
    if(this.props.selectedGroup == null || this.props.isSaving){
      return {cancel: false, save: false};
    }
    let enrolmentChange = false;

    try
    {
       enrolmentChange =
        this.props.selectedGroup.invites[0].autoEnabled != this.props.editingGroup.invites[0].autoEnabled ||
        this.props.selectedGroup.invites[0].enabled != this.props.editingGroup.invites[0].enabled;

      // console.log("================== canSaveCancel", enrolmentChange);
    }
    catch (e)
    {
      console.log("Missing invites", e)
    }

    let cancel = (this.props.selectedGroup.name !== this.props.editingGroup.name ||
      this.props.selectedGroup.description !== this.props.editingGroup.description ||
      this.props.selectedGroup.meshType !== this.props.editingGroup.meshType ||
      enrolmentChange);

    let save = cancel && isValidGroup(this.props.editingGroup); //cancel && stringNotNullUndefOrEmpty(this.props.editingGroup.name);

    return {cancel: cancel, save: save};

  }
  canSaveCancelLink(){
    if(this.props.selectedGroup == null){
      return false;
    }
    return this.props.selectedGroup.connectionIds.toString() !== this.props.editingGroup.connectionIds.toString();
  }

  linkChanged(group,linkType){
    console.log('link changed ',Object.assign({},group), " ",linkType);

    if (this.props.selectedGroup == null){
      return;
    }
    let con = [];
    if (this.props.editingGroup.connectionIds != null){
      con = this.props.editingGroup.connectionIds.slice();
    }

    if (linkType === 'link'){
      if(!con.includes(group.id)){
        con.push(group.id);
      }

      this.props.checkLink(this.props.selectedVin.id,this.props.selectedGroup.id,con);

    }else{
      if(con.includes(group.id)){
        con.splice(con.indexOf(group.id),1);

        this.props.detailEdit("group","connectionIds",con);
      }
    }


  }

  render() {

    // console.log("GroupDetail:: ",this.props);
    // console.log("GroupDetail:: ",this.props.selectedGroup);

    const { t, theme } = this.props;

    if (this.props.selectedGroup == null){
      return (
        <NothingSelectedTitle>{t('noGroupSelectedInfo')}</NothingSelectedTitle>
      );
    }

    // if (this.props.detailLoading || this.props.editingGroup == null){
    //   return null;//<Loading/>
    // }

    let content = [];
    let header = null;
    // let cancelSave = this.canSaveCancel();
    let {cancel, save} = this.canSaveCancel();
    let linkCancelSave = this.canSaveCancelLink()

    if (this.props.actionTab === VINActionTab.GROUPS || this.props.actionTab === VINActionTab.TOPOLOGY){

      header = (
        <SomethingSelectedTitle>{t('groupDetailTitle', {name:this.props.selectedGroup.name})}</SomethingSelectedTitle>
      );

      let meshData = [{id:GroupMeshType.meshed,name:t('mesh')}, {id:GroupMeshType.nonMeshed,name:t('nonmesh')}];

      let enrolmentData = [{id:'auto',name:t('groupEnrolmentOptionAuto')},{id:'manual',name:t('groupEnrolmentOptionManual')}, {id:'off',name:t('groupEnrolmentOptionNotAllowed')}]

      let obj = {...this.props.editingGroup};

      if (this.props.editingGroup.invites != null){
        obj.invites = [...this.props.editingGroup.invites]
      }else{
        obj.invites = null;
      }

      // console.log('obj before ',Object.assign({},obj));
      if (this.props.selectedVin != null)
      {
        obj.vin = this.props.selectedVin.name;
      }

      obj.enrolment = 'off';
      if (this.props.editingGroup != null && obj.invites != null && obj.invites.length > 0) {
        obj.invite = obj.invites[0].invitation;

        if (obj.invites[0].enabled) {

          if (obj.invites[0].autoEnabled) {
            obj.enrolment = 'auto';
          } else {
            obj.enrolment = 'manual';
          }
        }
      }



      content = (
        <Fragment>
          <div style={{borderBottom: '1px solid grey'}}>
            <Tabs value={this.state.groupTab} onChange={this.tabChange}>
              <Tab label={t('tabGroupDetails')} />
              <Tab label={t('tabGroupLinking')} disabled={this.props.selectedGroup == null}/>
            </Tabs>
          </div>
          <TabPanel index={0} value={this.state.groupTab} >
            <FormGroup>
              <DataDetails template={"GroupDetails"}
                           object={obj}
                           data={{data:meshData,options:enrolmentData}}
                           extraPropsHandler={this.fieldPropsHandler}
                           inputChangeHandler={this.allowedEdit ? this.fieldChanged : null}
                           selectChangeHandler={this.allowedEdit ? this.fieldChanged: null}
                           regenerateHandler={this.regenerateInvite}
              />
            </FormGroup>

            {this.allowedEdit ?
              <Box className="d-flex justify-content-end" style={{ marginTop: 15 }}>
                <AppButton
                  buttonType={'grey'}
                  style={{ marginRight: 10 }}
                  onClick={this.props.cancelDetailEdit}
                  disabled={!cancel}
                >
                  {t('common:labels.cancel').toLocaleUpperCase()}
                </AppButton>
                <AppButton
                  buttonType={"primary"}
                  disabled={!save}
                  onClick={this.saveConfirmClick}
                >
                  {t('common:labels.save').toLocaleUpperCase()}
                </AppButton>
              </Box>
              : null
            }

            {this.allowedDelete ?
              <Box className="d-flex justify-content-center" style={{ marginTop: 60 }}>
                <AppButton
                  buttonType={"delete"}
                  startIcon={<DeleteIcon/>}
                  disabled={this.props.selectedGroup == null}
                  onClick={() => this.setState({ deleteOpen: true })}
                >
                  {t('buttonDeleteGroup').toLocaleUpperCase()}
                </AppButton>
              </Box>
              : null
            }

          </TabPanel>

          <TabPanel index={1} value={this.state.groupTab} style={{marginLeft:0, paddingRight:0}}>
            <GroupLinkTable
              groups={this.props.selectedVinGroups}
              connections={obj.connectionIds}
              linkType="unlink"
              linkAction={this.linkChanged}
              group={obj}
            />
            <Box style={{height:25}}/>

            <GroupLinkTable
              groups={this.props.selectedVinGroups}
              connections={obj.connectionIds}
              linkType="link"
              linkAction={this.linkChanged}
              group={obj}
              loading={this.props.detailLoading}
              loadingId={this.props.groupCheckId}
            />

            {this.allowedEdit ?
              <Box className="d-flex justify-content-end" style={{ marginTop: 15 }}>
                <AppButton
                  buttonType={'grey'}
                  style={{ marginRight: 10 }}
                  onClick={this.props.cancelDetailEdit}
                  disabled={!linkCancelSave}
                >
                  {t('common:labels.cancel').toLocaleUpperCase()}
                </AppButton>
                <AppButton
                  buttonType={"primary"}
                  disabled={!linkCancelSave}
                  onClick={this.saveLinkConfirmClick}
                >
                  {t('common:labels.save').toLocaleUpperCase()}
                </AppButton>
              </Box>
              : null
            }

          </TabPanel>
        </Fragment>
      )
    }



    return (
      <Fragment>
        <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
          {header}
        </Box>
        <Paper style={{padding:16}}>
          {content}
        </Paper>

        <AppDialogAlert
          open={this.state.deleteOpen}
          title={t("vinsgroups:deletegroup.title")}
          message={t("vinsgroups:deletegroup.message", {name:this.props.editingGroup.name})}
          confirmActionTitle={ t('common:uppercase', {text:t('common:labels.delete')}) }
          confirmHandler={this.deleteVinConfirmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({deleteOpen:false})}
        />

        <AppDialogAlert
          open={this.state.regenOpen}
          title={t("vinsgroups:regeninvite.title")}
          message={t("vinsgroups:regeninvite.message", {name:this.props.editingGroup.name})}
          confirmActionTitle={ t('common:uppercase', {text:t('vinsgroups:regeninvite.button')}) }
          confirmHandler={this.regenerateInviteConfirmClick}
          alternateActionTitle={ t('common:uppercase', {text:t('common:labels.cancel')}) }
          alternateHandler={() => this.setState({regenOpen:false})}
        />

        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
      </Fragment>
    );

  }
}
export default withTranslation('vinsgroups')(withTheme(GroupDetail));
