import React, { useState } from 'react';
import {
  Snackbar,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions, DialogTitle
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';


const AppDialogAlert = props => {

  //console.log("AppDialogAlert:: ", props);
  //console.log("  AppDialogAlert:: ", props.alert);

  const { t } = useTranslation();

  let ct = props.confirmActionTitle;
  if(ct == null){
    ct = t('common:labels.ok');
  }

  let confirm = (
    <AppButton buttonType={"primary"} onClick={props.confirmHandler}>
      {ct}
    </AppButton>
  );

  let alt;
  if(props.alternateActionTitle != null && props.alternateHandler != null){
    alt = (
      <AppButton onClick={props.alternateHandler}>
        {props.alternateActionTitle}
      </AppButton>
    )
  }

  let actions = (
    <DialogActions>
      {alt}
      {confirm}
    </DialogActions>
  )


  return (
    <div>
      <Dialog open={props.open} onClose={props.closeHandler}>
        {props.title != null ?
          <DialogTitle>{props.title}</DialogTitle>
          : null
        }
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
          <DialogActions>
            {actions}
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AppDialogAlert.propTypes = {
  title: PropTypes.string,
  message:PropTypes.string.isRequired,
  open:PropTypes.bool.isRequired,
  confirmActionTitle:PropTypes.string,
  alternateActionTitle:PropTypes.string,
  confirmHandler:PropTypes.func.isRequired,
  alternateHandler:PropTypes.func,
  closeHandler:PropTypes.func,
};


export default AppDialogAlert;


