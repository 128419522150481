import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { appImages } from 'helpers/images';
import templates from 'utils/templates';

import {ReactComponent as GroupLogo} from 'assets/netlinkz/group.svg';

import {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Image } from '@material-ui/icons';
import useWindowSize from 'utils/windowSize';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// const StyledTableCell = withStyles((theme) => ({
//   head:{
//     backgroundColor: theme.palette.table.headerBg,
//       // color: theme.palette.table.text,
//     fontWeight:'bold',
//   },
//   root:{
//     color: theme.palette.table.text,
//   },
// }))(TableCell);

const DataTable = props => {

  const size = useWindowSize();

  // console.log('DataTable ',props);
  const { t } = useTranslation(['users','organisation','common','vinsgroups','vindevice']);
  const theme = useTheme();

  // find the first none empty column to sort by
  let defaultField = null;
  if (props.defaultSort != "none")
  {
    defaultField = props.columns[0];
    if (defaultField.label == null || defaultField.label.length == 0) defaultField = props.columns[1];

    for (let i in props.columns)
    {
      let c = props.columns[i];
      if (c.label !== null && c.label.length > 0) {
        defaultField = c;
        break;
      }
      if (c.defaultSort === true)
      {
        defaultField = c;
      }
    }
  }

  // console.log("Default Field = " , defaultField);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultField==null ? null : defaultField.field);
  // console.log("order by = " , orderBy);
  let sizeWithWindow = props.sizeWithWindow;

  let bClickRow = props.columnClickIndex == null;

  let click =  (item) => {
    if (props.rowClickHandler != null) {
      props.rowClickHandler(item);
    }
  };

  let topBar = null;

  if (props.topHeader != null) {
    topBar = (
      <TableRow style={{ backgroundColor: theme.palette.table.tableTopHeader }}>
        <TableCell style={{ backgroundColor: theme.palette.table.tableTopHeader }}></TableCell>
        <TableCell style={{ backgroundColor: theme.palette.table.tableTopHeader }}
                   colSpan={100}>{props.topHeader}</TableCell>
      </TableRow>
    );
  }

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (orderBy, order, a, b) =>
  {
    if (orderBy == null) return 0;
    let aData = templates.getValue(a,orderBy);
    let bData = templates.getValue(b,orderBy);

    if (aData != null && bData != null)
    {
      if (typeof aData === 'string' && typeof bData === 'string') {
        if (order == 'desc') return bData.localeCompare(aData);
        else return aData.localeCompare(bData);
      }

      if (typeof aData === 'number' && typeof bData === 'number'){
        if (order === 'desc'){
          return aData - bData;
        }else{
          return bData - aData;
        }
      }
    }
    return 0;
  }

  let header = props.columns.map( (col) => {

    let field = col.field;
    if (col.sortField != null){
      field = col.sortField;
    }

    let bCanSort = (col.label != null && col.label.length > 0);
    if (!bCanSort)
    {
      return <TableCell key={field}>{t(col.label)}</TableCell>
    }
    else {
      return <TableCell key={field} sortDirection={orderBy === field ? order : false}>
        <TableSortLabel active={orderBy === field} direction={orderBy === field ? order : 'asc'} onClick={createSortHandler(field)}>
          {t(col.label)}
        </TableSortLabel>
      </TableCell>
    }
  });

  let data = [];
  if (props.data != null)
  {
    data = props.data;
  }

  let rows = data
    .sort((a,b) => sortData(orderBy, order, a, b))
    .map ( (item, index) =>
    {
      // console.log('item: ',item);
      let selected = false;
      if (props.selected != null){
        selected = item.id === props.selected.id
      }

      let styleOverride = {};
      if(props.customRowStyler != null){
        let name = props.customRowStyler(item);
        if (name === 'notapproved'){
          styleOverride = {backgroundColor: theme.palette.table.rowDeviceNotApproved};
        }
        if(name === 'superuser'){
          styleOverride = {color:'green'};
        }
      }

      if (!bClickRow)
      {
        styleOverride.cursor = "default";
      }

      return (
      <TableRow
        hover={bClickRow ? true : null}
        selected={selected}
        key={"ROW" + index}
        onClick={bClickRow ? click.bind(this,item) : null}
        style={styleOverride}
      >
        {props.columns.map( (col, i) => {
          let td;

          let colStyle = {};
          let colClick = null;
          if (!bClickRow && i == props.columnClickIndex)
          {
            colClick = click.bind(this,item);
            colStyle.cursor = "pointer";
            colStyle.color = "#0893E1";
          }
          let width = null;
          if (col.valueAsKey === true){
            td = templates.getValue(item,col.field);
            let key = col.namespace + ':'+td;
            td = t(key);
          }else if(col.type === 'icon'){

            let field = col.field;
            if(typeof field === 'function'){
              td = appImages[field(item)];
            }else {
              td = appImages[col.field];
            }
            width = 10;
          }else {

            let field = col.field;
            if(typeof field === 'function'){
              td = field(item)
            }else {
              td = templates.getValue(item, col.field);
            }
            // console.log('td: ',td);
            if (td == null) {
              td = '';
            } else if (typeof td !== 'string' && typeof td !== 'number') {
              // console.log('td not string: '+ typeof td,td);
              td = JSON.stringify(td);
            }

          }

          let thekey = index + "::" + i;
          if (col.id != null) thekey = col.id;

        if (props.customCellStyler != null){
          let name = props.customCellStyler(item,col.field);
          if (name === 'superuser'){
            colStyle = {...colStyle, color: theme.palette.common.superUser}
          }
          if (name === 'mono'){
            colStyle = {...colStyle, fontFamily:'monospace'}
          }
        }

          return (
            <TableCell width={width} key={thekey} onClick={colClick} style={colStyle}>
              {td}
            </TableCell>
          );
        })}
      </TableRow>
      )
    });

  let paperStyle = {width:'100%'};
  if (sizeWithWindow != null)
  {
    paperStyle.maxHeight = size.height - sizeWithWindow;
  }

  if (rows.length === 0){
    rows.push(
      <TableRow
        key={"Empty"}

      >
        <TableCell colSpan={props.columns.length} style={{fontWeight:500,fontSize:12, fontStyle:'italic'}}>
          {t('common:table.noData')}
        </TableCell>
      </TableRow>
    )
  }

  return(
    <Paper style={paperStyle}>
      <TableContainer component={Paper} style={paperStyle}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {topBar}
            <TableRow>
              {header}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>

      </TableContainer>
    </Paper>
  )
}

DataTable.propTypes = {
  data: PropTypes.array,
  sizeWithWindow: PropTypes.number,
  rowClickHandler: PropTypes.func,
  selected: PropTypes.object,
  customRowStyler: PropTypes.func,
  columns: PropTypes.arrayOf( (propValue, key, componentName, location, propFullName) => {
    if (typeof propValue[key] !== 'object'){
      return new Error(
        "Invalid column `"+propValue[key]+"` for "+componentName+ ". columns must be an array of {field: string, label: string}"
      );
    }
    let el = propValue[key];
    if (typeof el['field'] !== 'string' && typeof el['label'] !== 'string'){
      return new Error(
        "Invalid column `"+JSON.stringify(propValue[key])+"` for "+componentName+ ". columns must be an array of {field: string, label: string}"
      );
    }
  })
};

export default DataTable;
