import React, { Fragment, useEffect, useState } from 'react';
import { RingLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';

const Loading = () => {

  const { t } = useTranslation('common');
  // const [show, setShow] = useState(false);
  //
  //
  // useEffect( () => {
  //   const timer = setTimeout(() => {
  //     console.log("in timer");
  //       setShow(true);
  //   },140);
  //   return () =>{
  //     console.log('cleanup');
  //     clearTimeout(timer);
  //   }
  //   }
  // ,[]);
  //
  //
  //
  // if (show === false){
  //   return null;
  // }

  return (
    <Fragment>
      <div className="d-flex align-items-center flex-column h-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <RingLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          {t('appname')}
          <span className="font-size-lg d-block text-dark">
              {t('labels.loading')}
            </span>
        </div>
      </div>
    </Fragment>
  );
};

export default Loading;
