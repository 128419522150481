import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import TableHead from '@material-ui/core/TableHead';
import useTheme from '@material-ui/core/styles/useTheme';

const getOrgName = (orgId, orgMap) =>
{
  let org = orgMap[orgId];
  if (org == null) return "";
  else return org.name;

}

const ApprovalTable = props => {

  // console.log('GroupLinkTable:: ',props);

  let { devices } = props;
  const { t } = useTranslation(['dashboard','vinsgroups','vindevice']);
  const theme = useTheme();


  if (devices == null || devices.length == 0)
  {
    return <Typography>{t('noApprovals')}</Typography>
  }

  //let cellWidth=115;
  let lastCellWidth=90;

  // sort the devices by organisation
  let tables = [];

  for (let i=0; i<devices.length; ++i)
  {
    let dd = devices[i];
    if (dd.orgId == null) dd.orgId = 0;

    let button = <AppButton onClick={() => props.approveClick(dd.id)} buttonType={"primary"} size={"small"}>{t("dashboard:approveButton")}</AppButton>
    if (dd.isApproving === true)
    {
      button = <Typography style={{fontSize:10}}>{t('approvingStatus')}</Typography>
    }
    let style = {};//{width:cellWidth};
    let click = () => props.deviceClick(dd.id);
    let row = (
      <TableRow style={{backgroundColor:theme.palette.common.warningBackground}} key={dd.orgId + " : " + dd.id}>
        <TableCell style={style} onClick={click}>{dd.device.name}</TableCell>
        <TableCell style={style} onClick={click}>{dd.networkName}</TableCell>
        <TableCell style={style} onClick={click}>{dd.groupName}</TableCell>
        <TableCell style={{width:lastCellWidth, maxWidth:lastCellWidth, minWidth:lastCellWidth}}>
          {button}
        </TableCell>
      </TableRow>
    );

    if (tables[dd.orgId] == null) tables[dd.orgId] = [];
    tables[dd.orgId].push(row);
  }


  let fullTables = [];

  for (let orgId in tables)
  {

    let style = {whiteSpace:"nowrap"};
    let rows = tables[orgId];
    let headerRow = (
      <TableRow key={"headerRow" + orgId}>
        <TableCell style={style}>{t('vindevice:device')}</TableCell>
        <TableCell style={style}>{t('vin')}</TableCell>
        <TableCell style={style}>{t('vinsgroups:group')}</TableCell>
        <TableCell style={{...style, width:lastCellWidth, maxWidth:lastCellWidth, minWidth:lastCellWidth}}></TableCell>
      </TableRow>
    );

    let titleRow = (
      <TableRow key={"titleRow"+orgId} style={{backgroundColor: theme.palette.table.tableTopHeader}}>
        <TableCell style={{backgroundColor: theme.palette.table.tableTopHeader}} colSpan={100}>{t("dashboard:approvalOrgTitle", {name:getOrgName(orgId, props.orgMap)})}</TableCell>
      </TableRow>
    );

    let cardStyle = {};
    if (fullTables.length > 0) {
      cardStyle = {marginTop:21};
    }
    fullTables.push(
      <Card style={cardStyle} key={"card" + orgId} elevation={0}>
        <Table size={'small'}>
          <TableHead>
            {titleRow}
            {headerRow}
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <Fragment>
      {fullTables}
    </Fragment>
  )

};

ApprovalTable.propTypes = {
  devices: PropTypes.array.isRequired,
  orgMap: PropTypes.object.isRequired,
  approveClick: PropTypes.func.isRequired
};

export default ApprovalTable;
