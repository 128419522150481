/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RESTResultInteger,
    RESTResultIntegerFromJSON,
    RESTResultIntegerToJSON,
    RESTResultListUserDetails,
    RESTResultListUserDetailsFromJSON,
    RESTResultListUserDetailsToJSON,
    RESTResultUserDetails,
    RESTResultUserDetailsFromJSON,
    RESTResultUserDetailsToJSON,
    UserDetails,
    UserDetailsFromJSON,
    UserDetailsToJSON,
} from '../models';

export interface DeleteUserRequest {
    userId: number;
}

export interface GetUserRequest {
    userId: number;
}

export interface PostUserRequest {
    userDetails?: UserDetails;
}

export interface PutUserRequest {
    userId: number;
    userDetails?: UserDetails;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Deletes a user
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<RESTResultInteger>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultIntegerFromJSON(jsonValue));
    }

    /**
     * Deletes a user
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<RESTResultInteger> {
        const response = await this.deleteUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all users.
     */
    async getAll1Raw(): Promise<runtime.ApiResponse<RESTResultListUserDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListUserDetailsFromJSON(jsonValue));
    }

    /**
     * Retreives all users.
     */
    async getAll1(): Promise<RESTResultListUserDetails> {
        const response = await this.getAll1Raw();
        return await response.value();
    }

    /**
     * Retreives a single user.
     */
    async getUserRaw(requestParameters: GetUserRequest): Promise<runtime.ApiResponse<RESTResultUserDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultUserDetailsFromJSON(jsonValue));
    }

    /**
     * Retreives a single user.
     */
    async getUser(requestParameters: GetUserRequest): Promise<RESTResultUserDetails> {
        const response = await this.getUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new user.
     */
    async postUserRaw(requestParameters: PostUserRequest): Promise<runtime.ApiResponse<RESTResultUserDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDetailsToJSON(requestParameters.userDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultUserDetailsFromJSON(jsonValue));
    }

    /**
     * Creates a new user.
     */
    async postUser(requestParameters: PostUserRequest): Promise<RESTResultUserDetails> {
        const response = await this.postUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a user.
     */
    async putUserRaw(requestParameters: PutUserRequest): Promise<runtime.ApiResponse<RESTResultUserDetails>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDetailsToJSON(requestParameters.userDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultUserDetailsFromJSON(jsonValue));
    }

    /**
     * Updates a user.
     */
    async putUser(requestParameters: PutUserRequest): Promise<RESTResultUserDetails> {
        const response = await this.putUserRaw(requestParameters);
        return await response.value();
    }

}
