import React from 'react';
import LoginPage from 'pages/Login/LoginPage';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';


const mapStateToProps = (state, ownProps) => {

  return {
    isLoading: state.session.isLoading,
    isAuthenticated: state.session.isAuthenticated,
    error: state.session.error,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    doLogin: (username, password) => {
      // console.log("LoginContainer:: doLogin:");
      SessionManager.shared().authenticate(username, password);
    }
  };
};

class LoginView extends React.Component{

  render() {

     // console.log("LoginView:: ",this.props);
    return React.cloneElement(<LoginPage />, this.props);
  }

}



const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginView);
export default LoginContainer;


