/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    RESTResultSessionDetails,
    RESTResultSessionDetailsFromJSON,
    RESTResultSessionDetailsToJSON,
    RESTResultString,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
} from '../models';

export interface AuthenticatePostRequest {
    xForwardedHost?: string;
    xForwardedProto?: string;
    credentials?: Credentials;
}

/**
 * 
 */
export class AuthenticateApi extends runtime.BaseAPI {

    /**
     * Authenticate into the system.
     */
    async authenticatePostRaw(requestParameters: AuthenticatePostRequest): Promise<runtime.ApiResponse<RESTResultSessionDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xForwardedHost !== undefined && requestParameters.xForwardedHost !== null) {
            headerParameters['X-Forwarded-Host'] = String(requestParameters.xForwardedHost);
        }

        if (requestParameters.xForwardedProto !== undefined && requestParameters.xForwardedProto !== null) {
            headerParameters['X-Forwarded-Proto'] = String(requestParameters.xForwardedProto);
        }

        const response = await this.request({
            path: `/auth/authenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultSessionDetailsFromJSON(jsonValue));
    }

    /**
     * Authenticate into the system.
     */
    async authenticatePost(requestParameters: AuthenticatePostRequest): Promise<RESTResultSessionDetails> {
        const response = await this.authenticatePostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check auth session.
     */
    async checkSessionRaw(): Promise<runtime.ApiResponse<RESTResultSessionDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/checkSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultSessionDetailsFromJSON(jsonValue));
    }

    /**
     * Check auth session.
     */
    async checkSession(): Promise<RESTResultSessionDetails> {
        const response = await this.checkSessionRaw();
        return await response.value();
    }

    /**
     * Log out of the system.
     */
    async logoutPostRaw(): Promise<runtime.ApiResponse<RESTResultString>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     * Log out of the system.
     */
    async logoutPost(): Promise<RESTResultString> {
        const response = await this.logoutPostRaw();
        return await response.value();
    }

}
