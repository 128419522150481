/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RESTResultBoolean,
    RESTResultBooleanFromJSON,
    RESTResultBooleanToJSON,
    RESTResultListLong,
    RESTResultListLongFromJSON,
    RESTResultListLongToJSON,
} from '../models';

export interface CheckNetworkConnectionsRequest {
    vinId: number;
    groupId: number;
    requestBody?: Array<number>;
}

export interface GetNetworkConnectionsRequest {
    vinId: number;
    groupId: number;
}

export interface SetNetworkConnectionsRequest {
    vinId: number;
    groupId: number;
    requestBody?: Array<number>;
}

/**
 * 
 */
export class NetworkGroupConnectionApi extends runtime.BaseAPI {

    /**
     * Checks the group connections to see if this is a valid update
     */
    async checkNetworkConnectionsRaw(requestParameters: CheckNetworkConnectionsRequest): Promise<runtime.ApiResponse<RESTResultBoolean>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling checkNetworkConnections.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling checkNetworkConnections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/{vin_id}/connections/{group_id}/check`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultBooleanFromJSON(jsonValue));
    }

    /**
     * Checks the group connections to see if this is a valid update
     */
    async checkNetworkConnections(requestParameters: CheckNetworkConnectionsRequest): Promise<RESTResultBoolean> {
        const response = await this.checkNetworkConnectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives the group connections for a particular group
     */
    async getNetworkConnectionsRaw(requestParameters: GetNetworkConnectionsRequest): Promise<runtime.ApiResponse<RESTResultListLong>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling getNetworkConnections.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getNetworkConnections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/connections/{group_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListLongFromJSON(jsonValue));
    }

    /**
     * Retreives the group connections for a particular group
     */
    async getNetworkConnections(requestParameters: GetNetworkConnectionsRequest): Promise<RESTResultListLong> {
        const response = await this.getNetworkConnectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates the group connections for a particular group
     */
    async setNetworkConnectionsRaw(requestParameters: SetNetworkConnectionsRequest): Promise<runtime.ApiResponse<RESTResultBoolean>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling setNetworkConnections.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling setNetworkConnections.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/{vin_id}/connections/{group_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultBooleanFromJSON(jsonValue));
    }

    /**
     * Updates the group connections for a particular group
     */
    async setNetworkConnections(requestParameters: SetNetworkConnectionsRequest): Promise<RESTResultBoolean> {
        const response = await this.setNetworkConnectionsRaw(requestParameters);
        return await response.value();
    }

}
