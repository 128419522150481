export default function organisations(
  state = {
    isLoading: false,
    detailLoading: false,
    list: null,
    selected: null,
    editingOrg:null,
    isAdding:false,
    detailEditing: false,
    error:null,
    paramsReplacementId: null
  },
  action) {
  switch (action.type) {

    case "SELECT_ORG_ID":
    {
      return {
        ...state,
        paramsReplacementId: action.selectId,
      }
    }


    case 'AUTH_LOGOUT':
      return {
      isLoading: false,
      detailLoading: false,
      list: null,
      selected: null,
      editingOrg:null,
      isAdding:false,
      detailEditing: false,
      error:null,
    }

    case "LOAD_ORGS_START":
    {
      return {
        ...state,
        isLoading: true,
        list: null,
        selected: null,
        editingOrg: null,
        error: null
      };
    }

    case "LOAD_ORGS_FINISHED":
    {
      let data = action.data;

      return {
        ...state,
        isLoading: false,
        list:data
      };
    }

    case "LOAD_SELECTED_ORG_START":
    {
      return {
        ...state,
        detailLoading: true,
        selected: null,
        editingOrg: null,
      };
    }

    case "LOAD_SELECTED_ORG_FINISHED":
    {
      let edit = {...action.data};
      return {
        ...state,
        selected: action.data,
        editingOrg: edit,
        detailLoading: false
      };
    }

    case "ORG_LOAD_ERROR":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false,
        error: action.data
      };
    }

    case "SELECT_ORGANISATION":
    {
      let edit = {...action.data};
      if (action.data.deviceLimit != null) {
        edit.deviceLimit = { ...action.data.deviceLimit };
      }else{
        edit.deviceLimit = null;
      }
      return {
        ...state,
        selected: action.data,
        editingOrg: edit,
        detailLoading: false,
        isLoading: false,
      }
    }

    case "ORG_DETAIL_FIELD_CHANGE":
    {
      // console.log('ORG_DETAIL_FIELD_CHANGE ',action);
      let data = action.data;
      let edit = Object.assign({},state.editingOrg);
      edit[data.field] = data.value;

      return {
        ...state,
        editingOrg: edit,
      }
    }

    case "SAVE_NEW_ORG_START":
    {
      return {
        ...state,
        isLoading: true,
      }
    }

    case "SAVE_NEW_ORG_FINISHED":
    {
      return {
        ...state,
        isAdding: false,
      }
    }

    case "CANCEL_ORGDETAIL_EDIT":
    {
      let edit = Object.assign({},state.selected);
      if (state.selected.deviceLimit != null) {
        edit.deviceLimit = { ...state.selected.deviceLimit };
      }else{
        edit.deviceLimit = null;
      }

      return{
        ...state,
        editingOrg: edit,
      }
    }

    case "ORG_DATA_LOADING_START":
    {
      return {
        ...state,
        isLoading: true,
        detailLoading: true
      }
    }

    case "ORG_DATA_LOADING_FINISHED":
    {
      return {
        ...state,
        isLoading: false,
        detailLoading: false
      }
    }

    case "SAVE_NEW_ORG_ERROR":
    {
      return {
        ...state,
        error: action.data,
      }
    }


    default:
      return state;
  }

}
