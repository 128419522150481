/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RESTResultSearchResponse,
    RESTResultSearchResponseFromJSON,
    RESTResultSearchResponseToJSON,
    SearchRequest,
    SearchRequestFromJSON,
    SearchRequestToJSON,
} from '../models';

export interface DoSearchRequest {
    searchRequest?: SearchRequest;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     * Searches The whole database.
     */
    async doSearchRaw(requestParameters: DoSearchRequest): Promise<runtime.ApiResponse<RESTResultSearchResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultSearchResponseFromJSON(jsonValue));
    }

    /**
     * Searches The whole database.
     */
    async doSearch(requestParameters: DoSearchRequest): Promise<RESTResultSearchResponse> {
        const response = await this.doSearchRaw(requestParameters);
        return await response.value();
    }

}
