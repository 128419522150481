import React from 'react';
import {ReactComponent as IconCompany} from 'assets/netlinkz/companyLogo.svg';
import {ReactComponent as IconVin} from 'assets/netlinkz/vin.svg';
import {ReactComponent as IconVinDark} from 'assets/netlinkz/vinDark.svg';
import {ReactComponent as IconGroup} from 'assets/netlinkz/group.svg';
import {ReactComponent as IconGroupDark} from 'assets/netlinkz/groupDark.svg';
import {ReactComponent as IconDevice} from 'assets/netlinkz/device.svg';
import {ReactComponent as IconDeviceDark} from 'assets/netlinkz/deviceDark.svg';
import {ReactComponent as IconOrg} from 'assets/netlinkz/org.svg';
import {ReactComponent as IconUser} from 'assets/netlinkz/userIcon.svg';
import {ReactComponent as IconCross} from 'assets/netlinkz/crossIcon.svg';
import {ReactComponent as IconTick} from 'assets/netlinkz/tickIcon.svg';

export const appImages = {

  companyLogo: <IconCompany/>,
  companyLogoDark: <IconCompany className="company-dark" />,
  vinIcon: <IconVin/>,
  vinIconDark: <IconVinDark/>,
  groupIcon: <IconGroup/>,
  groupIconDark: <IconGroupDark/>,
  deviceIcon: <IconDevice/>,
  deviceIconDark: <IconDeviceDark/>,
  deviceOnlineIcon: <IconDevice className="device-online" />,
  orgIcon: <IconOrg className="icon-light"/>,
  userIcon: <IconUser/>,
  superUserIcon: <IconUser className="superuser-icon"/>,
  crossIcon: <IconCross/>,
  tickIcon: <IconTick/>,
};

export default {appImages};
