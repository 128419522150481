/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    type?: SearchRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    query?: string;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'query': !exists(json, 'query') ? undefined : json['query'],
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'query': value.query,
    };
}

/**
* @export
* @enum {string}
*/
export enum SearchRequestTypeEnum {
    ALL = 'ALL',
    VIN = 'VIN',
    GROUP = 'GROUP',
    DEVICE = 'DEVICE'
}


