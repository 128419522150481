import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';

import ContentLayout from 'layouts/Content/ContentLayout';
import CreateGroup from 'pages/VIN Pages/CreateGroup';

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps ', ownProps);
  let params = ownProps.match.params;
  let orgs = [];
  if (state.session.sessionData != null){
    orgs = state.session.sessionData.organisations;
  }

  return {
    orgId: params.org_id,
    vinId: params.vin_id,
    detailLoading: state.vinsgroups.detailLoading,
    orgVins: state.vinsgroups.orgVins,
    orgMap: state.session.orgMap,
    organisations: orgs,
    error: state.vinsgroups.error,
  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    saveNewGroup: (info) => {

      let vin = info.vinId;
      let group = info.group;

      //group.enrolment will be 'auto', 'manual' or 'off' .
      let enabled = false;
      let autoEnable = false;

      if (group.enrolment === 'auto'){
        enabled = true;
        autoEnable = true;
      }
      if (group.enrolment === 'manual'){
        enabled = true;
        autoEnable = false;
      }
      group.enabled = enabled;
      group.autoEnable = autoEnable;
      delete group["enrolment"];

     SessionManager.shared().createNewGroup(vin, group).then(result => {

       if(result != null && result.success === true){

         let group = result.data;
         let params = ownProps.match.params;
         SessionManager.shared().loadVinGroups(vin).then(result => {
           SessionManager.shared().loadGroupDetail(group.networkId, group.id);
           SessionManager.shared().gotoGroup(params.org_id, group.networkId, group.id);
         });

       }

     });

    }
  };
};

class CreateGroupView extends React.Component{

  render() {
    // console.log("CreateVinView:: ",this.props);
    let detail = React.cloneElement(<CreateGroup />, this.props);

    return <ContentLayout detail={detail} /> ;
  }
}

const CreateGroupContainer = connect (mapStateToProps,mapDispatchToProps)(CreateGroupView);
export default CreateGroupContainer;
