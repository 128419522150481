import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import ContentLayout from 'layouts/Content/ContentLayout';
import OrganisationsMain from 'pages/Organisations/OrganisationsMain';
import OrganisationDetail from 'pages/Organisations/OrganisationDetail';
import Loading from 'components/Loading';
import { linkToSelectedOrg } from 'helpers/links';
import { validateFieldElement } from 'helpers/validate';


const mapStateToProps = (state, ownProps) => {

  return {

    organisationList: state.organisations.list,
    selected: state.organisations.selected,
    editingOrg: state.organisations.editingOrg,
    mainLoading: state.organisations.isLoading,
    detailLoading: state.organisations.detailLoading,
    // isAdding: state.organisations.isAdding,
    // detailEditing: state.organisations.detailEditing,
    paramsReplacementId: state.organisations.paramsReplacementId

  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    loadAll: () => {
      SessionManager.shared().loadOrganisations();
    },

    orgSelected: orgId =>{
      SessionManager.shared().gotoOrg(orgId);
    },

    loadOrg: (orgId) => {
      //will load the org from server if it's not in the state already.
      SessionManager.shared().loadOrganisationSingle(orgId);
    },

    detailEdit: (field,value) => {
      dispatch({type:"ORG_DETAIL_FIELD_CHANGE",data:{field:field, value: value}});
    },

    cancelDetailEdit: () => {
      dispatch({ type:"CANCEL_ORGDETAIL_EDIT"});
      dispatch({type:"VALIDATION_CLEAR_TEMPLATE",data:"OrgDetail"});
    },

    newClick: () => {
      SessionManager.shared().goto('/orgs/new');
    },

    updateOrg: info => {
      console.log('update org ',info);
      let req = {
        orgId: info.id,
        orgDetails: info
      };
      console.log('update org ',req);
      SessionManager.shared().updateOrganisation(req).then( result => {
        if (result != null && result.success == true){

          // this will update any changed org names
          SessionManager.shared().checkSessionBackground();

          SessionManager.shared().loadOrganisations().then( result => {
            if (result != null && result.success === true) {
              SessionManager.shared().loadOrganisationSingle(info.id);
            }
          });
        }
      });
    },

    deleteOrganisation: (orgId) => {
      SessionManager.shared().deleteOrganisation(orgId).then(result => {
        if (result != null && result.success === true){
          SessionManager.shared().loadOrganisations().then( result => {
            if (result != null && result.success === true) {
              SessionManager.shared().goto('/orgs');
            }
          });
        }
      });
    },

    validateDeviceLimit: limit => {

      if (limit == null){
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "OrgDetail", field:"deviceLimit.soft", validation:null}});
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "OrgDetail", field:"deviceLimit.hard", validation:null}});
      }else{

        if (limit.soft == null && limit.hard == null){
          dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "OrgDetail", field:"deviceLimit.soft", validation:null}});
          dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "OrgDetail", field:"deviceLimit.hard", validation:null}});
        }else {

          let softValid = validateFieldElement("number", limit.soft);
          let hardValid = validateFieldElement("number", limit.hard);

          if (softValid == null && hardValid == null){
            if (limit.soft > limit.hard){
              softValid = "organisation:error.limitInvalid"
            }
          }

          if (limit.soft === 0){
            softValid = "organisation:error.limitZero"
          }
          if (limit.hard === 0){
            hardValid = "organisation:error.limitZero"
          }

          dispatch({
            type: "VALIDATION_TEMPLATE_FIELD_CHANGE",
            data: { template: "OrgDetail", field: "deviceLimit.soft", validation: softValid }
          });
          dispatch({
            type: "VALIDATION_TEMPLATE_FIELD_CHANGE",
            data: { template: "OrgDetail", field: "deviceLimit.hard", validation: hardValid }
          });
        }
      }
    }

  };
};

class OrganisationsView extends React.Component{

  readParams(props)
  {
    let params = props.match.params;
    let orgId = params.org_id;
    if (orgId == null)
    {
      orgId = props.paramsReplacementId;
    }

    return orgId;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    let orgId = this.readParams(this.props);
    let old_orgId = this.readParams(prevProps);

    if (old_orgId != orgId) {
      if(orgId == null){
        this.props.loadAll();
      }else{
        this.props.loadOrg(orgId);
      }

    }
  }

  componentDidMount() {

    let orgId = this.readParams(this.props);

    if(orgId == null){
      this.props.loadAll();
    }else{
      this.props.loadOrg(orgId);
    }
  }

  render() {
    let main = React.cloneElement(<OrganisationsMain/>, this.props);
    let detail = React.cloneElement(<OrganisationDetail/>, this.props);


    return <ContentLayout main={main} detail={detail} />;
  }

}



const OrganisationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganisationsView);
export default OrganisationsContainer;


