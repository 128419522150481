import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import { Box, Grid, Hidden, Card, CardContent, TextField, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';


class OrganisationsMain extends React.Component{

  constructor(props) {
    super(props);


    // this.count = 0;
    this.rowClick = this.rowClick.bind(this);


    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
  }


  rowClick(org){
    // console.log('OrgMain:: row click ',org);
    this.props.orgSelected(org.id);
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];

    crumbs.push({name: t('sidebarnav:orgs')});
    return crumbs;
  }

  render() {
    // console.log("OOrganisationsMain :: ",this.props);

    // console.log("template: ",templates.getTemplate("Organisation"));

    const { t } = this.props;

    if (this.props.mainLoading === true){
      return <Loading/>;
    }

    let content;

    let noOrgs = this.props.organisationList == null || this.props.organisationList.length === 0;
    if (noOrgs === true) {
      content = "No Orgs, add a new one";
    } else {

      content = <DataDetails
        sizeWithWindow={200}
        template={"OrgTable"}
        data={{orgs:this.props.organisationList}}
        rowClickHandler={this.rowClick}
        object={this.props.selected}
      />;

    }

    let crumbs = this.getBreadCrumbs(t);

    return (
      <Grid container={true} direction="column" className='h-100'>
        <ContentPageHeader breadCrumbs={crumbs} newHandler={this.props.newClick} />
        <Grid item xs>
          {content}
        </Grid>
      </Grid>
    );
  }


}
export default withTranslation(['organisation','common'])(OrganisationsMain);
