import React from 'react';
import {
  Button,
  Fab,
  withTheme, withStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';

const DeleteButton = withStyles((theme) =>({
  containedPrimary:{
    borderRadius:theme.shape.buttonBorderRadius,
    backgroundColor: theme.palette.buttons.delete,
    '&:hover':{
      backgroundColor: theme.palette.buttons.deleteHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.deleteDisabled,
    color:theme.palette.buttons.deleteDisabledText,
  }
}))(Button);

const PrimaryButton = withStyles((theme) =>({
  containedPrimary:{
    borderRadius:theme.shape.buttonBorderRadius,
    backgroundColor: theme.palette.buttons.primary,
    '&:hover':{
      backgroundColor: theme.palette.buttons.primaryHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.disabled,
    // color:theme.palette.buttons.deleteDisabledText,
  }
}))(Button);

const SecondaryButton = withStyles((theme) =>({
  containedPrimary:{
    borderRadius:theme.shape.buttonBorderRadius,
    backgroundColor: theme.palette.buttons.secondary,
    '&:hover':{
      backgroundColor: theme.palette.buttons.secondaryHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.disabled,
    // color:theme.palette.buttons.deleteDisabledText,
  }
}))(Button);

const FabButton = withStyles((theme) => ({
  primary:{
    backgroundColor: theme.palette.buttons.secondary,
    color:'#ffffff',
    '&:hover':{
      backgroundColor: theme.palette.buttons.secondaryHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.FABDisabled,
    color:theme.palette.buttons.FABDisabledText,
  }
}))(Fab);

const SmallButton = withStyles((theme) =>({
  containedPrimary:{
    backgroundColor: theme.palette.buttons.primary,
    borderRadius:theme.shape.buttonBorderRadius,
    fontSize: 10,
    paddingTop:2,
    paddingBottom:2,
    paddingLeft:6,
    paddingRight:6,
    color:'#ffffff',
    '&:hover':{
      backgroundColor: theme.palette.buttons.primaryHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.disabled,
    // color:theme.palette.buttons.deleteDisabledText,
  }
}))(Button);


const GreyButton = withStyles((theme) =>({
  containedPrimary:{
    borderRadius:theme.shape.buttonBorderRadius,
    backgroundColor: theme.palette.buttons.grey,
    color:theme.palette.common.regularText,
    '&:hover':{
      backgroundColor: theme.palette.buttons.greyHover
    },
  },
  disabled:{
    backgroundColor: theme.palette.buttons.disabled,
    // color:theme.palette.buttons.deleteDisabledText,
  }
}))(Button);

class AppButton extends React.Component{


  render() {

    let { buttonType } = this.props;

    // console.log('this.props ',this.props);

    let freshProps = {...this.props};
    if (this.props.size != null && this.props.size === 'small'){
      freshProps = Object.assign(freshProps,{style:{fontSize: 10, paddingTop:2, paddingBottom:2, paddingLeft:6, paddingRight:6}});
      delete freshProps["size"];
    }
    delete freshProps["buttonType"];




    if (buttonType === 'primary'){
      return (
        <PrimaryButton color="primary" variant="contained" {...freshProps}/>
      )
    }

    if (buttonType === 'secondary'){
      return (
        <SecondaryButton color="primary" variant="contained" {...freshProps}/>
      )
    }
    if (buttonType === 'delete') {
      return (
        <DeleteButton color="primary" variant="contained" {...freshProps} />
      )
    }

    if (buttonType === 'fab') {
        return (
          <FabButton color="primary" {...freshProps} />
        )
    }

    if (buttonType === 'small') {
      //freshProps.style = {fontSize:10, padding:2, margin:0};
      return (
        <SmallButton color="primary" variant="contained" {...freshProps}  />
      )
    }

    return (
      <GreyButton color="primary" variant="contained" {...freshProps} />
    )

  }

}

AppButton.propTypes = {
  buttonType: PropTypes.string,
};

AppButton.defaultProps = {
  buttonType: 'grey',
};


export default withTheme(AppButton);
