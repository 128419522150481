/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkDetails,
    NetworkDetailsFromJSON,
    NetworkDetailsFromJSONTyped,
    NetworkDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultListNetworkDetails
 */
export interface RESTResultListNetworkDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultListNetworkDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultListNetworkDetails
     */
    error?: string;
    /**
     * 
     * @type {Array<NetworkDetails>}
     * @memberof RESTResultListNetworkDetails
     */
    data?: Array<NetworkDetails>;
}

export function RESTResultListNetworkDetailsFromJSON(json: any): RESTResultListNetworkDetails {
    return RESTResultListNetworkDetailsFromJSONTyped(json, false);
}

export function RESTResultListNetworkDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultListNetworkDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(NetworkDetailsFromJSON)),
    };
}

export function RESTResultListNetworkDetailsToJSON(value?: RESTResultListNetworkDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(NetworkDetailsToJSON)),
    };
}


