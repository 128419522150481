import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  Hidden,
  IconButton,
  Paper,
  Box,
  Breadcrumbs,
  Typography,
  Fab,
  Dialog,
  Checkbox,
  Tabs,
  Tab,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip,
  FormControl
} from '@material-ui/core';

import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import useTheme from '@material-ui/core/styles/useTheme';
import AppButton from 'components/AppButton';


function ContentPageHeader(props) {


  const clickHandler = crumb => {
    if (crumb.action != null){
      crumb.action(crumb.link);
    }
  };


  let crumbs = [];
  const theme = useTheme();

  if (props.breadCrumbs != null){

    crumbs = props.breadCrumbs.map( (item, i) => {

      if (item.link != null){
        return (
          <Typography
            key={i}
            variant='subtitle1'
            style={{color:theme.palette.primary.main, cursor:'pointer'}}
            onClick={clickHandler.bind(this,item)}
          >
            {item.name}
          </Typography>
        )
      }else{
        return (
          <Typography key={i} variant='subtitle1' style={{color:theme.palette.text.primary, cursor:'default'}} >{item.name}</Typography>
        )
      }
    });
  }

  let boxStyle = {height:64, marginBottom:0, display:'flex', alignItems:'center', justifyContent:'space-between', borderBottom:"1pt black dotted"};
  boxStyle = {...boxStyle, ...props.style}

  return (
    <Fragment>
        <Box
          style={boxStyle}
        >
        <Breadcrumbs>
          {crumbs}
        </Breadcrumbs>
          {props.withAdd ?
          <AppButton
            buttonType="fab"
            onClick={() => {
               if (props.newHandler != null){
                props.newHandler();
               }
             }}
          >
            <AddIcon />
          </AppButton>
            : null }
        </Box>
    </Fragment>
  );
}
const mapStateToProps = state => ({

});

ContentPageHeader.propTypes = {
  breadCrumbs: PropTypes.array,
  newHandler: PropTypes.func,
  withAdd: PropTypes.bool,
  style: PropTypes.object
};

ContentPageHeader.defaultProps = {
  withAdd: true,
  style: {marginBottom:16}
};

export default connect(mapStateToProps)(ContentPageHeader);
