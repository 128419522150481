/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceDetails,
    DeviceDetailsFromJSON,
    DeviceDetailsFromJSONTyped,
    DeviceDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigDetails
 */
export interface ConfigDetails {
    /**
     * 
     * @type {number}
     * @memberof ConfigDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigDetails
     */
    ip?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigDetails
     */
    enable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConfigDetails
     */
    networkId?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigDetails
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigDetails
     */
    orgId?: number;
    /**
     * 
     * @type {DeviceDetails}
     * @memberof ConfigDetails
     */
    device?: DeviceDetails;
    /**
     * 
     * @type {number}
     * @memberof ConfigDetails
     */
    lastSeen?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigDetails
     */
    networkName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigDetails
     */
    groupName?: string;
}

export function ConfigDetailsFromJSON(json: any): ConfigDetails {
    return ConfigDetailsFromJSONTyped(json, false);
}

export function ConfigDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'enable': !exists(json, 'enable') ? undefined : json['enable'],
        'networkId': !exists(json, 'network_id') ? undefined : json['network_id'],
        'groupId': !exists(json, 'group_id') ? undefined : json['group_id'],
        'orgId': !exists(json, 'org_id') ? undefined : json['org_id'],
        'device': !exists(json, 'device') ? undefined : DeviceDetailsFromJSON(json['device']),
        'lastSeen': !exists(json, 'last_seen') ? undefined : json['last_seen'],
        'networkName': !exists(json, 'networkName') ? undefined : json['networkName'],
        'groupName': !exists(json, 'groupName') ? undefined : json['groupName'],
    };
}

export function ConfigDetailsToJSON(value?: ConfigDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ip': value.ip,
        'enable': value.enable,
        'network_id': value.networkId,
        'group_id': value.groupId,
        'org_id': value.orgId,
        'device': DeviceDetailsToJSON(value.device),
        'last_seen': value.lastSeen,
        'networkName': value.networkName,
        'groupName': value.groupName,
    };
}


