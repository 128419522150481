import React, { useEffect } from 'react';
import {Snackbar, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import SessionManager from 'session/SessionManager';
import { useTranslation } from 'react-i18next';

const AppSnackbarAlert = props => {

  //console.log("AppSnackbarError:: ", props);
  //console.log("  AppSnackbarError:: ", props.alert);
  const { t } = useTranslation(["snack", 'vinsgroups', 'user', 'auth', 'organisation', 'search']);
  useEffect(() => {
    SessionManager.shared().setSnackTranslator(t);
  })
  if (props.alert == null){
    return null;
  }

  setTimeout(() => {
    //console.log('  snacktimeout ',props.alert);
    if(props.alert != null) {
      props.alertClose(props.alert);
    }
  },5000);

  return (
    <Snackbar
      open={props.show}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      onClose={() => {
        //console.log("  snack on close: ",props.alert);
        props.alertClose(props.alert)
      }}
    >

      <Alert severity={props.alert.type}
             action={
              <IconButton size="small" color='inherit' onClick={() => props.alertClose(props.alert)}>
                <CloseIcon fontSize="small" />
              </IconButton>
             }
      >
        {props.alert.message}
      </Alert>

    </Snackbar>
  );
};

const mapStateToProps = state => ({
  alert: state.appAlert.current,
  show: state.appAlert.show,
});

const mapDispatchToProps = dispatch => ({
  alertClose: msg => dispatch( { type:"CLEAR_APP_ALERT", data:msg })
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackbarAlert);


