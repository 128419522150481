/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InviteDetails,
    InviteDetailsFromJSON,
    InviteDetailsFromJSONTyped,
    InviteDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface GroupDetails
 */
export interface GroupDetails {
    /**
     * 
     * @type {number}
     * @memberof GroupDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDetails
     */
    eref?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupDetails
     */
    networkId?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupDetails
     */
    meshType?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupDetails
     */
    deviceCount?: number;
    /**
     * 
     * @type {Array<InviteDetails>}
     * @memberof GroupDetails
     */
    invites?: Array<InviteDetails>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GroupDetails
     */
    connectionIds?: Array<number>;
}

export function GroupDetailsFromJSON(json: any): GroupDetails {
    return GroupDetailsFromJSONTyped(json, false);
}

export function GroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'eref': !exists(json, 'eref') ? undefined : json['eref'],
        'networkId': !exists(json, 'networkId') ? undefined : json['networkId'],
        'meshType': !exists(json, 'mesh_type') ? undefined : json['mesh_type'],
        'deviceCount': !exists(json, 'deviceCount') ? undefined : json['deviceCount'],
        'invites': !exists(json, 'invites') ? undefined : ((json['invites'] as Array<any>).map(InviteDetailsFromJSON)),
        'connectionIds': !exists(json, 'connectionIds') ? undefined : json['connectionIds'],
    };
}

export function GroupDetailsToJSON(value?: GroupDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'eref': value.eref,
        'networkId': value.networkId,
        'mesh_type': value.meshType,
        'deviceCount': value.deviceCount,
        'invites': value.invites === undefined ? undefined : ((value.invites as Array<any>).map(InviteDetailsToJSON)),
        'connectionIds': value.connectionIds,
    };
}


