import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';

const NothingSelectedTitle = (props) => {
  const theme = useTheme();
  return (
    <Box className="d-flex align-items-center" style={{height: 80, marginBottom: 0}}>
      <Box className="d-flex justify-content-center align-items-center" style={{textAlign:"center", width:"100%"}}>
        <Typography style={{color: theme.palette.common.disabledText}} variant={'caption'}>{props.children}</Typography>
      </Box>
    </Box>
  );
};

export default NothingSelectedTitle;
