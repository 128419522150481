import React, { useEffect } from 'react';
import SessionManager from 'session/SessionManager';
import LoadingFullPage from 'components/LoadingFullPage';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InitialiseContainer from 'pages/Initialise/InitialiseContainer';

class SessionCheck extends React.PureComponent{

  constructor(props) {
    super(props);
    this.lastPath = null;
    this.state = {initialised: false}

    this.checkSession = this.checkSession.bind(this);
  }


  checkSession()
  {
    SessionManager.shared().checkSession((result) => {

      console.log("SESSION CHECK ", result);
      this.setState({initialised:true});
      if(result.success === true){

        if(this.lastPath !== '/dashboard' && this.lastPath !== '/login'){
          //takes us to the path that started load. Ignore if it was dashboard or login.
          SessionManager.shared().goto(this.lastPath);
        } else {
          SessionManager.shared().goto('/dashboard');
        }
      }
      else if (result.status==406 || result.error == "needs_init")
      {
        SessionManager.shared().goto('/init');
      }
      else
      {
        this.props.sessionFailed();
        SessionManager.shared().goto('/login');
      }
    });
  }

  componentDidMount() {
    //console.log("================== SessionCheck::  component did mount", this.props.location, window.location);
    this.lastPath = this.props.location.pathname;

    console.log("SessionCheck Mount : ", this.props);
    SessionManager.shared().setHistory(this.props.history);

    this.checkSession();

  }

  render(){

    if (this.state.initialised !== true) {
      return <LoadingFullPage/>
    }

    // console.log("SessionCheck:: render ",this.props);
    // console.log("SessionCheck:: render ",this.props.location);
    if (this.props.isInitialised === false)
    {
      return <InitialiseContainer/>
    }

    if (this.props.isLoading === true) {
      return <LoadingFullPage/>
    }

    return (
        <>
          {this.props.children}
        </>
    )

  }

}

const mapStateToProps = (state, ownProps) => {

  return {
    isLoading: state.session.isLoading,
    isAuthenticated: state.session.isAuthenticated,
  };

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sessionFailed : () => {
      dispatch({ type: 'AUTH_LOGOUT' });
    }
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SessionCheck));
