/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceDetails
 */
export interface DeviceDetails {
    /**
     * 
     * @type {number}
     * @memberof DeviceDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetails
     */
    fqdn?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceDetails
     */
    os?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceDetails
     */
    mtu?: number;
}

export function DeviceDetailsFromJSON(json: any): DeviceDetails {
    return DeviceDetailsFromJSONTyped(json, false);
}

export function DeviceDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fqdn': !exists(json, 'fqdn') ? undefined : json['fqdn'],
        'os': !exists(json, 'os') ? undefined : json['os'],
        'mtu': !exists(json, 'mtu') ? undefined : json['mtu'],
    };
}

export function DeviceDetailsToJSON(value?: DeviceDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fqdn': value.fqdn,
        'os': value.os,
        'mtu': value.mtu,
    };
}


