/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroupDetails,
    GroupDetailsFromJSON,
    GroupDetailsFromJSONTyped,
    GroupDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultGroupDetails
 */
export interface RESTResultGroupDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultGroupDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultGroupDetails
     */
    error?: string;
    /**
     * 
     * @type {GroupDetails}
     * @memberof RESTResultGroupDetails
     */
    data?: GroupDetails;
}

export function RESTResultGroupDetailsFromJSON(json: any): RESTResultGroupDetails {
    return RESTResultGroupDetailsFromJSONTyped(json, false);
}

export function RESTResultGroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultGroupDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : GroupDetailsFromJSON(json['data']),
    };
}

export function RESTResultGroupDetailsToJSON(value?: RESTResultGroupDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': GroupDetailsToJSON(value.data),
    };
}


