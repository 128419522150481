import {
  AuthenticateApi,
  CreateNetworkRequest,
  DoSearchRequest,
  UpdateGroupRequest,
  NetworkApi,
  NetworkGroupApi,
  NetworkDeviceApi,
  OrganisationApi,
  SearchApi,
  UserApi,
  NetworkGroupConnectionApi,
  SetNetworkConnectionsRequest,
  PostUserRequest,
  PutUserRequest,
  InitialiseApi,
  PutOrganisationRequest, LicenseApi
} from 'api/apis';
import {snackAlertError, snackAlertSuccess} from '../helpers/alerting';
import { VINActionTab } from 'helpers/constants';

import {
  Configuration,
  GroupDetails,
  NewGroupDetails, NewNetworkDetails,
  Organisation, RESTResultBoolean,
  RESTResultGroupDetails,
  RESTResultListNetworkDetails, SearchRequestTypeEnum
} from '../api';
import * as runtime from '../api/runtime';
import links from 'helpers/links';

let staticSessionManager: SessionManager;

class SessionManager extends Object {
  store: any;
  authApi: AuthenticateApi;
  orgApi: OrganisationApi;
  userApi: UserApi;
  vinAPI: NetworkApi;
  groupApi: NetworkGroupApi;
  deviceApi: NetworkDeviceApi;
  searchApi: SearchApi;
  groupLinkApi: NetworkGroupConnectionApi;
  initialiseApi: InitialiseApi;
  licenseApi: LicenseApi
  history: any;
  t : any;

  constructor(store: any) {
    super();

    this.store = store;

    staticSessionManager = this;

    let config = new Configuration({
      basePath: '/vin-admin/rest'
    });

    this.authApi = new AuthenticateApi(config);
    this.orgApi = new OrganisationApi(config);
    this.userApi = new UserApi(config);
    this.vinAPI = new NetworkApi(config);
    this.groupApi = new NetworkGroupApi(config);
    this.deviceApi = new NetworkDeviceApi(config);
    this.searchApi = new SearchApi(config);
    this.groupLinkApi = new NetworkGroupConnectionApi(config);
    this.initialiseApi = new InitialiseApi(config);
    this.licenseApi = new LicenseApi(config);
    this.history = null;

    console.log('SessionManager:: store State:', store.getState());

    setTimeout(this.timerCheck.bind(this), 30000);
  }

  getState(){
    return this.store.getState();
  }

  setSnackTranslator(t : any)
  {
    console.log("Setting Tranbslator", t);
    this.t = t;
  }

  setHistory(history: object): void {
    console.log("Setting History : ", history);
    this.history = history;
  }

  snackAlertSuccess(stringIdentifier : string, args? : object | null)
  {
    let message = this.t(stringIdentifier, args);
    this.store.dispatch({ type: "ALERT_APP_SUCCESS", data: message });
  }

  snackAlertError(stringIdentifier : string, args? : object | null)
  {
    console.log("Looking up ", stringIdentifier, args);
    let argsFiltered = {error:""};
    // @ts-ignore
    if (args != null && typeof args === 'object' && args.error != null) {
      // @ts-ignore
      argsFiltered.error = this.t(args.error);
    }
    let message = this.t(stringIdentifier, argsFiltered);
    console.log("Looking up = ", message);
    this.store.dispatch({ type: "ALERT_APP_ERROR", data: message });
  }

  snackAlertInfo(stringIdentifier : string, args? : object | null)
  {
    let message = this.t(stringIdentifier, args);
    this.store.dispatch({ type: "ALERT_APP_INFO", data: message });
  }

  snackAlertWarning(stringIdentifier : string, args? : object | null)
  {
    let message = this.t(stringIdentifier, args);
    this.store.dispatch({ type: "ALERT_APP_WARNING", data: message });
  }

  goto(link : string)
  {
    // push if the link will be different
    console.log("Going to " + link);
    if (this.history.location.pathname != link)
    {
      this.history.push(link);
    }
  }

  gotoOrg(orgId : string)
  {
    let link = "/orgs";
    this.store.dispatch({type:"SELECT_ORG_ID", selectId:orgId});

    // push if the link will be different
    if (this.history.location.pathname != link)
    {
      this.history.push(link);
    }
  }


  gotoUser(userId : string)
  {
    let link = "/users";
    this.store.dispatch({type:"SELECT_USER_ID", selectId:userId});

    // push if the link will be different
    if (this.history.location.pathname != link)
    {
      this.history.push(link);
    }

  }
  gotoVIN(orgId: string, vinId: number) {

    this.gotoVinTab(orgId, vinId, null, null);
  }

  /**
   * Goto a VIN Tab and optionally select an item on that tab.
   *
   * If type is null this will just go to the vin list. optionally selecting a VIN
   * @param orgId
   * @param vinId
   * @param type
   * @param itemId
   */
  gotoVinTab(orgId: string, vinId: number, type : string | null, itemId : string | null) {
    this.store.dispatch({ type: "VIN_TAB_SELECTED", data: type });

    let tab_name = null;
    let select_type = null;
    if (type == VINActionTab.GROUPS) {
      tab_name = "group";
      select_type = "group";
    } else if (type == VINActionTab.TOPOLOGY){
      tab_name = "topology";
      select_type = "group";
    }else if (type == VINActionTab.DEVICES){
      tab_name = "device";
      select_type = "device";
    }else if (type == VINActionTab.PERFORMANCE) {
      tab_name = "performance";
    }else if (type == VINActionTab.AUDIT) {
      tab_name = "audit";
    }else{
      select_type = "vin";
      itemId = vinId.toString();
    }
    let link = '/vins/org/' + orgId;

    if (tab_name != null)
    {
      link += "/vin/" + vinId + "/tab/" + tab_name
      this.store.dispatch({ type:"VIN_TAB_SELECTED", data: type});
    }
    else
    {
      this.store.dispatch({ type:"VIN_TAB_SELECTED", data: null});
    }

    if (itemId != null)
    {
      this.store.dispatch({type:"SET_PARAM_REPLACEMENT", selectType:select_type, selectId:itemId});
    }
    else
    {
      this.store.dispatch({type:"CLEAR_PARAM_REPLACEMENT"});
    }

    // push if the link will be different
    if (this.history.location.pathname != link)
    {
      this.history.push(link);
    }

  }

  gotoGroup(orgId : string, vinId : number, groupId: number | null)
  {
    this.gotoVinTab(orgId, vinId, VINActionTab.GROUPS, groupId==null ? null : groupId.toString());
  }

  gotoDevice(orgId : string, vinId : number, deviceId: number | null)
  {
    this.gotoVinTab(orgId, vinId, VINActionTab.DEVICES, deviceId==null ? null : deviceId.toString());
  }

  timerCheck()
  {
    try
    {
      if (this.isAuthenticated())
      {
        this.checkSessionBackground();
      }
    }
    catch (e)
    {
      console.log(e);
    }
    setTimeout(this.timerCheck.bind(this), 30000);
  }

  checkUnauthorised(error: any){
    if(error == null){
      return;
    }
    if(error.status != null){
      if (error.status === 401){
        window.location.assign("/");
      }
    }
  }

  static shared() {
    return staticSessionManager;
  }

  checkSessionBackground()
  {
    console.log('----- Check Session');

    this.authApi
      .checkSession()
      .then(result => {
        console.log('chck result: ', result);
        if (result.success)
        {
          this.store.dispatch({ type: 'CHECK_SESSION_SUCCESS', data: result.data });
        } else {
          this.store.dispatch({type: 'CHECK_SESSION_NO_SUCCESS'});
        }
      })
      .catch(reason => {
        console.log('checksession error ', reason);
        if (reason.status == 406) {
          // not initialised
          this.store.dispatch({type: 'SET_INITIALISE_OFF'});
        } else {
          this.store.dispatch({ type: 'AUTH_ERROR', data: reason.data });
        }
      });

  }

  checkSession(callback: any) {
    console.log('----- Check Session');

    this.store.dispatch({ type: 'CHECK_SESSION_START'});

    this.authApi
      .checkSession()
      .then(result => {
        console.log('chck result: ', result);
        if (result.success)
        {
          this.store.dispatch({ type: 'CHECK_SESSION_SUCCESS', data: result.data });
        } else {
          this.store.dispatch({type: 'CHECK_SESSION_NO_SUCCESS'});
        }

        if (callback != null){
          callback(result);
        }
      })
      .catch(reason => {
        console.log('checksession error ', reason);
        if (reason.status == 406) {
          // not initialised
          console.log('Not Initialised Caught', reason);
          this.store.dispatch({type: 'SET_INITIALISE_OFF'});
          callback({success:false, error:"needs_init"});
        } else {
          // try again
          //this.checkSession(callback);
          this.store.dispatch({ type: 'AUTH_ERROR', data: reason.data });
        }
      });
  }

  getTopLevelRoutes(){
    let state = this.store.getState();
    let site = state.session.sessionData;

    let standard = ["/dashboard","/vins"];

    if (site == null){
      return standard;
    }else{

      if (site.admin === true){
        return standard.concat(["/orgs","/users","/certificates","/licences","/settings"]);
      }

      return standard;
    }
  }

  getSideBarLinks(){
    let state = this.store.getState();
    let site = state.session.sessionData;

    if (site == null){
      return []
    }
    if (site.admin === true){
      return ['/dashboard','/vins/org/all','/orgs','/users','/certificates','/licences','/settings'];
    }
    return ['/dashboard','/vins/org/all'];
  }

  userCan(accessType: string, objectType: string){
    let state = this.store.getState();
    let site = state.session.sessionData;

    if (site == null){
      return false;
    }
    if (site.admin === true){
      return true;
    }
    let access = state.session.access[accessType];
    if (access != null) {
      return access.includes(objectType);
    }
    return false;
  }

  userAllowedAdd(type: string){
    return this.userCan('add',type);
  }

  userAllowedEdit(type: string){
    return this.userCan('edit',type);
  }

  userAllowedDelete(type: string){
    return this.userCan('delete',type);
  }

  isAuthenticated() {
    let sessionState = this.store.getState().session;
    return sessionState.isAuthenticated;
  }

  authenticate(username: string, password: string, callback: any) {
    console.log(
      'SessionManager:: authenticate: ' + username + ' | ' + password
    );

    this.authApi
      .authenticatePost({
        credentials: { username: username, password: password }
      })
      .then(result => {
        console.log('reuslt: ', result);
        if (result.success)
        {
          this.store.dispatch({ type: 'AUTH_SUCCESS', data: result.data });
        } else {
          this.store.dispatch({ type: 'AUTH_NO_SUCCESS', data: result.error });
        }

        if(callback != null){
          try {
            callback(result);
          }catch (error) {
            console.log('error', error);
          }
        }

      })
      .catch(reason => {
        console.log('authenticate error ', reason);
        this.store.dispatch({ type: 'AUTH_ERROR', data: reason.data });
      });
  }

  logoutSession(history: any){
    this.authApi.logoutPost().then(result => {
      console.log("logout result: ",result);
      this.store.dispatch({ type: 'AUTH_LOGOUT' });
      if(history != null && history.push != null){
        history.push('/');
      }
    }).catch(reason => {
      console.log('logout error: ',reason);
    });
  }

  initialiseCheck()
  {
    return this.initialiseApi.initialiseCheck()
      .then(result => {

        if (result.success === true) {
          if (result.data == "complete")
          {
            this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"complete"});

            // @ts-ignore
            window.location = "/";
          }
          else
          {
            this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:result.data});
          }
        }
        else
        {
          this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"error", error:result.error});
        }
        return result;

      })
      .catch(reason => {
        console.log("initialise error ", reason);
        this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"error", error:"initialise:error.servererror"});
      });

  }

  initialise(username: string, password: string, organisation: string, licenseKey: string)
  {
    let info = {initialiseDetails:{username:username, email:username, password:password, orgname:organisation, licenseKey:licenseKey}};
    this.initialiseApi.initialise(info)
      .then(result => {

        if (result.success === true) {
          this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"waiting"});
        }
        else
        {
          this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"error", error:result.error});
        }
        return result;

      })
      .catch(reason => {
        console.log("initialise error ", reason);
        this.store.dispatch({ type: "SET_INITIALISE_STATUS", value:"error", error:"initialise:error.servererror"});
      });

  }


  /*
  ===== Organisation Calls ======
   */

  loadOrganisations(){
    this.store.dispatch({ type: "LOAD_ORGS_START" });

      return this.orgApi.getAll()
        .then(result => {

          if (result.success === true) {
            this.store.dispatch({ type: "LOAD_ORGS_FINISHED", data: result.data });
          } else {
            this.store.dispatch({ type: "ORG_LOAD_ERROR", data: result.error });
          }

          return result;

        })
        .catch(reason => {
          console.log("loadOrganisations error ", reason);
          this.checkUnauthorised(reason);
          this.store.dispatch({type:"ORG_LOAD_ERROR"});
          this.snackAlertError("snack:loadfailed", {error:reason.statusText})
        });
  }

  loadOrganisationSingle(orgid: number){

    let orgId = Number(orgid);
    let state = this.store.getState();
    let orgs = state.organisations.list;
    let select;

    if (orgs == null){

      this.loadOrganisations().then(result => {
        if (result != null && result.success === true && result.data != null){
          this.loadOrganisationSingle(orgId);
        }
      });

    }else {
      for (const i in orgs) {
        let org = orgs[i];
        if (org.id === orgId) {
          select = org;
          break;
        }
      }
      if (select != null) {
        this.store.dispatch({ type: "SELECT_ORGANISATION", data: select });
      } else {
        this.store.dispatch({ type: "LOAD_SELECTED_ORG_START" });

        this.orgApi.getOrganisation({ orgId: orgId }).then(result => {
          if (result.success === true) {
            this.store.dispatch({ type: "LOAD_SELECTED_ORG_FINISHED", data: result.data });
          } else {
            this.store.dispatch({ type: "ORG_LOAD_ERROR", data: result.error });
          }
        }).catch(reason => {
          console.log("loadOrganisationSingle error ", reason);
          this.checkUnauthorised(reason);
          this.store.dispatch({ type: "ORG_LOAD_ERROR" });
          this.snackAlertError("snack:loadfailed", {error:reason.statusText})
        });
      }
    }
  }

  saveOrganisation(info: Organisation){
    console.log("SessionManager:: save organsiation ", info);
    this.store.dispatch({type:"SAVE_NEW_ORG_START"})

    return this.orgApi.postOrganisation({ orgDetails: info }).then( result => {

      if (result.success === true){
        this.snackAlertSuccess("snack:addedorg", {name: info.name});
        this.store.dispatch({type:"SAVE_NEW_ORG_FINISHED"});
        this.loadOrganisations();
        this.checkSessionBackground();

      } else {
        this.store.dispatch({type:"SAVE_NEW_ORG_ERROR"});
        this.snackAlertError("snack:savefailed", {error:result.error})
      }

      return result;

    }).catch(reason => {
      console.log("saveOrganisation error ", reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({type:"ORG_LOAD_ERROR"});
      this.snackAlertError("snack:savefailed", {error:reason.statusText})
    })
  }

  updateOrganisation(req: PutOrganisationRequest){
    this.store.dispatch({type:"ORG_DATA_LOADING_START"});
    return this.orgApi.putOrganisation(req).then(result => {
      if (result.success === true){
        this.snackAlertSuccess("snack:updatedorg");
        this.store.dispatch({type:"ORG_DATA_LOADING_FINISHED"});
      } else {
        this.store.dispatch({type:"ORG_LOAD_ERROR"});
        this.snackAlertError("snack:savefailed", {error:result.error})
      }

      return result;
    }).catch( reason => {
      console.log("updateOrganisation error ", reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({type:"ORG_LOAD_ERROR"});
      this.snackAlertError("snack:updatefailed", {error:reason.statusText})

    });
  }

  deleteOrganisation(orgId: number){

    this.store.dispatch({type:"ORG_DATA_LOADING_START"});
    return this.orgApi.deleteOrganisation({orgId: orgId}).then(result => {

      if (result.success === true){
        this.snackAlertSuccess("snack:deletedorg");
        this.store.dispatch({type:"ORG_DATA_LOADING_FINISHED"});
        this.checkSessionBackground();

      } else {
        this.store.dispatch({type:"ORG_LOAD_ERROR"});
        this.snackAlertError("snack:deletefailed", {error:result.error})
      }

      return result;

    }).catch(reason => {
      console.log("updateOrganisation error ", reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({type:"ORG_LOAD_ERROR"});
      this.snackAlertError("snack:deletefailed", {error:reason.statusText})
    });

  }


  /*
  ===== User Calls ======
   */

  loadUsers(){
    this.store.dispatch({type: "LOAD_USERS_START"});

    return this.userApi.getAll1()
      .then(result => {
        if (result.success === true) {
          this.store.dispatch({ type: "LOAD_USERS_FINISHED", data: result.data})
        }else{
          this.store.dispatch({type: "USER_LOAD_ERROR", data: result.error});
        }
        return result;

      })
      .catch(reason => {
        console.log("loadUsers error ", reason);
        this.checkUnauthorised(reason);
        this.snackAlertError("snack:loadfailed", {error:reason.statusText})
      });
  }

  loadUser(userId: number){

    let state = this.store.getState();
    let users = state.users.list;
    // console.log('users ',users);

    if (users == null){

      this.loadUsers().then(result => {
        if (result != null && result.success === true && result.data != null){
          this.loadUser(userId);
        }
      });

    }else {

      let user;
      for (const i in users) {
        let u = users[i];
        if (u.id === userId) {
          user = u;
          break;
        }
      }

      // console.log('user: ', user);

      if (user == null) {
        this.store.dispatch({ type: 'LOAD_SELECTED_USER_START' });
        this.userApi.getUser({ userId: userId }).then(result => {

          if (result.success === true) {
            this.store.dispatch({ type: 'LOAD_SELECTED_USER_FINISHED', data: result.data });
          } else {
            this.snackAlertError("snack:loadfailed", {error:result.error});
          }

        }).catch(reason => {
          console.log('loadUser() error ', reason);
          this.snackAlertError("snack:loadfailed", {error:reason.statusText});
        });
      } else {
        this.store.dispatch({ type: "SELECT_USER", data: user })
      }
    }
  }

  createUser(req: PostUserRequest){
    console.log("SessionManager:: createuser ",req);
    this.store.dispatch({ type: "USER_DATA_LOADING_START"});
    return this.userApi.postUser(req).then(result => {

      if (result.success === true){
        console.log('createUser result ',result);
      }else{
        this.store.dispatch({ type: "USER_LOAD_ERROR", data:result.error });
        this.snackAlertError("snack:createfailed", {error:result.error});
      }

      return result;

    }).catch(reason => {
      console.log("createUser() error ",reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({ type: "USER_LOAD_ERROR", data:reason.statusText });
      this.snackAlertError("snack:loadfailed", {error:reason.statusText})
    });
  }

  deleteUser(userId: number){
    this.store.dispatch({ type: "USER_DATA_LOADING_START"});
    return this.userApi.deleteUser({userId: userId}).then(result => {
      if (result.success === true){
        console.log('deleteUser result ',result);
      }else {
        this.store.dispatch({ type: "USER_LOAD_ERROR", data: result.error });
        this.snackAlertError("snack:deletefailed", { error: result.error });
      }

        return result;

    }).catch(reason => {
      console.log("deleteUser() error ",reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({ type: "USER_LOAD_ERROR", data:reason.statusText });
      this.snackAlertError("snack:deletefailed", {error:reason.statusText})
    });
  }

  updateUser(req: PutUserRequest){

    this.store.dispatch({ type: "USER_DATA_LOADING_START"});
    return this.userApi.putUser(req).then(result => {

      if (result.success === true){
        console.log('updateUser result ',result);
      }else{
        this.store.dispatch({ type: "USER_LOAD_ERROR", data:result.error });
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch(reason => {
      console.log("updateUser() error ",reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({ type: "USER_LOAD_ERROR", data:reason.statusText });
      this.snackAlertError("snack:updatefailed", {error:reason.statusText})
    });

  }

  /*
  ===== VIN/Network Calls ======
   */

  loadVins(){
    this.store.dispatch({type: "LOAD_ALL_VINS_START"});
    return this.vinAPI.getAllNetworks().then(result  => {

      console.log(" ------ getAllNetworks");
      if (result.success === true){
        console.log(" ------ LOAD_ALL_VINS_FINISHED");
        this.store.dispatch({ type: "LOAD_ALL_VINS_FINISHED", data: result.data})
      } else {
        console.log(" ------ VINS_LOAD_ERROR");
        this.store.dispatch({ type: "VINS_LOAD_ERROR", data:result.error });
      }
      return result;

    }).catch(reason => {
      console.log("loadVins() error ",reason);
      this.checkUnauthorised(reason);
      this.snackAlertError("snack:loadfailed", {error:reason.statusText})
    });
  }

  loadVinsForOrganisation(orgId: number){
    this.store.dispatch({type: "LOAD_ORG_VINS_START"});

    return this.vinAPI.getOrgNetworks({orgId: orgId}).then(result => {
      if (result.success === true){
        this.store.dispatch({ type: "LOAD_ORG_VINS_FINISHED", data: result.data})
      } else {
        this.store.dispatch({ type: "VINS_LOAD_ERROR", data:result.error });
      }

      return result;

    }).catch(reason => {
      console.log("loadloadVinsForOrganisation() error ",reason);
      this.checkUnauthorised(reason);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:reason.statusText });
      this.snackAlertError("snack:loadfailed", {error:reason.statusText})
    })
  }

  /**
   * handles the VINs once loaded, could be loaded from all vins or org vin call
   *
   * @param vinId
   * @param groupId
   * @param deviceId
   * @param tab
   * @param result
   */
  loadVinHandler(vinId : number, groupId : any, deviceId : any, tab : any, result : any) {

    if('success' in result && result.success === true)
    {
      if (vinId != null)
      {
        this.loadVinDetail(vinId);
      }

      if(tab == VINActionTab.GROUPS || tab == VINActionTab.TOPOLOGY)
      {
        this.loadGroupDetail(vinId, groupId);
      }
      else if (tab == VINActionTab.DEVICES)
      {
        this.loadDeviceDetail(vinId, deviceId);
      }

      this.store.dispatch({type: "VIN_TAB_SELECTED", data:tab});

    }
  }

  loadToVinGroupDevice(orgId: any, vinId: number, groupId: any, deviceId: any, tab: any){

    let state = this.store.getState();
    console.log('load to vin group device ',{org:orgId,vin:vinId,group:groupId,device:deviceId});

    let orgVins = state.vinsgroups.orgVins;
    // console.log('orgVins: ',orgVins);

    //nothing has been loaded so load everything.
    if (orgVins == null){
      this.store.dispatch({type: "LOAD_ORG_VINS_START"});

      if (orgId === 'all' || orgId === null)
      {
        this.loadVins().then((result) => this.loadVinHandler(vinId, groupId, deviceId, tab, result));
      }
      else
      {
        console.log('nothing loaded - ');
        this.loadVinsForOrganisation(Number(orgId)).then((result) => this.loadVinHandler(vinId, groupId, deviceId, tab, result));
      }
    }
    else
    {
      this.loadVinDetail(vinId);
      console.log("Loading VIN = ", vinId);

      if(tab == VINActionTab.GROUPS || tab == VINActionTab.TOPOLOGY)
      {
        this.loadGroupDetail(vinId, groupId);
      }
      else if (tab == VINActionTab.DEVICES)
      {
        this.loadDeviceDetail(vinId, deviceId);
      }
      this.store.dispatch({type: "VIN_TAB_SELECTED", data:tab});
    }

  }

  loadVinDetail(vinId: number){
    this.store.dispatch({type: "LOAD_VIN_DETAIL", data:Number(vinId)});
  }

  loadVinGroups(vinId: number){
    this.store.dispatch({type: "LOAD_VIN_GROUPS_START"});

    return this.vinAPI.getNetworkDetails( {vinId: vinId } ).then(result => {
      if (result.success === true) {
        this.store.dispatch({ type: "LOAD_VIN_GROUPS_FINISHED", data: result.data });
      }else{
        this.store.dispatch({ type: "VINS_LOAD_ERROR", data:result.error });
      }

      return result;

    }).catch( error => {
      console.log("loadVinDetail() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });
  }

  loadGroupDetail(vinId : number, groupId: string)
  {
    console.log("Load VIN Groups", vinId, groupId);
    let state = this.store.getState();

    let vinGroups = state.vinsgroups.vinGroups;
    let vinDevices = state.vinsgroups.vinDevices;

    // make sure the group hasn't changed. if vin devices are loaded, this could mean that the groups are just the ones used by the vin devices
    if(vinDevices != null || vinGroups == null || (vinGroups.length > 0 && vinGroups[0].networkId != vinId))
    {
      this.loadVinGroups(Number(vinId)).then((result : any) => {

        if(result != null && 'success' in result && result.success === true){
          if(groupId !== 'all' && groupId != null)
          {
            this.store.dispatch({type: "LOAD_GROUP_DETAIL", data:Number(groupId)});
          }
        }
      });
    }
    else if (groupId !== 'all' && groupId != null)
    {
      this.store.dispatch({type: "LOAD_GROUP_DETAIL", data:Number(groupId)});
    }
    else
    {
      console.log("Load VIN Groups : Nothing to load");
    }
  }

  createNewVinAndGroup(info : CreateNetworkRequest){
    this.store.dispatch({type: "CREATE_VIN_AND_GROUP_START"});

    return this.vinAPI.createNetwork(info).then(result => {

      console.log('createNewVinAndGroup result ',result);

      if(result.success === true){
        this.store.dispatch({ type: "CREATE_VIN_AND_GROUP_FINISHED", data:result.data});
        this.snackAlertSuccess("snack:createdvin", {name:info.newNetworkDetails!.name});
      }else{
        this.store.dispatch({ type: "VINS_LOAD_ERROR", data:result.error });
        this.snackAlertError("snack:createfailed", {error:result.error});
      }

      return result;

    }).catch(error => {
      console.log("createNewVinAndGroup() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });

  }

  createNewGroup(vinId: number, group: NewGroupDetails){
    this.store.dispatch({type: "CREATE_GROUP_START"});

    return this.groupApi.createGroup({vinId: vinId, newGroupDetails: group}).then( (result:RESTResultGroupDetails )  => {

      console.log('createNewGroup result ',result);

      if (result.success === true){
        snackAlertSuccess("snack:createdgroup");
        this.store.dispatch({type: "CREATE_GROUP_FINISHED", group:result.data});
      }else{
        this.store.dispatch({ type: "VINS_LOAD_ERROR", data:result.error });
        this.snackAlertError("snack:createfailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("createNewGroup() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:savefailed", {error:error.statusText})
    });
  }

  deleteVin(vinId: number){
    this.store.dispatch({type: "DELETE_VIN_START"});

    return this.vinAPI.deleteNetwork({vinId: vinId}).then( result => {
      if (result.success === true){
        this.store.dispatch({type: "DELETE_VIN_FINISHED", vinId:vinId});
      }else{
        this.store.dispatch({type: "VINS_LOAD_ERROR"});
        this.snackAlertError("snack:deletefailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("deleteVin() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:deletefailed", {error:error.statusText})
    });
  }


  deleteGroupFromVin(vinId: number, groupId: number){

    this.store.dispatch({type: "DELETE_VIN_GROUP_START"});

    return this.groupApi.deleteGroup({vinId: vinId, groupId:groupId}).then( result => {
      if (result.success === true){
        this.store.dispatch({type: "DELETE_VIN_GROUP_FINISHED", vinId: vinId, groupId:groupId});
      }else{
        this.store.dispatch({type: "VINS_LOAD_ERROR"});
        this.snackAlertError("snack:deletefailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("deleteGroupFromVin() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:deletefailed", {error:error.statusText})
    });
  }

  updateVin(vinId: number, updates: NewNetworkDetails){
    this.store.dispatch({type:"VINMANAGE_DATA_SAVING"});
    return this.vinAPI.updateNetwork({vinId:vinId, newNetworkDetails: updates}).then(result =>{
      if (result.success === true){
        this.store.dispatch({type:"VINMANAGE_DATA_SAVING_FINISHED", dataType:"vin", vin:result.data});
        //this.store.dispatch({type:"UPDATE_VIN_DETAILS_FINISHED",data: result.data});
      }else{
        this.store.dispatch({type: "VINS_SAVING_ERROR"});
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch(error => {
      console.log("updateVin() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });
  }

  regenerateInvite(vinId: number, groupId: number) {
    this.store.dispatch({type:"VINMANAGE_SAVING_LOADING"});

    return this.groupApi.regenerateInvite({vinId: vinId, groupId:groupId}).then( result => {
      if (result.success === true){
        this.store.dispatch({type:"VINMANAGE_DATA_SAVING_FINISHED", dataType:"invite", group:result.data});
      }else{
        this.store.dispatch({type: "VINS_SAVING_ERROR"});
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("regenerateInvite() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:updatefailed", {error:error.statusText})
    });

  }

  updateDevice(vinId : number, deviceId : number, groupId: number)
  {
    this.store.dispatch({type:"VINMANAGE_DATA_SAVING"});
    return this.deviceApi.moveDevice({vinId:vinId, deviceId:deviceId, groupId:groupId}).then(result => {
      if (result.success === true){
        this.store.dispatch({type:"VINMANAGE_DATA_SAVING_FINISHED", dataType:"device", device:result.data});
      }else{
        this.store.dispatch({type: "VINS_SAVING_ERROR"});
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch(error =>{
      console.log("updateDevice() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:updatefailed", {error:error.statusText})
    });
  }

  updateGroup(req: UpdateGroupRequest){

    this.store.dispatch({type:"VINMANAGE_DATA_SAVING"});
    return this.groupApi.updateGroup(req).then(result => {
      if (result.success === true){
        this.store.dispatch({type:"VINMANAGE_DATA_SAVING_FINISHED", dataType:"group", group:result.data});
      }else{
        this.store.dispatch({type: "VINS_SAVING_ERROR"});
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch(error =>{
      console.log("updateGroup() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:updatefailed", {error:error.statusText})
    });
  }

  updateGroupLinks(req: SetNetworkConnectionsRequest){

    this.store.dispatch({type:"VINMANAGE_DATA_LOADING"});

    return this.groupLinkApi.setNetworkConnections(req).then(result => {
      console.log('updateGroupLinks result: ',result);
      if (result.success === true){
        //this.store.dispatch({type:"UPDATE_GROUP_DETAILS_FINISHED",data: result.data});
      }else{
        this.store.dispatch({type: "VINS_LOAD_ERROR"});
        this.snackAlertError("snack:updatefailed", {error:result.error});
      }

      return result;

    }).catch(error => {
      console.log("updateGroupLinks() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:updatefailed", {error:error.statusText})
    });

  }

  checkLinkValid(vinId : number, group:number, groups: Array<number>){
    console.log("SessionManager:: checkLinkValid ",group,groups);
    let loadId;
    if (groups != null && groups.length > 0){
      loadId = groups[groups.length - 1]
    }
    this.store.dispatch({type: "LOAD_CHECKLINK_START",data:loadId});

    return this.groupLinkApi.checkNetworkConnections({vinId: vinId, groupId: group, requestBody: groups}).then(result =>{

      // console.log("checkLinkValid result ",result);
      if (result.success === false){
        if (result.error != null) {
          this.snackAlertError(result.error);
        }else{
          this.snackAlertError("common:labels.error");
        }
      }

      this.store.dispatch({type: "LOAD_CHECKLINK_FINISHED"});
      return result;

    }).catch(error=>{
      console.log("checkLinkValid() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    })

  }

  loadVinDevices(vinId: number){
    this.store.dispatch({type: "LOAD_VIN_DEVICES_START"});

    return this.vinAPI.getNetworkDevices({vinId:vinId}).then( result => {
      // console.log('loadVinDevices result ',result);
      if (result.success === true){
        this.store.dispatch({type: "LOAD_VIN_DEVICES_FINISHED", data:result.data});
      }else{
        this.store.dispatch({type: "VINS_LOAD_ERROR"});
        this.snackAlertError("snack:deletefailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("loadVinDevices() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });
  }

  approveDevice(vinId: number, deviceId: number){

    this.store.dispatch({type: "APPROVE_DEVICE_START", vinId:vinId, deviceId:deviceId});

    return this.deviceApi.approveDevice({vinId: vinId, deviceId: deviceId}).then( result => {

      console.log('approve result: ',result);
      if(result.success === true){
        this.store.dispatch({type: "VINMANAGE_DATA_SAVING_FINISHED", dataType:"approve", vinId:vinId, deviceId:deviceId});
        //this.store.dispatch({type: "VINMANAGE_DATA_LOADING_FINISHED", data:result.data});
        //snackAlertSuccess("Device Approved");
        this.checkSessionBackground();

      } else {
        this.store.dispatch({ type: "VINS_SAVING_ERROR", data:result.error });
        this.snackAlertError("snack:approvefailed", {error:result.error});
      }

      return result;

    }).catch(error => {
      console.log("approveDevice() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:approvefailed", {error:error.statusText})
    });
  }

  deleteDevice(vinId: number, deviceId: number){

    this.store.dispatch({type: "DELETE_DEVICE_START"});

    return this.deviceApi.deleteDevice({vinId: vinId, deviceId: deviceId}).then(result => {

      if(result.success === true){
        this.store.dispatch({type: "DELETE_DEVICE_FINISHED", vinId:vinId, deviceId:deviceId});
      } else {
        this.snackAlertError("snack:deletefailed", {error:result.error});
      }

      return result;

    }).catch(error => {
      console.log("deleteDevice() error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "VINS_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:deletefailed", {error:error.statusText})
    });
  }

  /**
   * loads the device details, along with al lthe devices if it has to
   *
   * @param vinId
   * @param deviceId
   */
  loadDeviceDetail(vinId : number, deviceId: string){
    let state = this.store.getState();
    let devices = state.vinsgroups.vinDevices;

    if(devices == null){
      this.loadVinDevices(Number(vinId)).then(result => {
        // @ts-ignore
        if('success' in result && result.success === true){

          if (deviceId != 'all' && deviceId != null)
          {
            //load device detail
            this.store.dispatch({type:"LOAD_DEVICE_DETAIL", data:Number(deviceId)});
          }
        }
      });
    }
    else if (deviceId != 'all' && deviceId != null)
    {
      this.store.dispatch({type:"LOAD_DEVICE_DETAIL", data:Number(deviceId)});
    }


  }

  performSearch(type : string, keywords : string)
  {
    this.store.dispatch({type: "SEARCH_START"});
    let searchType : SearchRequestTypeEnum = SearchRequestTypeEnum[type as keyof typeof SearchRequestTypeEnum];
    return this.searchApi.doSearch({searchRequest:{type:searchType, query:keywords}}).then( result => {
      console.log('search result ',result);
      if (result.success === true){
        this.store.dispatch({type: "SEARCH_FINISHED", data:result.data});
      }else{
        this.store.dispatch({type: "SEARCH_ERROR"});
        this.snackAlertError("snack:searchfailed", {error:result.error});
      }

      return result;

    }).catch( error => {
      console.log("search error ",error);
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "SEARCH_ERROR", data:error.statusText });
      this.snackAlertError("snack:searchfailed", {error:error.statusText})
    });
  }

  loadLicenceDetails(){
    this.store.dispatch({type: "LICENCE_LOAD_START"});
    this.licenseApi.getLicense().then(result => {
      this.store.dispatch({type:"LICENCE_LOAD_FINISHED", data:result.data});
    }).catch( error => {
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "LICENCE_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });
  }

  activateNewLicence(key: string){
    this.store.dispatch({type: "LICENCE_ACTIVATE_START"});
    let req = {newLicenseDetails: {licenseKey: key}};
    this.licenseApi.putLicense(req).then( result => {
      if (result.success === true){
        this.snackAlertSuccess("snack:licenceActivated");
        this.store.dispatch({type:"LICENCE_ACTIVATE_FINISHED",data:result.data});
      }else{
        this.store.dispatch({ type: "LICENCE_LOAD_ERROR"});
        this.snackAlertError("snack:loadfailed", {error:result.error});

      }
    }).catch( error => {
      this.checkUnauthorised(error);
      this.store.dispatch({ type: "LICENCE_LOAD_ERROR", data:error.statusText });
      this.snackAlertError("snack:loadfailed", {error:error.statusText})
    });

  }




}
export default SessionManager;
