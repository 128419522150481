import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import templates from 'utils/templates';
import { connect } from 'react-redux';

import {Alert, AlertTitle } from '@material-ui/lab/';
import {
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  RadioGroup, FormControlLabel, Radio, useTheme, withStyles, Switch
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/Tables/DataTable';
import * as validate from 'helpers/validate';
import VINTopology from 'pages/VIN Pages/VINTopology';
import {ReactComponent as IconRegenerate} from 'assets/netlinkz/btnRegenerate.svg'
import {ReactComponent as IconCopy} from 'assets/netlinkz/btnCopy.svg'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { snackAlertSuccess } from 'helpers/alerting';

const RadioFormLabel = withStyles((theme) => ({
  root:{
    height:22
  },
}))(FormControlLabel);

const useStyles = makeStyles(theme => ({
    iconButton: {
      cursor:'pointer',
      '&:hover path' : {
        fill:"#505FF6",
      }
    }
  }
));


const DataDetails = props => {

  useEffect(() => {
    props.initValidation(props.template);
  }, []);

  const classes = useStyles();

  const { t } = useTranslation(['users','validation','common','license']);
  const theme = useTheme();
  let template = templates.getTemplate(props.template);

  // console.log('DataDetailsz; ',props);
  // console.log('DataDetails template ',template);

  if(template == null){
    return (
      <Fragment>
        <Alert severity={'error'}>No Template for {props.template}</Alert>
      </Fragment>
    )
  }

  const inputChanged = (field, event) => {
    if(props.inputChangeHandler != null){
      props.inputChangeHandler(field, event.target.value, props.template, event);
    }
  };

  const selectChanged = (field, event) => {
    if(props.selectChangeHandler != null) {
      props.selectChangeHandler(field, event.target.value, props.template, event);
    }
  };

  const inputBlur = (el,event) => {
    // console.log('datadetails:: input blur ',el);
    if (el.validate != null){
      // console.log(' validate ',el)
      let validateResult = validate.validateFieldElement(el.validate,event.target.value);
      // console.log('validateResult ',validateResult);
      props.updateValidation(props.template,el.field,validateResult);
    }

    if(props.blurHandler != null) {
      props.blurHandler(el.field, event.target.value, props.template, event);
    }
  };

  const regenerateInvite = () => {
    if (props.regenerateHandler != null)
    {
      props.regenerateHandler();
    }
  }

  const copyToClipboard = (invite) =>
  {
    navigator.clipboard.writeText(invite);
    snackAlertSuccess('snack:copysuccess');
  }


  let elements = [];

  for (let i in template){

    let page = template[i];

    let fields = page.fields;

    for (let j in fields) {
      let el = fields[j];

      if (el.type === 'invite') {

        let val = templates.getValue(props.object,el.field);
        if(val == null){
          val = '';
        }
        // console.log(el.field + ' - val = "' + val + '"');

        let extraProps = {};
        if(props.extraPropsHandler != null){
          extraProps = props.extraPropsHandler(el.field, props.template);
        }

        let numRows;
        elements.push((
          <Grid key={"grid-"+j} container direction="row" justify="space-between" style={{ marginBottom: 20}}>
            <Grid item style={{flexGrow:1, minWidth:150}}>
              <TextField
                size={theme.shape.textFieldSize}
                key={el.field}
                style={{ marginRight:5, width:'95%' }}
                variant={"outlined"}
                type={el.type}
                label={t(el.label)}
                value={val}
                placeholder={t(el.placeholder)}
                InputLabelProps={{ shrink: true }}
                onChange={inputChanged.bind(this, el.field)}
                rows={numRows}
                {...extraProps}

              />
            </Grid>
            <Grid item style={{paddingTop:7}}>
              <IconRegenerate className={classes.iconButton} onClick={() => regenerateInvite()}/>
              <IconCopy className={classes.iconButton} onClick={() => copyToClipboard(val)}/></Grid>
          </Grid>
        ));

      }
      // console.log("el: ", el);
      else if (el.type === 'text' || el.type === 'password' || (el.type != null && el.type.startsWith('multiline'))) {

        // console.log(' obj.'+el.field+" = ",templates.getValue(props.object,el.field));

        //for text if getValue returns null/undefined and then swaps to a string
        //this triggers an error about changing component from controlled to uncontrolled.
        //Always setting value to string fixes this.
        let val = templates.getValue(props.object,el.field);
        if(val == null){
          val = '';
        }
        // console.log(el.field + ' - val = "' + val + '"');

        let error = false;
        let helpText = null;
        // console.log('props.validationData ', {...props.validationData});
        if (el.validate != null){
          let validationData = props.validationData;
          let templateValidation = validationData[props.template];
          if(templateValidation != null){
            let validationValue = templateValidation[el.field];
            if(validationValue != null){
              error = true;
              helpText = t(validationValue);
            }
          }
        }

        if (el.helper != null && error === false){
          helpText = t(el.helper);
        }


        let extraProps = {};
        if(props.extraPropsHandler != null){
          extraProps = props.extraPropsHandler(el.field, props.template);
        }
        let req = el.required === true;

        let multi = false;
        let numRows;

        if (el.type.startsWith('multiline')){

          let split = el.type.split('-');
          if(split.length === 2) {
            numRows = split[1];
          }
          multi = true;
        }


        if (extraProps != null && 'readOnly' in extraProps && extraProps.readOnly === true){
          elements.push((
            <TextField
              size={theme.shape.textFieldSize}
              key={el.field}
              style={{ marginBottom: 20 }}
              variant={"outlined"}
              type={el.type}
              label={t(el.label)}
              value={val}
              placeholder={t(el.placeholder)}
              required={req}
              InputLabelProps={{ shrink: true }}
              onChange={inputChanged.bind(this, el.field)}
              rows={numRows}
              multiline={multi}
              {...extraProps}

            />
          ));
        }else {

          elements.push((
            <TextField
              size={theme.shape.textFieldSize}
              key={el.field}
              style={{ marginBottom: 20 }}
              variant={"outlined"}
              type={el.type}
              label={t(el.label)}
              value={val}
              placeholder={t(el.placeholder)}
              required={req}
              InputLabelProps={{ shrink: true }}
              onChange={inputChanged.bind(this, el.field)}
              onBlur={inputBlur.bind(this,el)}
              error={error}
              helperText={helpText}
              rows={numRows}
              multiline={multi}
              autoComplete="off"
              name="totally-new-name"
              {...extraProps}
            />
          ));
        }

        // elements.push((
        //   <FormGroup key={el.field} style={{marginBottom:20}}>
        //     <TextField
        //       variant={"outlined"}
        //       type={el.type}
        //       label={t(el.label)}
        //       value={val}
        //       InputLabelProps={{ shrink: true }}
        //       onChange={inputChanged.bind(this, el.field)}
        //       {...extraProps}
        //     />
        //   </FormGroup>
        //
        // ));

      }

      if (el.type === 'select'){
        let list = templates.getValue(props.data, el.dataMap.key);
        if (list == null){
          return (
            <Fragment>
              <Alert severity={'error'}><AlertTitle>Template Error</AlertTitle>{props.template} - {el.type} has no data defined </Alert>
            </Fragment>
          );
        }

        // console.log('list ',list);
        // console.log('props.data ',props.data);

        let error = false;
        let helpText = null;
        // console.log('props.validationData ', {...props.validationData});
        if (el.validate != null){
          let validationData = props.validationData;
          let templateValidation = validationData[props.template];
          if(templateValidation != null){
            let validationValue = templateValidation[el.field];
            if(validationValue != null){
              error = true;
              helpText = t(validationValue);
            }
          }
        }

        if (el.helper != null && error === false){
          helpText = t(el.helper);
        }

        let itemKeys = el.dataMap.itemKeys;
        let valueKeys = itemKeys.slice(1,itemKeys.length);

        let items = list.map(item => {
          let v = "";
          for (const k of valueKeys){
            v = item[k] + " ";
          }
          v.trim();
          return (
            <MenuItem key={item[itemKeys[0]]} value={item[itemKeys[0]]}>
              {v}
            </MenuItem>
          )
        });

        let extraProps = {};
        if(props.extraPropsHandler != null){
          extraProps = props.extraPropsHandler(el.field, props.template);
        }

        let val = templates.getValue(props.object,el.field);
        if (val == null){
          val = ''
        }
        let req = el.required === true;

        elements.push(
            <TextField
              select
              size={theme.shape.textFieldSize}
              key={el.field}
              fullWidth={true}
              variant={"outlined"}
              label={t(el.label)}
              value={val}
              placeholder={t(el.placeholder)}
              required={req}
              onChange={selectChanged.bind(this,el.field)}
              style={{marginBottom:20}}
              onBlur={inputBlur.bind(this,el)}
              autoComplete="off"
              error={error}
              helperText={helpText}
              {...extraProps}
            >{items}</TextField>
        );

        // elements.push(
        //   <FormGroup key={el.field} >
        //     <TextField
        //       select
        //       fullWidth={true}
        //       variant={"outlined"}
        //       label={t(el.label)}
        //       value={val}//{templates.getValue(props.object,el.field)}
        //       onChange={selectChanged.bind(this,el.field)}
        //       style={{marginBottom:20}}
        //       {...extraProps}
        //       >{items}</TextField>
        //   </FormGroup>
        // );
      }

      if (el.type === 'DataTable'){

        let tableData = templates.getValue(props.data, el.dataMap.key);
        let cols = el.dataMap.columns;
        if(cols == null){
          return (
            <Fragment>
              <Alert severity={'error'}><AlertTitle>Template Error</AlertTitle>{props.template} - {el.type} has no columns defined </Alert>
            </Fragment>
          );
        }

        let topHeader = null;
        // console.log(el.type + " tableData ",tableData);
        if (props.data.header != null)
        {
          topHeader = props.data.header;
        }

        elements.push(
          <DataTable
            topHeader={topHeader}
            key={i}
            data={tableData}
            columns={cols}
            rowClickHandler={props.rowClickHandler}
            selected={props.object}
            columnClickIndex={props.columnClickIndex}
            sizeWithWindow={props.sizeWithWindow}
            customRowStyler={el.dataMap.rowStyleOverride}
            defaultSort={el.defaultSort}
            customCellStyler={el.dataMap.cellStyleOverride} />
        );

      }

      if (el.type === 'Topology'){

        elements.push(
          <div key={"vintopology"}>
          <VINTopology demo={true}
            data={props.data}
            rowClickHandler={props.rowClickHandler}
            selected={props.object}
            sizeWithWindow={props.sizeWithWindow}
          />
          </div>
        );

      }

      if (el.type === 'radio'){

        let optionData = templates.getValue(props.data, el.dataMap.key);
        let current = templates.getValue(props.object, el.field);
        // console.log('props.object ',props.object);
        // console.log('options ',optionData);
        // console.log('current ',current);

        let options = optionData.map((ro,i) => {
          return (
            <RadioFormLabel key={i} value={ro.id} control={<Radio />} label={ro.name} />
          )
        });

        let extraProps = {};
        if(props.extraPropsHandler != null){
          extraProps = props.extraPropsHandler(el.field, props.template);
        }

        elements.push(
          <FormControl component="fieldset" key={el.field} {...extraProps}>
            <FormLabel component="legend">{t(el.label)}</FormLabel>
            <RadioGroup value={current} onChange={inputChanged.bind(this, el.field)}>
              {options}
            </RadioGroup>
          </FormControl>
        );
      }

      if (el.type === 'switch'){
        let isOn = templates.getValue(props.object, el.field);
        let extraProps = {};
        if(props.extraPropsHandler != null){
          extraProps = props.extraPropsHandler(el.field, props.template);
        }
        elements.push (
          <FormControlLabel key={el.field} control={
            <Switch
              checked={isOn}
              onChange={inputChanged.bind(this,el.field)}
              color={"primary"}
              {...extraProps}
            />
          } label={t(el.label)}/>
        )
      }

      if (el.type === 'limit'){

        let limit = templates.getValue(props.object,'deviceLimit');
        if (limit == null){
          limit = {hard:"",soft:""};
        }else{
          if (limit.soft == null){
            limit.soft = ""
          }
          if (limit.hard == null){
            limit.hard = ""
          }
        }

        let error = false;
        let helpText = null;


        let validationData = props.validationData;
        let templateValidation = validationData[props.template];

        let softEl = Object.assign({},el);
        softEl.field = el.field+'.soft';

        if(templateValidation != null){
          let validationValue = templateValidation[softEl.field];
          if(validationValue != null){
            error = true;
            helpText = t(validationValue);
          }
        }

        if (el.helper != null && error === false){
          helpText = t(el.helper);
        }

        let soft = (
          <TextField
            size={theme.shape.textFieldSize}
            key={softEl.field}
            style={{ marginBottom: 20}}
            fullWidth={true}
            variant={"outlined"}
            type={'text'}
            label={t('organisation:softLabel')}
            value={limit.soft}
            placeholder={t('organisation:softLimitPlaceholder')}
            InputLabelProps={{ shrink: true }}
            onChange={inputChanged.bind(this, softEl.field)}
            onBlur={inputBlur.bind(this,softEl)}
            autoComplete="off"
            name="totally-new-name"
            error={error}
            helperText={helpText}
          />
        );

        let hardEl = Object.assign({},el);
        hardEl.field = el.field+'.hard';
        error = false;
        helpText = null;

        if(templateValidation != null){
          let validationValue = templateValidation[hardEl.field];
          if(validationValue != null){
            error = true;
            helpText = t(validationValue);
          }
        }

        if (el.helper != null && error === false){
          helpText = t(el.helper);
        }

        let hard = (
          <TextField
            size={theme.shape.textFieldSize}
            key={hardEl.field}
            style={{ marginBottom: 20}}
            fullWidth={true}
            variant={"outlined"}
            type={'text'}
            label={t('organisation:hardLabel')}
            value={limit.hard}
            placeholder={t('organisation:hardLimitPlaceholder')}
            InputLabelProps={{ shrink: true }}
            onChange={inputChanged.bind(this, hardEl.field)}
            onBlur={inputBlur.bind(this,hardEl)}
            autoComplete="off"
            name="totally-new-name"
            error={error}
            helperText={helpText}
          />
        );

        let devLimt = (
          <FormControl component="fieldset" key={el.field+'pfft'} variant={"outlined"} >
            <FormLabel component="legend">{t('organisation:deviceLimit')}</FormLabel>
            <div key="pfft" style={{display:'flex',flexDirection:'row', marginTop:10}}>
              {soft}
              <div style={{width:10}}></div>
              {hard}
            </div>
          </FormControl>
        );

        elements.push(devLimt)

      }

    }
  }

  return(
    <Fragment>
      {elements}
    </Fragment>

  );
};

DataDetails.propTypes = {
  template: PropTypes.string.isRequired,
  object: PropTypes.object,
  data: PropTypes.object,
  inputChangeHandler: PropTypes.func,
  selectChangeHandler: PropTypes.func,
  rowClickHandler: PropTypes.func,
  extraPropsHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  columnClickIndex: PropTypes.number
};

const mapStateToProps = state => ({
  validationData: state.validation.templates,
});

const mapDispatchToProps = dispatch => ({
  initValidation: (template) => {
    dispatch({type:"VALIDATION_CLEAR_TEMPLATE", data:template});
  },
  updateValidation: (template, field, validationString) =>{
    dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE", data:{template: template, field:field, validation:validationString}});
  }
});


export default connect(mapStateToProps,mapDispatchToProps)(DataDetails);
