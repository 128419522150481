import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';

import { stringNotNullUndefOrEmpty, isValidUser } from 'helpers/validate';
import SessionManager from 'session/SessionManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CreateUser extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      user :{
        enabled:true,
        admin:false
      }
    };

    this.saveClick = this.saveClick.bind(this);

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.inputChanged = this.inputChanged.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.inputBlur = this.inputBlur.bind(this);

    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
  }

  saveClick(){
    // console.log('save click ',this.state);
    this.props.saveNewClick({...this.state.user});
  }


  fieldPropsHandler(field, template){
    let user = this.state.user;
    if (field === 'orgId'){
      if (user.admin === false){
        return {required:true}
      }else{
        return {disabled:true}
      }
    }

    if (field === 'confirmPassword' && !stringNotNullUndefOrEmpty(user.plainPassword)) {
        return {disabled:true};
    }

    return null;
  }

  inputChanged(field, value,template, event){
    // console.log(template+ ' inputChanged '+ field + " | v - "+value);

    let updateUser = {...this.state.user};

    if (field === 'enabled' || field === 'admin'){
      updateUser[field] = event.target.checked;
      if (field === 'admin' && event.target.checked){
        delete updateUser["orgId"];
      }
    }else {
      updateUser[field] = value;
    }

    if (field === 'confirmPassword'){
      this.props.validatePasswordMatch(updateUser.plainPassword, value);
    }

    this.setState({user:updateUser});
  }

  selectChange(field, value,template){
    // console.log(template+ ' selectChange '+ field + " | v - "+value );
    let updateUser = {...this.state.user};
    updateUser[field] = value;
    this.setState({user:updateUser});
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    if (field === 'confirmPassword'){
      let user = this.state.user;
      this.props.validatePasswordMatch(user.plainPassword, value);
    }
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];

    let link = '/users';
    crumbs.push({name: t('sidebarnav:users'), link:link, action: this.crumbClicked.bind(this,link)});
    crumbs.push({name: t('addNewUserTitle')});


    return crumbs;
  }

  crumbClicked(crumb){
    // console.log('crumb click ',crumb);
    SessionManager.shared().goto(crumb);
  }

  render() {

    // console.log("CreateUser:: ",this.props);
    // console.log("CreateUser:: state ",this.state);
    // console.log("CreateUser:: state.user.enabled ",this.state.user.enabled);

    const { t, theme } = this.props;

    if (this.props.detailLoading || this.props.mainLoading){
      return <Loading/>
    }

    let debug = "";
    let validUser = isValidUser(this.state.user);
    let passwordMatch = this.state.user.plainPassword === this.state.user.confirmPassword;

    let content = (
      <Fragment>
        <div style={{borderBottom: '1px solid grey'}}>
          <Tabs value={0} variant="scrollable">
            <Tab label={t('tabNewUserDetails').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
          </Tabs>
        </div>
        <TabPanel index={0} value={0}>
          <Grid spacing={2} container={true}>
            <Grid item xs>
              <Card elevation={0} style={{height: '100%'}}>
                <CardContent>
                  <Box style={{marginBottom:15}}>

                  </Box>
                  <FormGroup>
                    <DataDetails
                      template={"CreateUser"}
                      object={this.state.user}
                      data={{orgs:this.props.organisations}}
                      inputChangeHandler={this.inputChanged}
                      selectChangeHandler={this.selectChange}
                      extraPropsHandler={this.fieldPropsHandler}
                      blurHandler={this.inputBlur}
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <Box className="d-flex justify-content-end">
          <AppButton buttonType="grey" style={{marginRight:8}} onClick={() => this.props.history.goBack()}>
            { t('common:uppercase', {text:t('common:labels.cancel')}) }
          </AppButton>
          <AppButton
            buttonType="primary"
            onClick={this.saveClick}
            disabled={!validUser || !passwordMatch}
          >
            { t('common:uppercase', {text:t('common:labels.save')}) }
          </AppButton>
        </Box>
      </Fragment>
    );

    let crumbs = this.getBreadCrumbs(t);

    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} withAdd={false} />
        <Grid spacing={0} container={true} style={{backgroundColor:this.props.theme.palette.common.detailContentBg}}>
          <Grid item xs={4} >
            <Paper style={{padding:16}}>
              {content}
            </Paper>
          </Grid>
        </Grid>
        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}

      </Fragment>
    );

  }
}
export default withTranslation(['users','common'])(withTheme(CreateUser));
