import React from 'react';
import Loading from 'components/Loading';
import { Box, Button, Card, CardContent, Grid } from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';
import SessionManager from 'session/SessionManager';

class ManageUsersMain extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      newUser: {
        orgId: '',
      },
      canSave: false,
    };

    this.orgSelectChange = this.orgSelectChange.bind(this);
    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);
    this.rowClick = this.rowClick.bind(this);
  }

  orgSelectChange(field,value){
    if(this.props.isAdding){
      let user = this.state.newUser;
      user[field] = value;
      this.setState({newUser:user});
    }
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];

    crumbs.push({name: t('sidebarnav:users')});
    return crumbs;
  }

  fieldPropsHandler(field){

  }

  rowClick(user){
    // console.log('row click ',user);
    this.props.userSelected(user.id);
  }

  render() {
    // console.log("ManageUsersMain :: ",this.props);
    const { t } = this.props;

    if (this.props.mainLoading === true){
      return <Loading/>;
    }
    let content = [];

    let bd = { border: "1px solid black" };
    let noData = this.props.userList == null || this.props.userList.length === 0;
    if (noData === true) {
      content = "No Users, add a new one";
    } else {

      let userList = Array.from( this.props.userList);

      let userData = userList.map( item => {
          let user = {...item};
          user.fullName = user.firstname + ' ' + user.lastname;
          if (user.orgId != null){
            user.orgName = this.props.orgMap[user.orgId].name;
          }else{
            user.orgName = '';
          }
          user.enabled = !user.disabled + "";

          return user;
      });
      userData.sort( (a,b) => {
        return a.firstname.localeCompare(b.firstname);
      });
      userData.sort( (a,b) => {
        return b.admin - a.admin ;
      });

      content =
        <DataDetails
          sizeWithWindow={200}
          template={"UserTable"}
        data={{users:userData}}
        rowClickHandler={this.rowClick}
        object={this.props.selectedUser}
      />;

    }


    let crumbs = this.getBreadCrumbs(t);

    let withAdd = SessionManager.shared().userAllowedAdd('user');

    return (
      <Grid container={true} direction="column" className='h-100'>
        <ContentPageHeader breadCrumbs={crumbs} newHandler={this.props.addClick} withAdd={withAdd}/>
        <Grid item xs >
          {content}
        </Grid>
      </Grid>
    )
  }


}
export default withTranslation(['users','common'])(ManageUsersMain);
