export default function search(
  state = {
    isLoading: false,
    results: null
  },
  action) {
  switch (action.type) {

    case 'AUTH_LOGOUT':
      return {
        results: null,
        isLoading: false,
      };

    case "SEARCH_START":
    {
      return {
        isLoading: true,
        results:null
      }
    }

    case "SEARCH_FINISHED":
    {
      return {
        isLoading: false,
        results: action.data
      }
    }

    case "SEARCH_ERROR":
    {
      return {
        isLoading: false,
        error: action.data
      }
    }


    default:
      return state;
  }

}
