/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewGroupDetails,
    NewGroupDetailsFromJSON,
    NewGroupDetailsFromJSONTyped,
    NewGroupDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewNetworkDetails
 */
export interface NewNetworkDetails {
    /**
     * 
     * @type {string}
     * @memberof NewNetworkDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewNetworkDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewNetworkDetails
     */
    cidr?: string;
    /**
     * 
     * @type {string}
     * @memberof NewNetworkDetails
     */
    cipher?: NewNetworkDetailsCipherEnum;
    /**
     * 
     * @type {NewGroupDetails}
     * @memberof NewNetworkDetails
     */
    group?: NewGroupDetails;
}

export function NewNetworkDetailsFromJSON(json: any): NewNetworkDetails {
    return NewNetworkDetailsFromJSONTyped(json, false);
}

export function NewNetworkDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewNetworkDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cidr': !exists(json, 'cidr') ? undefined : json['cidr'],
        'cipher': !exists(json, 'cipher') ? undefined : json['cipher'],
        'group': !exists(json, 'group') ? undefined : NewGroupDetailsFromJSON(json['group']),
    };
}

export function NewNetworkDetailsToJSON(value?: NewNetworkDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'cidr': value.cidr,
        'cipher': value.cipher,
        'group': NewGroupDetailsToJSON(value.group),
    };
}

/**
* @export
* @enum {string}
*/
export enum NewNetworkDetailsCipherEnum {
    BlowfishCbc = 'blowfish-cbc',
    Aes128Cbc = 'aes128-cbc'
}


