import { AlertType } from 'helpers/alerting'

const appendMessage = (state_messages, message) => {

  let list = state_messages.slice();
  list.push(message);

  return list;
};

export default function appAlert(
  state = {
    alerts:[],
    current:null,
    show:false,
  },
  action) {

  switch (action.type) {
    case "ALERT_APP_ERROR":
    {
      // console.log(' ALERT_APP_ERROR ',state);

      let alert = {type: AlertType.ERROR, message: action.data};

      if(state.alerts.length === 0) {

        let list = appendMessage(state.alerts,alert);

        return {
          alerts: list,
          show: true,
          current: alert,
        }
      }else{

        return {
          ...state,
          alerts: appendMessage(state.alerts, alert),
        }
      }
    }

    case "ALERT_APP_SUCCESS":
    {
      let alert = {type: AlertType.SUCCESS, message: action.data};

      // console.log(' ALERT_APP_SUCCESS ',state);

      if(state.alerts.length === 0) {

        let list = appendMessage(state.alerts,alert);

        return {
          alerts: list,
          show: true,
          current: alert,
        }
      }else{

        return {
          ...state,
          alerts: appendMessage(state.alerts, alert),
        }
      }
    }

    case "ALERT_APP_WARNING":
    {
      let alert = {type: AlertType.WARNING, message: action.data};

      if(state.alerts.length === 0) {

        let list = appendMessage(state.alerts,alert);

        return {
          alerts: list,
          show: true,
          current: alert,
        }
      }else{

        return {
          ...state,
          alerts: appendMessage(state.alerts, alert),
        }
      }
    }

    case "ALERT_APP_INFO":
    {
      let alert = {type: AlertType.INFO, message: action.data};

      if(state.alerts.length === 0) {

        let list = appendMessage(state.alerts,alert);

        return {
          alerts: list,
          show: true,
          current: alert,
        }
      }else{

        return {
          ...state,
          alerts: appendMessage(state.alerts, alert),
        }
      }
    }

    case "CLEAR_APP_ALERT":
    {
      let old = action.data;
      // console.log(' CLEAR_APP_ERROR', action);
      // console.log(' CLEAR_APP_ERROR state - ', state);
      if (old == null) {
        return { alerts:[], show:false, current: null };
      }

      //remove message from list
      let list = state.alerts.slice();

      list = list.filter(alert => alert.message !== old.message);

      if (list.length === 0){
        return {
          alerts: [],
          show:false,
          current: null,
        }
      }

      return {
        alerts: list,
        current: list[0],
        show: true,
      }
    }



    default:
      return state;
  }

}
