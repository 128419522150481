import React, { Fragment } from 'react';
import Loading from 'components/Loading';
import AppButton from 'components/AppButton';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tab,
  Tabs,
  Paper, withTheme, FormGroup
} from '@material-ui/core';
import { withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import DataDetails from 'components/DataDetails';
import ContentPageHeader from 'components/ContentPageHeader';
import { GroupMeshType } from 'helpers/constants';
import { isValidGroup } from 'helpers/validate';
import SessionManager from 'session/SessionManager';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class CreateGroup extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      vinId:props.vinId,
      group:{
        enrolment:'auto',
      },
    };

    this.saveClick = this.saveClick.bind(this);

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.inputChanged = this.inputChanged.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.getBreadCrumbs = this.getBreadCrumbs.bind(this);
  }

  saveClick(){
    console.log('save click ',this.state);
    let group = this.state.group;

    if (group.name != null &&
      group.meshType != null)
    {
      let info = {vinId: this.state.vinId};
      info.group = {...this.state.group};
      this.props.saveNewGroup(info);
    }

  }


  fieldPropsHandler(field, template){
    if(template === 'CreateVinWithOrg'){
      if (field === 'orgName'){
        return {InputProps:{readOnly:true}};
      }
    }
  }

  inputChanged(field, value,template){
    // console.log(template+ ' inputChanged '+ field + " | v - "+value );

    if (template === "CreateGroup"){
      let updateGroup = this.state.group;
      updateGroup[field] = value;
      this.setState({group:updateGroup});
    }
  }

  selectChange(field, value,template){
    // console.log(template+ ' selectChange '+ field + " | v - "+value );

    if (template === "CreateGroup"){
      let updateGroup = this.state.group;
      updateGroup[field] = value;
      this.setState({group:updateGroup});
    }
  }

  getBreadCrumbs(t){

    if (this.props.error != null){
      return [];
    }
    let crumbs = [];
    let orgId = this.props.orgId;
    if(orgId == null){
      orgId = 'all';
    }
    let orgName = t('sidebarnav:allVins');
    if(orgId != 'all') {
      let org = this.props.orgMap[orgId];
      orgName = org.name + " " + t('vin_plural');
    }
    let vinBit = t('vin') + ' ' + t('group_plural');

    if (this.props.orgVins != null){
      for (const i in this.props.orgVins){
        let v = this.props.orgVins[i];

        if(v.id === Number(this.props.vinId)){
          vinBit = v.name + ' ' + t('group_plural');
        }
      }
    }

    let link = '/vins/org/'+orgId;
    crumbs.push({name: orgName, link:'/vins/org/'+orgId, action: this.crumbClicked.bind(this,link)});

    link = link + '/vin/'+this.props.vinId+'/group/all';
    crumbs.push({name:vinBit,link:link,action: this.crumbClicked.bind(this,link)});

    crumbs.push({name: t('createGroupTitle')});

    return crumbs;
  }

  crumbClicked(crumb){
    // console.log('crumb click ',crumb);
    SessionManager.shared().goto(crumb);
  }

  render() {

    // console.log("CreateGroup:: ",this.props);
    // console.log("CreateGroup:: state ",this.state);

    const { t, theme } = this.props;

    if (this.props.detailLoading){
      return <Loading/>
    }

    let debug = "";

    let meshData = [{id:GroupMeshType.meshed,name:t('mesh')}, {id:GroupMeshType.nonMeshed,name:t('nonmesh')}];
    let enrolmentData = [{id:'auto',name:t('groupEnrolmentOptionAuto')},{id:'manual',name:t('groupEnrolmentOptionManual')}, {id:'off',name:t('groupEnrolmentOptionNotAllowed')}]
    let validGroup = isValidGroup(this.state.group);

    let content = (
      <Fragment>
        <div style={{borderBottom: '1px solid grey'}}>
          <Tabs value={0} variant="scrollable">
            <Tab label={t('tabNewGroup').toLocaleUpperCase()} style={{maxWidth:'100%'}}/>
          </Tabs>
        </div>
        <TabPanel index={0} value={0}>
          <Grid spacing={2} container={true}>
            <Grid item xs>
              <Card elevation={0} >
                <CardContent>
                  <Box style={{marginBottom:15}}>
                    <Typography variant="subtitle1" >{t('createGroupDetailsTitle')}</Typography>
                  </Box>
                  <FormGroup>
                    <DataDetails
                      template={"CreateGroup"}
                      object={this.state.group}
                      data={{data:meshData,options:enrolmentData}}
                      inputChangeHandler={this.inputChanged}
                      selectChangeHandler={this.selectChange}
                    />
                  </FormGroup>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <Box className="d-flex justify-content-end">
          <AppButton buttonType="grey" style={{marginRight:8}} onClick={() => this.props.history.goBack()}>
            {t('common:labels.cancel').toLocaleUpperCase()}
          </AppButton>
          <AppButton buttonType="primary" onClick={this.saveClick} disabled={!validGroup}>
            {t('common:labels.save').toLocaleUpperCase()}
          </AppButton>
        </Box>
      </Fragment>
    );

    let crumbs = this.getBreadCrumbs(t);


    return (
      <Fragment>
        <ContentPageHeader breadCrumbs={crumbs} withAdd={false} />
        <Grid spacing={0} container={true} style={{backgroundColor:this.props.theme.palette.common.detailContentBg}}>
          <Grid item xs={5} >
            <Paper style={{padding:16}}>
              {content}
            </Paper>
          </Grid>
        </Grid>
        {/*<pre>*/}
        {/*  {debug}*/}
        {/*</pre>*/}
      </Fragment>
    );

  }
}
export default withTranslation(['vinsgroups','sidebarnav'])(withTheme(CreateGroup));
