import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const SomethingSelectedTitle = (props) => {
  //const theme = useTheme();
  return (
    <Box className="d-flex justify-content-center align-items-center">
      <Typography style={{}} variant={'caption'}>{props.children}</Typography>
    </Box>
  );
};

export default SomethingSelectedTitle;
