import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ContentPageHeader from 'components/ContentPageHeader';

class TestPage extends React.Component{

  constructor(props) {
    super(props);

  }

  render() {
    console.log("TestPage:: ",this.props);

    let crumbs = [];
    crumbs.push({name: "Coming Soon"});

    return (

      <Grid container={true} direction="column" className='h-100' style={{paddingLeft:16, marginRight:16}}>
        <ContentPageHeader breadCrumbs={crumbs} newHandler={() => {}} withAdd={false}/>
        <Grid item xs >
          <Link to={'/dashboard'}>Dashboard</Link>
        </Grid>
      </Grid>

    )
  }


}
export default TestPage;
