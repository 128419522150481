/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LicenseDetails
 */
export interface LicenseDetails {
    /**
     * 
     * @type {string}
     * @memberof LicenseDetails
     */
    licenseKey?: string;
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    notBefore?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    notAfter?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseDetails
     */
    deviceCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseDetails
     */
    licensed?: boolean;
}

export function LicenseDetailsFromJSON(json: any): LicenseDetails {
    return LicenseDetailsFromJSONTyped(json, false);
}

export function LicenseDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenseKey': !exists(json, 'licenseKey') ? undefined : json['licenseKey'],
        'notBefore': !exists(json, 'notBefore') ? undefined : json['notBefore'],
        'notAfter': !exists(json, 'notAfter') ? undefined : json['notAfter'],
        'deviceCount': !exists(json, 'deviceCount') ? undefined : json['deviceCount'],
        'licensed': !exists(json, 'licensed') ? undefined : json['licensed'],
    };
}

export function LicenseDetailsToJSON(value?: LicenseDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenseKey': value.licenseKey,
        'notBefore': value.notBefore,
        'notAfter': value.notAfter,
        'deviceCount': value.deviceCount,
        'licensed': value.licensed,
    };
}


