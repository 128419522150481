

export default function validation(
  state = {
    templates: {}
  },
  action) {
  switch (action.type) {

    case 'VALIDATION_CLEAR_TEMPLATE':
    {
      // console.log('VALIDATION_CLEAR_TEMPLATE ',action.data);

      if(action.data != null) {
        let templateData = Object.assign({}, state.templates);
        delete templateData[action.data];

        return {
          templates: templateData
        }
      }

      return state;
    }

    case 'VALIDATION_CLEAR_ALL':
    {
      return {
        ...state,
        templates: {}
      }
    }

    case 'VALIDATION_TEMPLATE_FIELD_CHANGE':
    {
      // console.log('VALIDATION_TEMPLATE_FIELD_CHANGE ',action);
      //{template: template, field:field, validation:validationString}
      let change = action.data;

      let templateData = {...state.templates};

      let info = templateData[change.template];
      if(info == null){

        info = {};
        info[change.field] = change.validation;

      } else {
        //update stuff..

        info[change.field] = change.validation;

      }

      templateData[change.template] = info;

      return {
        ...state,
        templates: templateData
      }
    }


    default:
      return state;
  }

}
