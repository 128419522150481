/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuildDetails
 */
export interface BuildDetails {
    /**
     * 
     * @type {string}
     * @memberof BuildDetails
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildDetails
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildDetails
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildDetails
     */
    branch?: string;
}

export function BuildDetailsFromJSON(json: any): BuildDetails {
    return BuildDetailsFromJSONTyped(json, false);
}

export function BuildDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'branch': !exists(json, 'branch') ? undefined : json['branch'],
    };
}

export function BuildDetailsToJSON(value?: BuildDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'number': value.number,
        'time': value.time,
        'branch': value.branch,
    };
}


