import React from 'react';
import InitialisePage from 'pages/Initialise/InitialisePage';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import { stringNotNullUndefOrEmpty, validateFieldElement } from 'helpers/validate';


const mapStateToProps = (state, ownProps) => {

  return {
    isLoading: state.session.isLoading,
    isAuthenticated: state.session.isAuthenticated,
    error: state.session.error,
    initialiseStatus: state.session.initialiseStatus,
    initialiseError: state.session.initialiseError
  }

};

const initialiseCheck = () =>
{
  console.log("Initialise Check : starting")
  SessionManager.shared().initialiseCheck().then((result) =>
  {
    console.log("Initialise Check : result = ", result);

    if (result.data == "waiting" || result.data == "new")
    {
      setTimeout(initialiseCheck, 2000);
    }
  });


}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    validatePasswordMatch(password, confirm)
    {
      let passwordOK = !stringNotNullUndefOrEmpty(validateFieldElement("password",password));
      if (passwordOK){
        let validation;
        if (password !== confirm){
          validation = "validation:fieldPasswordDontMatch";
        }
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "Initialise", field:"confirmPassword", validation:validation}})
      }

    },

    doInitialise: (username, password, organisation, licenseKey) => {
      SessionManager.shared().initialise(username, password, organisation, licenseKey);

      initialiseCheck();
    }
  };
};

class InitialiseView extends React.Component{

  render() {

     // console.log("InitialiseView:: ",this.props);
    return React.cloneElement(<InitialisePage />, this.props);
  }

}



const InitialiseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitialiseView);
export default InitialiseContainer;


