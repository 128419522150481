import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Box, Card,
  CardContent,
  Container,
  FormGroup,
  withTheme,
  Typography,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import DataDetails from 'components/DataDetails';
import { isValidInitialisation } from 'helpers/validate';
import Loading from 'components/Loading';
import {Alert, AlertTitle} from '@material-ui/lab';

class InitialisePage extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      user :{
        enabled:true,
        admin:true
      }
    };

    this.fieldPropsHandler = this.fieldPropsHandler.bind(this);

    this.inputChanged = this.inputChanged.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.inputBlur = this.inputBlur.bind(this);
    this.doInitalise = this.doInitalise.bind(this);

  }

  doInitalise()
  {
    // console.log('save click ',this.state);
    console.log("INITIALISE", this.state);
    this.props.doInitialise(this.state.user.username, this.state.user.plainPassword, this.state.user.organisation, this.state.user.licenseKey);
  }


  fieldPropsHandler(field, template){
    // let user = this.state.user;
    // if (field === 'orgId'){
    //   if (user.admin === false){
    //     return {required:true}
    //   }else{
    //     return {hidden:true}
    //   }
    // }
    //
    // if (field === 'confirmPassword' && !stringNotNullUndefOrEmpty(user.plainPassword)) {
    //   return {disabled:true};
    // }
    //
    return null;
  }


  inputChanged(field, value,template, event){
    // console.log(template+ ' inputChanged '+ field + " | v - "+value);

    let updateUser = {...this.state.user};

    if (field === 'enabled' || field === 'admin'){
      updateUser[field] = event.target.checked;
    }else {
      updateUser[field] = value;
    }

    if (field === 'confirmPassword'){
      this.props.validatePasswordMatch(updateUser.plainPassword, value);
    }

    this.setState({user:updateUser});
  }

  selectChange(field, value,template){
    // console.log(template+ ' selectChange '+ field + " | v - "+value );
    let updateUser = {...this.state.user};
    updateUser[field] = value;
    this.setState({user:updateUser});
  }

  inputBlur(field, value, template){
    // console.log(template+ ' inputBlur '+ field + " | v - "+value );
    if (field === 'confirmPassword'){
      let user = this.state.user;
      this.props.validatePasswordMatch(user.plainPassword, value);
    }
  }

  render() {

    // console.log("InitialisePage::initialiseStatus ",this.props.initialiseStatus);
    const { t, theme } = this.props;

    if (this.props.isAuthenticated === true || (this.props.initialiseStatus == "complete")){
      return <Redirect to='/dashboard'/>;
    }

    let validUser = isValidInitialisation(this.state.user) ;
    let passwordMatch = this.state.user.plainPassword === this.state.user.confirmPassword;
    let licenseOk = this.state.user.licenseKey != null && this.state.user.licenseKey.trim().length > 0;

    let body = null;

    if (this.props.initialiseStatus == "waiting")
    {
      body = (
        <Fragment>
          <Loading/>
        </Fragment>
      );
    }
    else
    {
      let error = null;
      if (this.props.initialiseStatus == "error")
      {
        //error = <Typography style={{color:"red", margin:10}}>{t(this.props.initialiseError)}</Typography>
        error = (
          <Box style={{marginBottom:20}}>
            <Alert severity="error">
              <AlertTitle>{t('common:labels.error')}</AlertTitle>
              {t(this.props.initialiseError)}
            </Alert>
          </Box>
        );
      }

      body = (
        <FormGroup autoComplete="off" style={{width:'75%'}}>
          {error}
          <DataDetails
            template={"Initialise"}
            object={this.state.user}
            data={{orgs:this.props.organisations}}
            inputChangeHandler={this.inputChanged}
            selectChangeHandler={this.selectChange}
            extraPropsHandler={this.fieldPropsHandler}
            blurHandler={this.inputBlur}
          />

          <AppButton onClick={this.doInitalise}
                     disabled={!validUser || !passwordMatch || !licenseOk}
                     buttonType={"primary"}
                     style={{marginTop:15, marginBottom:25}}
          >
            {t('common:uppercase', {text: t('initialise:labels.initialiseButton')})}
          </AppButton>

        </FormGroup>
      );
    }

    return (
      <Fragment>
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content py-5" >
            <Container maxWidth="lg" className="d-flex justify-content-center">
              <Card className="m-0 w-50 p-0 border-0">
                <div className="d-flex flex-column align-items-center justify-content-center" style={{height:78, marginBottom: 25, backgroundColor:theme.palette.common.cardHeaderBg}}>
                  <Typography variant="h2" style={{color:theme.palette.common.black}}>{t('initialise:pageTitle')}</Typography>
                  <Typography style={{color:theme.palette.common.grey}}>{t('initialise:pageSubtitle')}</Typography>

                </div>
                <CardContent className="d-flex justify-content-center">
                  {body}
                </CardContent>
              </Card>
            </Container>
          </div>
        </div>
      </Fragment>
    );
  }





}
export default withTheme(withTranslation()(InitialisePage));
