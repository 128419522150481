import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Hidden, IconButton, AppBar, Box, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from 'reducers/ThemeOptions';

// import HeaderDots from 'layouts/HeaderOnly/HeaderDots';
// import HeaderDrawer from 'layouts/HeaderOnly/HeaderDrawer';
import HeaderUserbox from 'layouts/HeaderOnly/HeaderUserbox';
// import HeaderSearch from 'layouts/HeaderOnly/HeaderSearch';
// import HeaderMenu from 'layouts/HeaderOnly/HeaderMenu';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import PageHeaderLogo from './PageHeaderLogo';
// import SessionManager from 'session/SessionManager';
import { useTranslation } from 'react-i18next';
import { appImages } from 'helpers/images';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import SessionManager from 'session/SessionManager';

const AlarmBox = props => {


  let pendingApprovalsCount = (props.pendingApprovals==null) ? 0 : props.pendingApprovals.length;

  let alertsCount = (props.alerts==null) ? 0 : props.alerts.length;

  let totalCount = pendingApprovalsCount + alertsCount;

  let classes = "badge badge-pill badge-warning badge-header";

  if (alertsCount > 0 && pendingApprovalsCount === 0){
    classes = "badge badge-pill badge-danger badge-header";
  }

  if (alertsCount > 0 && pendingApprovalsCount > 0){
    //makes it half red/orange.
    //badge-error-and-warning is defined in assets/netlinkz/_base.scss
    classes = "badge badge-pill badge-error-and-warning badge-header";
  }

  if (totalCount === 0) return null;

  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <Hidden smDown>
          <Box component="span" pr="2">
            <IconButton
              onClick={props.onClick}
              color="inherit"
              className="btn-inverse mx-1 d-50">
              <div className={classes}>
                {totalCount}
              </div>
              <NotificationsActiveTwoToneIcon />
            </IconButton>
          </Box>
        </Hidden>
      </div>
    </Fragment>
  )
}

const PageHeader = props => {
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle
  } = props;

  const { t } = useTranslation('common');

  // className={clsx('app-header', {
  //   'app-header-collapsed-sidebar': props.isCollapsedLayout
  // })}

  // className={'app-header app-header-collapsed-sidebar'}
  // console.log("pageheader:: props ",props);

  let session = props.sessionData;

  let version;
  let full_version = null;
  if (session != null && session.version != null) {
    version = <Typography style={{fontSize: '12px'}}>{t('labels.version')} {session.version}</Typography>

    if (session.build != null)
    {
      full_version = "\t" + session.build.version + " (" + session.build.number + ") " + session.build.branch + "\n\t" + session.build.time;
    }
  }

  return (
    <Fragment>
      <AppBar
        color="primary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <PageHeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title={t('company') + " " + t('appname') + "\n" + full_version}>
              <Link to="/dashboard" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  >
                  {appImages.companyLogoDark}
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">
                  {t('appname')}
                  {version}
                </Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}>
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse">
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {/*<HeaderSearch />*/}
              {/*<HeaderMenu />*/}
            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">
            {props.isAuthenticated ? <AlarmBox pendingApprovals={props.sessionData.pendingApprovals}
                                               alerts={props.alerts}
                                               onClick={() => SessionManager.shared().goto("/dashboard")}/>  : null}
            {props.isAuthenticated ? <HeaderUserbox firstname={props.sessionData.firstname} lastname={props.sessionData.lastname} history={props.history}/> : null}
            {/*<HeaderDrawer />*/}
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  isAuthenticated: state.session.isAuthenticated,
  sessionData: state.session.sessionData,
  alerts: state.session.alerts
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageHeader));
