export default function license(
  state = {
    isLoading: false,
    activating: false,
    licenceData: null,
    error:null,
  },
  action) {
  switch (action.type) {

    case "LICENCE_LOAD_START":
    {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case "LICENCE_LOAD_FINISHED":
    {
      return {
        ...state,
        isLoading: false,
        licenceData: action.data
      };
    }

    case "LICENCE_ACTIVATE_START":
    {
      return {
        ...state,
        activating: true,
        error:null
      }
    }

    case "LICENCE_ACTIVATE_FINISHED":
    {
      return {
        ...state,
        activating: false,
        licenceData: action.data,
        error:null
      }
    }

    case "LICENCE_LOAD_ERROR":
    {
      return {
        ...state,
        isLoading: false,
        activating: false,
        error: action.data
      };
    }

    default:
      return state;
  }

}
