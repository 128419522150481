import React  from 'react';
import { connect } from 'react-redux';
import SessionManager from 'session/SessionManager';
import ContentLayout from 'layouts/Content/ContentLayout';
import { snackAlertSuccess} from 'helpers/alerting';
import { stringNotNullUndefOrEmpty, validateFieldElement } from 'helpers/validate';
import CreateOrganisation from 'pages/Organisations/CreateOrganisation';


const mapStateToProps = (state, ownProps) => {

  // let orgs = [];
  // if (state.session.sessionData != null){
  //   orgs = state.session.sessionData.organisations;
  // }

  return {

    mainLoading: state.organisations.isLoading,
    detailLoading: state.organisations.detailLoading,

  }

};

const mapDispatchToProps = (dispatch, ownProps) => {

  return {

    saveNewClick: (info) => {

      console.log("SaveNewOrg click - ",info);


      SessionManager.shared().saveOrganisation(info).then( result => {
        if (result != null && result.success == true){
          SessionManager.shared().loadOrganisations().then( result => {
            SessionManager.shared().goto('/orgs');
          });
        }
      });

      // SessionManager.shared().createUser(req).then(result => {
      //   if (result != null && result.success === true){
      //     snackAlertSuccess("User Created");
      //     SessionManager.shared().loadUsers().then(result => {
      //       if (result != null && result.success === true){
      //         ownProps.history.push('/users');
      //         dispatch({type:"CREATE_USER_FINISHED"});
      //       }
      //     });
      //   }
      // });
    },

    validateDeviceLimit: limit => {

      if (limit == null){
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "CreateOrganisation", field:"deviceLimit.soft", validation:null}});
        dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "CreateOrganisation", field:"deviceLimit.hard", validation:null}});
      }else{

        if (limit.soft == null && limit.hard == null){
          dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "CreateOrganisation", field:"deviceLimit.soft", validation:null}});
          dispatch({type:"VALIDATION_TEMPLATE_FIELD_CHANGE",data:{template: "CreateOrganisation", field:"deviceLimit.hard", validation:null}});
        }else {

          let softValid = validateFieldElement("number", limit.soft);
          let hardValid = validateFieldElement("number", limit.hard);

          if (softValid == null && hardValid == null){
            if (limit.soft > limit.hard){
              softValid = "organisation:error.limitInvalid"
            }
          }

          if (limit.soft === 0){
            softValid = "organisation:error.limitZero"
          }
          if (limit.hard === 0){
            hardValid = "organisation:error.limitZero"
          }

          dispatch({
            type: "VALIDATION_TEMPLATE_FIELD_CHANGE",
            data: { template: "CreateOrganisation", field: "deviceLimit.soft", validation: softValid }
          });
          dispatch({
            type: "VALIDATION_TEMPLATE_FIELD_CHANGE",
            data: { template: "CreateOrganisation", field: "deviceLimit.hard", validation: hardValid }
          });
        }
      }
    }

  };
};

class CreateOrgView extends React.Component{

  componentDidMount() {
    //this.props.loadAll();
  }

  render() {

    // console.log('CreateUserView:: ',{...this.props});

    let detail = React.cloneElement(<CreateOrganisation/>, this.props);

    return <ContentLayout detail={detail} />;
  }

}



const CreateOrganisationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateOrgView);
export default CreateOrganisationContainer;


