import { GroupMeshType } from 'helpers/constants';
const isCidr = require("is-cidr");
/*
isCidr(input)
Check if input is a IPv4 or IPv6 CIDR address. Returns either 4, 6 (indicating the IP version) or 0 if the string is not a CIDR.

isCidr.v4(input)
Check if input is a IPv4 CIDR address. Returns a boolean.

isCidr.v6(input)
Check if input is a IPv6 CIDR address. Returns a boolean.
 */

export const stringNotNullUndefOrEmpty = (value) => {
  if (value === null || value === undefined){
    return false;
  }
  if (value.length === 0){
    return false;
  }
  return true;
};

export const validateFieldElement = (type, value) => {

  // console.log('validateFieldElement ',type, " ", value);
  if (type === "text"){
    let notEmpty = stringNotNullUndefOrEmpty(value);
    if(notEmpty){
      return null;
    }else {
      return "validation:fieldEmpty";
    }
  }

  if (type === "email"){
    let notEmpty = stringNotNullUndefOrEmpty(value);
    if(notEmpty){

      let basicOK =  /^.+@.+\..+$/.test(value);

      if(!basicOK){
        return 'validation:fieldEmailNotValid';
      }
      return null;
    }else {
      return "validation:fieldEmpty";
    }
  }

  if (type === "password"){
    let notEmpty = stringNotNullUndefOrEmpty(value);
    if(notEmpty){

      if (value.length < 8){
        return "validation:fieldPasswordTooShort";
      }

      let digitOk = /(?=.*[0-9])/.test(value);
      //let upOk = /(?=.*[A-Z])/.test(value);
      let symbolOk = /(?=.*[!@#$%^&*().,<>+=?_`~/\\-])/.test(value);

      if (!digitOk){
        return 'validation:fieldPasswordNoDigit'
      }
      // if (!upOk){
      //   return 'validation:fieldPasswordNoUpper'
      // }
      if (!symbolOk){
        return 'validation:fieldPasswordNoSymbol'
      }
      return null;

    }else {
      return "validation:fieldEmpty";
    }
  }

  if (type.startsWith('textMax')){
    let split = type.split("-");
    if(split.length === 2){
      let max = Number(split[1]);

      let notEmpty = stringNotNullUndefOrEmpty(value);

      if (notEmpty && value.length <= max){
        return null;
      }

      if(value.length > max){
        return "validation:fieldExceedMaxLength"
      }
      return "validation:fieldEmpty";
    }
  }

  if (type === 'cidr'){
    if (value == null || value.length === 0){
      return "validation:fieldEmpty";
    }

    console.log('cidr check');

    if (!isCidr.v4(value)){
      console.log('fail isCidr.v4');
      return 'validation:fieldCidrInvalid';
    }

    if (!value.startsWith("10.") && !value.startsWith("192.") && !value.startsWith("172.")){
      console.log('fail not 10.xx, 192.xxx, 172.xxx');
      return 'validation:fieldCidrInvalid';
    }

    if (isCidr.v4(value)){

      // ends with 0/16 - 0/24
      let maskTest = /0(\/(1[6-9]))|0(\/(2[0-4]))\b/;

      if (!maskTest.test(value)){
        console.log('fail mask test');
        return 'validation:fieldCidrBitMask';
      }

      // if (!value.endsWith("/24")){
      //   return 'validation:fieldCidrBitMask';
      // }
    }
    return null;
  }

  if (type === "number"){
    let notEmpty = stringNotNullUndefOrEmpty(value);
    if(notEmpty){
      let num = parseInt(value);
      if(isNaN(num)){
        return "validation:fieldNotNumber";
      }
      return null
    }else{
      return "validation:fieldEmpty";
    }
  }

  return null;

};

export const isValidVin = vin => {

  // console.log('validate vin ',vin);

  if (vin == null){
    return false;
  }

  let cipherOK = vin.cipher != null;
  let nameOK = stringNotNullUndefOrEmpty(vin.name);
  if (nameOK){
    nameOK = vin.name.length <= 32;
  }
  let orgIdOK = vin.orgId != null;
  let cidrOK = false;
  if (isCidr.v4(vin.cidr)){

    // ends with 0/16 - 0/24
    let maskTest = /0(\/(1[6-9]))|0(\/(2[0-4]))\b/;

    cidrOK = maskTest.test(vin.cidr);
  }

  return cipherOK && nameOK && orgIdOK && cidrOK;
};

export const isValidGroup = group => {

  // console.log('group ',group);

  if (group == null){
    return false;
  }

  let nameOK = stringNotNullUndefOrEmpty(group.name);
  if (nameOK){
    nameOK = group.name.length <= 32;
  }
  let meshTypeOK = group.meshType === GroupMeshType.meshed || group.meshType === GroupMeshType.nonMeshed;

  return nameOK && meshTypeOK;
};

export const isValidUser = user => {
  if (user == null){
    return false;
  }

  let usernameOk = !stringNotNullUndefOrEmpty(validateFieldElement('email',user.username));
  // let emailOk = stringNotNullUndefOrEmpty(user.email);
  let orgOk = user.orgId != null;
  let passwordOk = !stringNotNullUndefOrEmpty(validateFieldElement("password",user.plainPassword));

  if (user.admin === true){
    return (
      usernameOk &&
      // emailOk &&
      passwordOk
    );
  }

  if (user.admin === false){
    return (
      usernameOk &&
      // emailOk &&
      passwordOk &&
        orgOk
    );
  }

  return false;

};

export const isValidInitialisation = user => {
  if (user == null){
    return false;
  }

  let usernameOk = stringNotNullUndefOrEmpty(user.username);
  // let emailOk = stringNotNullUndefOrEmpty(user.email);
  let orgOk = stringNotNullUndefOrEmpty(user.organisation);
  let passwordOk = !stringNotNullUndefOrEmpty(validateFieldElement("password",user.plainPassword));

  return (
    usernameOk &&
    orgOk &&
    passwordOk
  );

};

export const isValidOrganisation = org =>{
  if (org == null){
    return false;
  }

  let deviceLimitOk;
  let lim = org.deviceLimit;
  if (lim == null){
    deviceLimitOk = true;
  }else{

    let soft = parseInt(lim.soft);
    let hard = parseInt(lim.hard);

    if (!isNaN(soft) && !isNaN(hard)){
      deviceLimitOk = (soft <= hard) && soft !== 0 && hard !== 0;
    }else {

      if (lim.soft == null && lim.hard == null){
        deviceLimitOk = true;
      }else {
        deviceLimitOk = false;
      }
    }
  }

  return stringNotNullUndefOrEmpty(org.name) && deviceLimitOk;
};

export default {stringNotNullUndefOrEmpty, validateFieldElement, isValidVin, isValidGroup, isValidInitialisation, isValidOrganisation};
