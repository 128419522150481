/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NewNetworkDetails,
    NewNetworkDetailsFromJSON,
    NewNetworkDetailsToJSON,
    RESTResultInteger,
    RESTResultIntegerFromJSON,
    RESTResultIntegerToJSON,
    RESTResultListNetworkDetails,
    RESTResultListNetworkDetailsFromJSON,
    RESTResultListNetworkDetailsToJSON,
    RESTResultNetworkDetailsWithDevices,
    RESTResultNetworkDetailsWithDevicesFromJSON,
    RESTResultNetworkDetailsWithDevicesToJSON,
    RESTResultNetworkDetailsWithGroups,
    RESTResultNetworkDetailsWithGroupsFromJSON,
    RESTResultNetworkDetailsWithGroupsToJSON,
} from '../models';

export interface CreateNetworkRequest {
    orgId: number;
    newNetworkDetails?: NewNetworkDetails;
}

export interface DeleteNetworkRequest {
    vinId: number;
}

export interface GetNetworkDetailsRequest {
    vinId: number;
}

export interface GetNetworkDevicesRequest {
    vinId: number;
}

export interface GetOrgNetworksRequest {
    orgId: number;
}

export interface UpdateNetworkRequest {
    vinId: number;
    newNetworkDetails?: NewNetworkDetails;
}

/**
 * 
 */
export class NetworkApi extends runtime.BaseAPI {

    /**
     * Creates a new network.
     */
    async createNetworkRaw(requestParameters: CreateNetworkRequest): Promise<runtime.ApiResponse<RESTResultNetworkDetailsWithGroups>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling createNetwork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewNetworkDetailsToJSON(requestParameters.newNetworkDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNetworkDetailsWithGroupsFromJSON(jsonValue));
    }

    /**
     * Creates a new network.
     */
    async createNetwork(requestParameters: CreateNetworkRequest): Promise<RESTResultNetworkDetailsWithGroups> {
        const response = await this.createNetworkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a network
     */
    async deleteNetworkRaw(requestParameters: DeleteNetworkRequest): Promise<runtime.ApiResponse<RESTResultInteger>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling deleteNetwork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultIntegerFromJSON(jsonValue));
    }

    /**
     * Deletes a network
     */
    async deleteNetwork(requestParameters: DeleteNetworkRequest): Promise<RESTResultInteger> {
        const response = await this.deleteNetworkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all vins that the user has access to.
     */
    async getAllNetworksRaw(): Promise<runtime.ApiResponse<RESTResultListNetworkDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListNetworkDetailsFromJSON(jsonValue));
    }

    /**
     * Retreives all vins that the user has access to.
     */
    async getAllNetworks(): Promise<RESTResultListNetworkDetails> {
        const response = await this.getAllNetworksRaw();
        return await response.value();
    }

    /**
     * Retreives the more expansive details of a network
     */
    async getNetworkDetailsRaw(requestParameters: GetNetworkDetailsRequest): Promise<runtime.ApiResponse<RESTResultNetworkDetailsWithGroups>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling getNetworkDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/detail`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNetworkDetailsWithGroupsFromJSON(jsonValue));
    }

    /**
     * Retreives the more expansive details of a network
     */
    async getNetworkDetails(requestParameters: GetNetworkDetailsRequest): Promise<RESTResultNetworkDetailsWithGroups> {
        const response = await this.getNetworkDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all the config for devices connected to this network
     */
    async getNetworkDevicesRaw(requestParameters: GetNetworkDevicesRequest): Promise<runtime.ApiResponse<RESTResultNetworkDetailsWithDevices>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling getNetworkDevices.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/device`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNetworkDetailsWithDevicesFromJSON(jsonValue));
    }

    /**
     * Retreives all the config for devices connected to this network
     */
    async getNetworkDevices(requestParameters: GetNetworkDevicesRequest): Promise<RESTResultNetworkDetailsWithDevices> {
        const response = await this.getNetworkDevicesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retreives all vins that belong to the given organisation
     */
    async getOrgNetworksRaw(requestParameters: GetOrgNetworksRequest): Promise<runtime.ApiResponse<RESTResultListNetworkDetails>> {
        if (requestParameters.orgId === null || requestParameters.orgId === undefined) {
            throw new runtime.RequiredError('orgId','Required parameter requestParameters.orgId was null or undefined when calling getOrgNetworks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters.orgId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultListNetworkDetailsFromJSON(jsonValue));
    }

    /**
     * Retreives all vins that belong to the given organisation
     */
    async getOrgNetworks(requestParameters: GetOrgNetworksRequest): Promise<RESTResultListNetworkDetails> {
        const response = await this.getOrgNetworksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a network.
     */
    async updateNetworkRaw(requestParameters: UpdateNetworkRequest): Promise<runtime.ApiResponse<RESTResultNetworkDetailsWithGroups>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling updateNetwork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/network/{vin_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewNetworkDetailsToJSON(requestParameters.newNetworkDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultNetworkDetailsWithGroupsFromJSON(jsonValue));
    }

    /**
     * Updates a network.
     */
    async updateNetwork(requestParameters: UpdateNetworkRequest): Promise<RESTResultNetworkDetailsWithGroups> {
        const response = await this.updateNetworkRaw(requestParameters);
        return await response.value();
    }

}
