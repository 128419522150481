/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InitialiseDetails,
    InitialiseDetailsFromJSON,
    InitialiseDetailsToJSON,
    RESTResultBoolean,
    RESTResultBooleanFromJSON,
    RESTResultBooleanToJSON,
    RESTResultString,
    RESTResultStringFromJSON,
    RESTResultStringToJSON,
} from '../models';

export interface InitialiseRequest {
    xForwardedHost?: string;
    initialiseDetails?: InitialiseDetails;
}

/**
 * 
 */
export class InitialiseApi extends runtime.BaseAPI {

    /**
     * Initialises the database.
     */
    async initialiseRaw(requestParameters: InitialiseRequest): Promise<runtime.ApiResponse<RESTResultBoolean>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xForwardedHost !== undefined && requestParameters.xForwardedHost !== null) {
            headerParameters['X-Forwarded-Host'] = String(requestParameters.xForwardedHost);
        }

        const response = await this.request({
            path: `/init`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitialiseDetailsToJSON(requestParameters.initialiseDetails),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultBooleanFromJSON(jsonValue));
    }

    /**
     * Initialises the database.
     */
    async initialise(requestParameters: InitialiseRequest): Promise<RESTResultBoolean> {
        const response = await this.initialiseRaw(requestParameters);
        return await response.value();
    }

    /**
     * Checks the initialisation status.
     */
    async initialiseCheckRaw(): Promise<runtime.ApiResponse<RESTResultString>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/init/check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultStringFromJSON(jsonValue));
    }

    /**
     * Checks the initialisation status.
     */
    async initialiseCheck(): Promise<RESTResultString> {
        const response = await this.initialiseCheckRaw();
        return await response.value();
    }

}
