import React from 'react';
import { Box, MenuItem, TextField, withTheme } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Search } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import DataDetails from 'components/DataDetails';
import Typography from '@material-ui/core/Typography';
import links from 'helpers/links';
import useWindowSize from 'utils/windowSize';
import LandingPage from './LandingPage';
import SessionManager from 'session/SessionManager';
import Alert from '@material-ui/lab/Alert';

const LayoutSearchResults = (props) =>
{
  console.log("LayoutSearchResults", props);
  const size = useWindowSize();

  let children = props.children;
  let equalHeight = 0;

  if (children.length == 0)
  {
    return null;
  }
  let spacing = (children.length - 1)*20;
  let availableHeight = size.height - 240 - spacing;
  equalHeight = availableHeight / children.length;

  // go through the predicted heights, and see if there is any extra scope
  for (let i=0; i<children.length; ++i)
  {
    let s = props.predictedSizes[i];
    // if there is some extra available height, add that on
    if (s < equalHeight)
    {
      let extra = equalHeight - s;
      equalHeight += extra/(children.length-1);
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="left" width={"100%"} style={{marginTop:0}}>
      {children.map((x) => x(size.height - equalHeight))}
    </Box>
  );
}

class DashboardSearch extends React.Component{

  constructor(props) {
    super(props);

    this.state = {keywords:null, type:"ALL"};
    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(type, keywords)
  {
    if (keywords != null && keywords.length > 0)
    {
      this.props.search(type, keywords);
    }
  }

  guessSize(length)
  {
    return 64 + length*32;
  }

  render() {

    const { t, theme } = this.props;

    let results = null;
    if (this.props.searchResults != null)
    {
      let type = this.props.searchResults.type;
      let query = this.props.searchResults.query;

      let groupNames = this.props.searchResults.groupNames;
      let networkNames = this.props.searchResults.networkNames;

      let networks = this.props.searchResults.networks;
      let devices = this.props.searchResults.configs;
      let groups = this.props.searchResults.groups;

      let guessedSizes = [];
      let tables = [];

      let marginTop = 0;
      let networkBox = null;
      if (networks != null && networks.length > 0)
      {
        let obj = null;

        let tableData = networks.map(vin => {
          let org = this.props.orgMap[vin.orgId];
          vin.orgName = org.name;
          vin.count = vin.groupCount + '/' + vin.deviceCount;
          if (this.props.ciphers != null){
            let cv = this.props.ciphers[vin.cipher];
            if (cv != null){
              vin.cipher = cv;
            }
          }
          return vin;
        });

        tables.push( (sizeWithWindow) =>(

        <Box display="flex" flexDirection="row" justifyContent="left" style={{marginTop:marginTop}}>
            <DataDetails
              sizeWithWindow={sizeWithWindow}
              columnClickIndex={1}
              template={"VINTableSearch"}
              object={obj}
              data={{data:tableData, header:t("dashboard:vinWithCount", {count:networks.length})}}
              rowClickHandler={(row) => SessionManager.shared().gotoVIN("all", row.id)} />
          </Box>
        ));
        marginTop = 20;
        guessedSizes.push(this.guessSize(networks.length));
      }

      if (groups != null && groups.length > 0)
      {
        let tableData = groups.map(dd => {

          dd.vinName = (networkNames==null)? "" : networkNames[dd.networkId];
          dd.invite = dd.invites[0].invitation;
          dd.linkCount = (dd.connectionIds==null)? 0 : dd.connectionIds.length;
          return dd;
        });

        tables.push( (sizeWithWindow) => (
          <Box display="flex" flexDirection="row" justifyContent="left" width={"100%"} style={{marginTop:marginTop}}>
            <DataDetails
              sizeWithWindow={sizeWithWindow}
              columnClickIndex={1}
              template={"VINGroupsSearch"}
              object={null}
              data={{data:tableData, header:t("dashboard:groupWithCount", {count:groups.length})}}
              rowClickHandler={(row) => SessionManager.shared().gotoGroup("all", row.networkId, row.id)} />
          </Box>
        ));
        marginTop = 20;

        guessedSizes.push(this.guessSize(groups.length));

      }

      if (devices != null && devices.length > 0)
      {
        let tableData = devices.map(dd => {

          dd.groupName = (groupNames==null)? "" : groupNames[dd.groupId];
          dd.vinName = (networkNames==null)? "" : networkNames[dd.networkId];

          if (dd.enable === false){
            dd.lastSeenInfo = t('vindevice:notApprovedInfo');
          }else{

            if (dd.lastSeen != null) {
              let d = new Date(dd.lastSeen);
              dd.lastSeenInfo = t('common:lastSeenInfo', { date: d });
            }
          }
          return dd;
        });

        tables.push( (sizeWithWindow) =>(
          <Box display="flex" flexDirection="row" justifyContent="left" style={{marginTop:marginTop}}>
            <DataDetails
              sizeWithWindow={sizeWithWindow}
              columnClickIndex={1}
              template={"VINDevicesSearch"}
              object={null}
              data={{data:tableData, header:t("dashboard:deviceWithCount", {count:devices.length})}}
              rowClickHandler={(row) => SessionManager.shared().gotoDevice("all", row.networkId, row.device.id)} />
          </Box>
        ));
        marginTop = 20;

        guessedSizes.push(this.guessSize(devices.length));

      }

      if (tables.length == 0)
      {
        results = (
          <Box display="flex" flexDirection="row" justifyContent="left" width={"100%"} style={{marginTop:0}}>
            <Alert severity={'info'}>{t('dashboard:noresults')}</Alert>
          </Box>
          );
      }
      else
      {
        results = (
          <LayoutSearchResults predictedSizes={guessedSizes}>
            {tables}
          </LayoutSearchResults>
        );
      }
    }
    else if (this.props.isSearchLoading)
    {
      results = null;
    }
    else {
      results = <LandingPage/>
    }

    return (
      <Box display={"flex"} flexDirection="column">

        <form>
        <Box display="flex" flexDirection="row" justifyContent="left" width={"100%"} style={{marginTop:34}}>

          <Box display="flex" flexDirection="row" style={{whiteSpace:"nowrap"}}><Search style={{fontSize:20}}/>&nbsp;<Typography variant="body1" style={{fontSize:18, fontWeight:500}}>{t("dashboard:searchTitle")}</Typography></Box>

          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" justifyContent="left" style={{marginBottom: 20, marginLeft:30, marginRight:30, width:120}}>
                <TextField
                  select
                  size={theme.shape.textFieldSize}
                  key={"type"}
                  fullWidth={false}
                  variant={"outlined"}
                  label={t("dashboard:searchTypeLabel")}
                  value={this.state.type}
                  required={false}
                  onChange={(el) => this.setState({type:el.target.value})}
                  style={{  }}
                >
                   <MenuItem value={"ALL"}>{t("dashboard:searchType.all")}</MenuItem>
                  <MenuItem value={"VIN"}>{t("dashboard:searchType.vin")}</MenuItem>
                  <MenuItem value={"GROUP"}>{t("dashboard:searchType.group")}</MenuItem>
                  <MenuItem value={"DEVICE"}>{t("dashboard:searchType.device")}</MenuItem>
              </TextField>
              <span style={{fontSize:11, lineHeight:"14px", color:"#737373"}}>{t("dashboard:searchSubtitle")}</span>
            </Box>

            <Box>
              <TextField
                size={theme.shape.textFieldSize}
                key={"search"}
                style={{ marginBottom: 20, width:295 }}
                variant={"outlined"}
                type={""}
                label={t("dashboard:searchTerm")}
                value={this.state.keywords == null ? "" : this.state.keywords}
                placeholder={t("dashboard:searchKeywords")}
                required={false}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => { console.log("CHANGED", e); this.setState({keywords:e.target.value});}}
              />
            </Box>
            <AppButton
              type={"submit"}
              buttonType="primary"
              onClick={(e) => { this.onSearch(this.state.type, this.state.keywords); e.preventDefault();}}
              onSubmit={(e) => { this.onSearch(this.state.type, this.state.keywords); e.preventDefault();}}
              style={{height:37, marginLeft:20}}
              disabled={this.state.keywords==null || this.state.keywords.length==0}
            >
              {t('dashboard:buttonSearch')}
            </AppButton>
          </Box>
        </Box>
        </form>

        {results}
      </Box>

    )
  }
}

export default withTranslation(['vindevice','common'])(withTheme(DashboardSearch));
