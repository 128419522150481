/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RESTResultConfigDetails,
    RESTResultConfigDetailsFromJSON,
    RESTResultConfigDetailsToJSON,
    RESTResultInteger,
    RESTResultIntegerFromJSON,
    RESTResultIntegerToJSON,
} from '../models';

export interface ApproveDeviceRequest {
    vinId: number;
    deviceId: number;
}

export interface DeleteDeviceRequest {
    vinId: number;
    deviceId: number;
}

export interface MoveDeviceRequest {
    vinId: number;
    deviceId: number;
    groupId: number;
}

/**
 * 
 */
export class NetworkDeviceApi extends runtime.BaseAPI {

    /**
     * Approve a device.
     */
    async approveDeviceRaw(requestParameters: ApproveDeviceRequest): Promise<runtime.ApiResponse<RESTResultInteger>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling approveDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling approveDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/device/{device_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultIntegerFromJSON(jsonValue));
    }

    /**
     * Approve a device.
     */
    async approveDevice(requestParameters: ApproveDeviceRequest): Promise<RESTResultInteger> {
        const response = await this.approveDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Approve a device.
     */
    async deleteDeviceRaw(requestParameters: DeleteDeviceRequest): Promise<runtime.ApiResponse<RESTResultInteger>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling deleteDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deleteDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/device/{device_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultIntegerFromJSON(jsonValue));
    }

    /**
     * Approve a device.
     */
    async deleteDevice(requestParameters: DeleteDeviceRequest): Promise<RESTResultInteger> {
        const response = await this.deleteDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Approve a device.
     */
    async moveDeviceRaw(requestParameters: MoveDeviceRequest): Promise<runtime.ApiResponse<RESTResultConfigDetails>> {
        if (requestParameters.vinId === null || requestParameters.vinId === undefined) {
            throw new runtime.RequiredError('vinId','Required parameter requestParameters.vinId was null or undefined when calling moveDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling moveDevice.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling moveDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/network/{vin_id}/device/{device_id}/move/{group_id}`.replace(`{${"vin_id"}}`, encodeURIComponent(String(requestParameters.vinId))).replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"group_id"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RESTResultConfigDetailsFromJSON(jsonValue));
    }

    /**
     * Approve a device.
     */
    async moveDevice(requestParameters: MoveDeviceRequest): Promise<RESTResultConfigDetails> {
        const response = await this.moveDeviceRaw(requestParameters);
        return await response.value();
    }

}
