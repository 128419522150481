import ThemeOptions from './ThemeOptions';
import session from 'reducers/session';
import organisations from 'reducers/oranisations';
import users from 'reducers/users'
import appAlert from 'reducers/appAlerts';
import vinsgroups from 'reducers/vinsgroups';
import search from 'reducers/search';
import validation from 'reducers/validation';
import license from 'reducers/license';

// export default {
//   ThemeOptions,
//   session: session,
//   organisations: organisations,
//   users: users,
//   appAlert: appAlert,
//   vinsgroups: vinsgroups
// };
export default {
  ThemeOptions,
  session,
  organisations,
  users,
  appAlert,
  vinsgroups,
  search,
  validation,
  license
};
