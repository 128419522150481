/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserDetails,
    UserDetailsFromJSON,
    UserDetailsFromJSONTyped,
    UserDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RESTResultUserDetails
 */
export interface RESTResultUserDetails {
    /**
     * 
     * @type {boolean}
     * @memberof RESTResultUserDetails
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RESTResultUserDetails
     */
    error?: string;
    /**
     * 
     * @type {UserDetails}
     * @memberof RESTResultUserDetails
     */
    data?: UserDetails;
}

export function RESTResultUserDetailsFromJSON(json: any): RESTResultUserDetails {
    return RESTResultUserDetailsFromJSONTyped(json, false);
}

export function RESTResultUserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RESTResultUserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : UserDetailsFromJSON(json['data']),
    };
}

export function RESTResultUserDetailsToJSON(value?: RESTResultUserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'error': value.error,
        'data': UserDetailsToJSON(value.data),
    };
}


