/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitialiseDetails
 */
export interface InitialiseDetails {
    /**
     * 
     * @type {string}
     * @memberof InitialiseDetails
     */
    licenseKey?: string;
    /**
     * 
     * @type {string}
     * @memberof InitialiseDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof InitialiseDetails
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof InitialiseDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InitialiseDetails
     */
    orgname?: string;
}

export function InitialiseDetailsFromJSON(json: any): InitialiseDetails {
    return InitialiseDetailsFromJSONTyped(json, false);
}

export function InitialiseDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialiseDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenseKey': !exists(json, 'licenseKey') ? undefined : json['licenseKey'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'orgname': !exists(json, 'orgname') ? undefined : json['orgname'],
    };
}

export function InitialiseDetailsToJSON(value?: InitialiseDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenseKey': value.licenseKey,
        'username': value.username,
        'password': value.password,
        'email': value.email,
        'orgname': value.orgname,
    };
}


