/* tslint:disable */
/* eslint-disable */
/**
 * VIN Admin Swagger Definition
 * This is the VIN Admin Swagger Defnitition.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: craig@netlinkz.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    emailEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    created?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    disabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetails
     */
    admin?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    orgId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    plainPassword?: string;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emailEnabled': !exists(json, 'email_enabled') ? undefined : json['email_enabled'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'plainPassword': !exists(json, 'plainPassword') ? undefined : json['plainPassword'],
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'username': value.username,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'mobile': value.mobile,
        'email': value.email,
        'email_enabled': value.emailEnabled,
        'created': value.created,
        'disabled': value.disabled,
        'admin': value.admin,
        'orgId': value.orgId,
        'plainPassword': value.plainPassword,
    };
}


