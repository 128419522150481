import React, { Fragment } from 'react';
import { Box, Paper, withTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {ReactComponent as IconPendingApprovals} from 'assets/netlinkz/pendingApproval.svg';
import {ReactComponent as IconSegmentAlert} from 'assets/netlinkz/segmentAlerts.svg';
import {Alert, AlertTitle} from '@material-ui/lab';
import { withTranslation } from 'react-i18next';
import ApprovalTable from 'components/Tables/ApprovalTable';
import { snackAlertSuccess } from 'helpers/alerting';
import DataDetails from 'components/DataDetails';
import links from 'helpers/links';
import SessionManager from 'session/SessionManager';

class DashboardDetail extends React.Component{

  constructor(props) {
    super(props);

    this.approveClick = this.approveClick.bind(this);
    this.findDevice = this.findDevice.bind(this);
    this.deviceClick = this.deviceClick.bind(this);
  }

  findDevice(configId)
  {
    let pendingApprovals = this.props.pendingApprovals;

    for (let i=0; i<pendingApprovals.length; ++i)
    {
      if (pendingApprovals[i].id == configId)
      {
        let device = pendingApprovals[i];
        return device;
      }
    }
    return null;
  }

  deviceClick(configId)
  {
    let device = this.findDevice(configId);
    if (device != null)
    {
      SessionManager.shared().gotoDevice("all", device.networkId, device.device.id);
    }
  }

  approveClick(configId)
  {
    let device = this.findDevice(configId);
    if (device != null)
    {
      const { t } = this.props;
      this.props.approveDevice(device, t);
    }
  }

  render() {
    const { t, theme } = this.props;

    let alerts = this.props.alerts==null ? [] : this.props.alerts;
    let pendingApprovals = this.props.pendingApprovals == null ? [] : this.props.pendingApprovals;

    //there's only a Licence alert at the moment so just doing it as a Alert.
    //If we add more types will need to update this.
    let alertItems = [];
    for (const i in alerts){
      let a = alerts[i];
      let item = (
          <Alert key={i} severity={'error'} style={{cursor:'pointer'}} onClick={() => {
            if (a.link != null){
              SessionManager.shared().goto(a.link);
            }
          }}>
            <AlertTitle>{t(a.title)}</AlertTitle>
            {t(a.info)}
          </Alert>
      );
      alertItems.push(item);
    }

    if(alertItems.length === 0){
      alertItems.push(
        <Typography key={"noope"} align={'center'} style={{fontSize:14, fontWeight:300, color:theme.palette.secondarySideBar.text}}>{t('dashboard:noAlerts')}</Typography>
      )
    }

    let colour = theme.palette.common.warningBackground;
    return (
      <Fragment>

        <Paper style={{padding:16, margin:23, backgroundImage: "linear-gradient(to bottom, " + colour + " 72px, " + colour + " 72px, #FFFFFF 72px, #FFFFFF 100%)"}}>
            <Box display="flex" flexDirection="row" style={{marginBottom:17}}>
              <Box style={{width:56, height:56}}><IconPendingApprovals/></Box>
              <Box flexDirection="column">
                <Typography style={{fontSize:15, fontWeight:700, color:theme.palette.common.warningText}}>{t("dashboard:pendingTitle")}</Typography>
                <Typography style={{fontSize:14, fontWeight:300, color:theme.palette.secondarySideBar.text}}>{t("dashboard:pendingCount", {count:pendingApprovals.length})}</Typography>
              </Box>
            </Box>

          <Box display="flex" flexDirection="row" justifyContent="center" style={{marginTop:17, marginBottom:17}}>
            <Box flexDirection="column" style={{width:'100%'}}>
              <ApprovalTable devices={pendingApprovals} orgMap={this.props.orgMap} approveClick={this.approveClick} deviceClick={this.deviceClick}/>
            </Box>
          </Box>

        </Paper>

        <Paper style={{padding:16, margin:23, backgroundImage: "linear-gradient(to bottom, #FFF0EF 72px, #FFF0EF 72px, #FFFFFF 72px, #FFFFFF 100%)"}}>
          <Box display="flex" flexDirection="row" style={{marginBottom:17}}>
            <Box style={{width:56, height:56}}><IconSegmentAlert/></Box>
            <Box flexDirection="column">
              <Typography style={{fontSize:15, fontWeight:700, color:"#F44336"}}>{t("dashboard:alertTitle")}</Typography>
              <Typography style={{fontSize:14, fontWeight:300, color:theme.palette.secondarySideBar.text}}>{t("dashboard:alertCount", {count:alerts.length})}</Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" flexDirection="row" style={{marginTop:17, marginBottom:17}} >
            <Box display="flex" flexDirection="column" style={{width:'100%'}}>
              {alertItems}
            </Box>
          </Box>
        </Paper>


      </Fragment>

    );
  }


}
export default withTranslation(['vindevice','common', 'dashboard'])(withTheme(DashboardDetail));
